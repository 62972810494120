import {IFormData} from "../MssNewsForm/MssNewsForm";
import React, {ChangeEvent} from "react";

interface IMssInputFileHandler {
    formData: IFormData;
    setFormData: React.Dispatch<React.SetStateAction<IFormData>>;
    e: ChangeEvent<HTMLInputElement>;
}
export const inputFileHandler = ({e, formData, setFormData}: IMssInputFileHandler) => {
    const WIDTH = 1400;
    let reader = new FileReader();
    let files = e.target.files;
    if (files) {
        setFormData({...formData, imgName: files[0].name.split('.')[0]})
        reader.readAsDataURL(files[0]);
    }

    reader.onload = e => {
        let imgUrl: any;
        if (e.target) {
            imgUrl = e.target.result;
        }
        const img = new Image();
        img.src = imgUrl;

        setFormData({...formData, imgValue: imgUrl})

        img.onload = (e: any) => {
            const canvas = document.createElement('canvas');
            const ratio = WIDTH / e.target.width;
            canvas.width = WIDTH;
            canvas.height = e.target.height * ratio;
            const context = canvas.getContext("2d");

            if (context) {
                context.drawImage(img, 0, 0, canvas.width, canvas.height);
                const imgUrl = context.canvas.toDataURL("image/jpeg", 80);
                setFormData({...formData, imgValue: imgUrl, fileValue: imgUrl})
            }

        };
    };
}
