import {groupBy, sequentialNumbers} from "../../../../../helpers/map.helper";
import {ILiveScoreEvents} from "../../../../../store/pages/liveScoreInfo/types";
import {MatchListItem} from "../../MatchListItem/MatchListItem";
import {Accordion} from "semantic-ui-react";
import React from "react";

interface IGetLocationPanels {
    onSelect: (eventId: number, isAdc: boolean) => void;
    groupedByLocationMatches: Map<string, ILiveScoreEvents[]>
}

export const useLocationPanels = ({onSelect, groupedByLocationMatches}: IGetLocationPanels) => {
    return Array.from(groupedByLocationMatches).map(
        ([location, locationMatches]) => {
            const groupedByCompetitionMatches = groupBy<string, ILiveScoreEvents>(
                locationMatches,
                (m) => m.tournamentName!
            );

            const competitionPanels = Array.from(groupedByCompetitionMatches).map(
                ([competitionId, competitionMatches]) => {
                    return {
                        key: competitionId + location,
                        title: {
                            content: (
                                <span>
                                {competitionMatches.length
                                    ? competitionMatches[0].tournamentName
                                    : "Unknown"}
                            </span>
                            ),
                        },
                        content: {
                            content: (
                                <>
                                    {!competitionMatches.length && (
                                        <div>No one match in tournament</div>
                                    )}
                                    {competitionMatches.length &&
                                        competitionMatches
                                            .sort(
                                                (first, second) =>
                                                    new Date(first.timeStart).getTime() -
                                                    new Date(second.timeStart).getTime()
                                            )
                                            .map((game) => (
                                                <MatchListItem
                                                    event={game}
                                                    onSelect={onSelect}
                                                    key={game.id}
                                                />
                                            ))}
                                </>
                            ),
                        },
                    };
                }
            );

            return {
                key: location,
                title: {
                    content: <span>{location || "Unknown region"}</span>,
                },
                content: {
                    content: (
                        <>
                            {!groupedByCompetitionMatches && <div>No one match</div>}
                            {(
                                <Accordion
                                    exclusive={false}
                                    defaultActiveIndex={sequentialNumbers(
                                        competitionPanels.length
                                    )}
                                    panels={competitionPanels}
                                />
                            )}
                        </>
                    ),
                },
            };
        }
    );
}
