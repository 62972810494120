import React from 'react';
import instFooter from "../../../../../img/inst-footer.png";
import twFooter from "../../../../../img/twitter-footer.png";
import fbFooter from "../../../../../img/fb-footer.png";
import "./styles.scss"

const GameScoresFooter = () => {

    return (
        <div className={'game-scores-footer'}>
            <div>
                <div className={"social-footer"}>
                    <div className={'social-footer-content'}>
                        <div className="social-icon">
                            <img src={instFooter} alt=""/>
                        </div>
                        <div className="social-icon">
                            <img src={twFooter} alt=""/>
                        </div>
                        <div
                            className="social-icon fb-icon">
                            <img src={fbFooter} alt=""/>
                        </div>
                        <div>@MSSdarts</div>
                    </div>
                </div>
                {/*<div className={"tv-banner"}>*/}
                {/*    <div>*/}
                {/*        <img*/}
                {/*            className={'banner'}*/}
                {/*            src={footerAvailable} alt=" "*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default React.memo(GameScoresFooter);
