import './styles.scss';
import React, {Fragment, useEffect} from "react";
import {Button, Container, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../components/DImmerLoader/DimmerLoader";
import ClientForm from "./ClientForm";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    createModeHandler,
    deleteClient,
    getClients,
    getSingleClient
} from "../../../store/cms/clients/cmsClientsSlice";
import {IClient} from "../../../store/cms/clients/types";

const Clients = () => {
    const dispatch = useAppDispatch();
    const {allClients, loader, createMode, editMode} = useAppSelector((state) => state.cmsClients);
    const showForm = () => dispatch(createModeHandler(true));
    const editClientHandler = (id: number) => dispatch(getSingleClient(id));

    const generateButtons = (client: IClient) => {
        return (
            <Fragment>
                <DartsDeleteModal
                    id={client?.id!}
                    deleteAction={deleteClient}
                    confirmText={"Are you sure you want to delete this client?"}
                    titleModal={"Delete the user"}
                />
                <Button
                    onClick={() => editClientHandler(client.id!)}
                    size="mini"
                    style={{marginTop: 5}}
                    color="yellow"
                >
                    Update
                </Button>
            </Fragment>
        );
    };

    useEffect(() => {
        dispatch(getClients());
    }, [])

    return (
        <Container className='client-container'>
            {(
                <>
                    <Container>
                        <div className="clients-btn-wrapper">
                            <Button
                                disabled={loader}
                                color="blue"
                                id="add_game"
                                onClick={showForm}
                            >
                                Add client
                            </Button>
                        </div>

                    </Container>
                    <div className="clients-page__container">
                        {
                            loader ?
                                <DimmerLoader/>
                                :
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="center">
                                                Client
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                                E-mail
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                                URL Broadcast
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                                Notification
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        { allClients &&
                                            allClients?.map((client: IClient) => {
                                                return (
                                                    <Table.Row key={client.id}>
                                                        <Table.Cell textAlign="center">
                                                            {client?.name}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {client?.email}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {client?.notificationUrl}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            <input
                                                                readOnly={true}
                                                                checked={client?.sendNotification}
                                                                type='checkbox'/>
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {generateButtons(client)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                    </Table.Body>
                                </Table>
                        }
                    </div>
                    {createMode || editMode ? <ClientForm/> : null}
                </>
            )}
        </Container>
    );
};

export default Clients;
