import React from 'react';

import './styles.scss';
import ShootOutScoreBoard from "./ShootOutScoreBoard/ShootOutScoreBoard";

interface IScoreboardPageProps {
}

export const ShootOutScoreboardPage: React.FC<IScoreboardPageProps> = () => {

    return (
        <div className="scoreboard-page shootout">
            <ShootOutScoreBoard/>
        </div>
    );
}
