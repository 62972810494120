import React from "react";
import {Container, Table} from "semantic-ui-react";
import {useEffect} from "react";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import TablePagination from "../../../../components/Pagination/Pagination";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    getDartsWeeks,
    setCurrentCmsWeekPage,
    setWeekOnCurrentPage
} from "../../../../store/cms/darts/weeks/cmsWeeksSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsWeeks = () => {
    const dispatch = useAppDispatch();
    const {
        dartsWeeks,
        currentPage,
        weeksCount,
        weeksOnCurrentPage,
        isLoading,
        noContent,
    } = useAppSelector((state) => state.cmsWeeks);
    const {user} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getDartsWeeks());
    }, []);

    return (
        <Container>
            {noContent ? (
                <div className="schedule-info-block">No weeks</div>
            ) : (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">Id</Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">Number</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Date start</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Date end</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoading && <DimmerLoader/>}
                        {weeksOnCurrentPage.map((week) => {
                            return (
                                <Table.Row>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">{week.id}</Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">{week.number}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {week.dateStart.slice(0, 10)}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {week.dateEnd.slice(0, 10)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {!noContent && (
                <TablePagination
                    setCurrentPage={setCurrentCmsWeekPage}
                    setItemsInCurrentPage={setWeekOnCurrentPage}
                    itemsCount={weeksCount}
                    items={dartsWeeks}
                    currentPage={currentPage}
                    limitItemsInPage={20}
                />
            )}
        </Container>
    );
};

export default DartsWeeks;
