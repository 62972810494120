import moment from "moment";
import React, {Fragment, useEffect, useState} from "react";
import {Button, Container, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DartsGamesForm from "./DartsGamesForm";
import DartsGamesFilter from "./components/dartsGamesFilter/DartsGamesFilter";
import GamesPagination from "../../../../components/GamesPagination/GamesPagination";
import './style.scss'
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    deleteDartsGame,
    getDartsGames,
    getDartsGamesInit, setEditMode,
    showGamesForm
} from "../../../../store/cms/darts/games/cmsGamesSlice";
import {DartsGamesData} from "../../../../store/cms/darts/games/types";
import {buildQueryString} from "./helpers/buildQueryString";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsGamesTable = () => {
    const [isShowFilter, setIsShowFilter] = useState(false);
    const dispatch = useAppDispatch();
    const {user} = useAppSelector((state) => state.auth);
    const {
        dartsGames,
        isVisibleForm,
        gamesCount,
        noContent,
        filterValues
    } = useAppSelector((state) => state.cmsGames);

    useEffect(() => {
        dispatch(getDartsGames(buildQueryString(filterValues)));
    }, [filterValues]);


    useEffect(() => {
        dispatch(getDartsGamesInit());
    }, []);

    const onOpenFilter = () => {
        setIsShowFilter(true);
    };

    const generateButtons = (game: DartsGamesData) => {
        if (game.status === "NotActive" || game.statusString === "NotActive") {
            return (
                <Fragment>
                    <DartsDeleteModal
                        id={game.id}
                        deleteAction={(id: number) => deleteDartsGame(id)}
                        confirmText={"Are you sure you want to delete your handler?"}
                        titleModal={"Delete your Game"}
                    />
                    <Button
                        onClick={() => dispatch(setEditMode(game.id))}
                        size="mini"
                        style={{marginTop: 5}}
                        color="yellow"
                    >
                        Update
                    </Button>
                </Fragment>
            );
        }
    };

    const showForm = () => {
        dispatch(showGamesForm())
    }


    return (
        <Container>
            <div className="cms-action-btns-inner">
                <div>
                    <Button color="yellow" onClick={onOpenFilter}>
                        Filter
                    </Button>
                    <Button color="green" id="add_game" onClick={showForm}>Create game</Button>
                </div>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No games at the moment</div>
            ) : (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) && <Table.HeaderCell textAlign="center">
                                Id
                            </Table.HeaderCell>}
                            <Table.HeaderCell textAlign="center">
                                Phase
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Week
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Group
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Home player
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Away player
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Date start
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Leg To Win
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dartsGames?.map((game) => {
                            return (
                                <Table.Row key={game.id}>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">
                                            {game.id}
                                        </Table.Cell>
                                    }

                                    <Table.Cell textAlign="center">
                                        {game.phaseName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.weekName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.groupName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{game.location}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.playerHomeName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.playerAwayName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {moment(game.timeStart)
                                            .local()
                                            .format("YYYY-MM-DD hh:mm")}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{
                                        game.status === 1 ? 'Not Active' : game.status}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.legsToWin}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {generateButtons(game)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {isVisibleForm && <DartsGamesForm/>}
            {isShowFilter && (
                <DartsGamesFilter
                    closeFilter={setIsShowFilter}
                />
            )}
            {!noContent && (
                <GamesPagination
                    itemsCount={gamesCount}
                    limitItemsInPage={10}
                />
            )}
        </Container>
    );
};

export default DartsGamesTable;
