import React, {Fragment, FunctionComponent, useEffect, useState} from 'react'
import {Button, Container, Table} from "semantic-ui-react";
import DartsShootOutForm from "../DartsGames/components/DartsShootOutForm/DartsShootOutForm";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import './style.scss'
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    deleteShootOutGame,
    getDartsShootOutGames, getOneShootOutDartsGame,
    getShootOutDartsInit
} from "../../../../store/cms/darts/shootOutGames/cmsShootOutGamesSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsShootOutGames: FunctionComponent = () => {
    const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const {shootOutGames} = useAppSelector((state) => state.cmsShootOutGames)
    const {user} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getDartsShootOutGames())
    }, [])

    const setEditGameId = (id: number) => {
        dispatch(getOneShootOutDartsGame(id))
        setIsOpenForm(true)
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        dispatch(getShootOutDartsInit());
    }, []);

    const generateButtons = (game: any) => {
        if (game.status === "NotActive") {
            return (
                <Fragment>
                    <DartsDeleteModal
                        id={game.id}
                        deleteAction={deleteShootOutGame}
                        confirmText={"Are you sure you want to delete your handler?"}
                        titleModal={"Delete your Game"}
                    />
                    <Button
                        onClick={() => setEditGameId(game.id)}
                        size="mini"
                        style={{marginTop: 5}}
                        color="yellow"
                    >
                        update
                    </Button>
                </Fragment>
            );
        }
    };

    return (
        <Container>
            <div className="cms-action-btns-inner">
                <div>
                    <Button onClick={() => setIsOpenForm(true)} color="green" id="add_game">Create game</Button>
                </div>
            </div>
            {(
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user)}
                            <Table.HeaderCell textAlign="center">
                                Id
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Player 1
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Player 2
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Player 3
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Start time</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Event Status
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {shootOutGames.map((game) => {
                            return (
                                <Table.Row key={game.id}>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">
                                            {game.id}
                                        </Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">
                                        {game.tournamentName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{game.location}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.player1Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.player2Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.player3Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {moment(game.timeStart)
                                            .local()
                                            .format("YYYY-MM-DD hh:mm:ss")}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{game.status}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {generateButtons(game)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {isOpenForm && <DartsShootOutForm setIsOpenForm={setIsOpenForm}/>}
        </Container>);
};

export default DartsShootOutGames;
