import React, {useEffect} from "react";
import { Pagination } from "semantic-ui-react";
import "./index.scss";
import {useAppDispatch} from "../../hooks/hooks";

interface ITablePaginationProps {
  setCurrentPage:(pageNumber:number)=>void
  setItemsInCurrentPage:(itemsArray:any[])=>void
  itemsCount:number
  items:any[]
  currentPage:number
  limitItemsInPage:number
}
const TablePagination: React.FC<ITablePaginationProps> = ({
  setCurrentPage,
  setItemsInCurrentPage,
  itemsCount,
  items,
  currentPage,
  limitItemsInPage,
}) => {
  const dispatch = useAppDispatch();
  const setItemListForCurrentPage = (currentPage:number, limit:number, itemArray:any[]) => {
    let startSlice = (currentPage - 1) * limit;
    let endSlice = startSlice + limit;
    let itemsOnCurrentPage = itemArray.slice(startSlice, endSlice);
    dispatch(setItemsInCurrentPage(itemsOnCurrentPage));
  };

  const onChangePage = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: any
  ) => {
    dispatch(setCurrentPage(activePage));
  };
  let totalPages = Math.ceil(itemsCount / limitItemsInPage);

  useEffect(() => {
    if (items && currentPage) {
      setItemListForCurrentPage(currentPage, limitItemsInPage, items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, items]);

  return (
    <div>
      <Pagination
        floated="right"
        size="mini"
        defaultActivePage={1}
        totalPages={totalPages}
        onPageChange={onChangePage}
      />
    </div>
  );
};

export default TablePagination;
