import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Grid} from 'semantic-ui-react';
import PlayerScoreboardHeader from './PlayerScoreboardHeader';
import PlayerScoreboardBody from './PlayerScoreboardBody';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import PausePopUp from "./PausePopUp/PausePopUp";
import {useSpring} from "react-spring";
import './styles.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {getActiveGameScoreData} from "../../../store/pages/activeGameScore/toolkit/activeGameScoreSlice";
import {DARTS_ADC_OR_CLASSIC} from "../../../constants/globalTypes";
import {IActionGameScorePayload} from "../../../store/pages/activeGameScore/toolkit/types";
import {GameTypes} from "../../../enums/gameEvents";


const Scoreboard: React.FC = () => {
    const {leagueId: leagueIdParam} = useParams<{ leagueId: string }>();
    const {gameType} = useParams<{ gameType: string }>();
    const dispatch = useAppDispatch()
    const {activeGameData, loader} = useAppSelector((state) => state.activeGameScore)
    const connected = useAppSelector((state) => state.connection.connection)
    const [isShowPopUp, setIsShowPopUp] = useState<boolean>(false)

    useEffect(() => {

        if (connected && Object.values(gameType).length) {
            if (Object.values(gameType).length) {
                if (!leagueIdParam && gameType === GameTypes.CLASSIC) {
                    return console.warn("Tournament id required for get active tournament data.")
                }
                const payload: IActionGameScorePayload = {
                    tournamentId: +leagueIdParam,
                    gameType: gameType.toUpperCase() as DARTS_ADC_OR_CLASSIC
                }
                dispatch(getActiveGameScoreData(payload))
            }
        }
    }, [connected, leagueIdParam]);

    useEffect(() => {
        if (activeGameData && activeGameData.legFinished) {
            setIsShowPopUp(true)
        }
    }, [activeGameData])

    const popUpStyle = useSpring({
        to: async (next) => {
            await next({
                opacity: isShowPopUp ? 1 : 0,
                display: isShowPopUp ? "flex" : "none",
            })
        },
        from: {opacity: 0, display: 'none'},
        config:{duration:300}
    })

    return (
        <div>
            <PausePopUp
                animStyle={popUpStyle}
                legFinished={activeGameData?.legFinished}
                setIsShowPopUp={setIsShowPopUp}
            />
            <Grid columns={2} className="scoreboard">
                {!loader ? (
                    <>
                        <Grid.Row className="scoreboard_header">
                            <Grid.Column id="left_player">
                                <PlayerScoreboardHeader
                                    legsCount={activeGameData?.legs?.home}
                                    isPlayerHomeAway={activeGameData?.playerHomeAway === true}
                                    remainingScore={activeGameData?.score?.home}
                                    playerName={activeGameData?.players?.home}/>
                            </Grid.Column>
                            <Grid.Column id="right_player">
                                <PlayerScoreboardHeader
                                    legsCount={activeGameData?.legs?.away}
                                    //@ts-ignore
                                    isPlayerHomeAway={activeGameData?.playerHomeAway === false}
                                    remainingScore={activeGameData?.score?.away}
                                    playerName={activeGameData?.players?.away}
                                    reverse
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="scoreboard_body">
                            <Grid.Column id="left_score">
                                <PlayerScoreboardBody
                                    turnData={activeGameData.homeData}
                                />
                            </Grid.Column>
                            <Grid.Column id="right_score">
                                <PlayerScoreboardBody
                                    turnData={activeGameData.awayData}
                                    reverse
                                />
                            </Grid.Column>

                        </Grid.Row>
                    </>
                ) : <OverlayLoader/>}
            </Grid>
        </div>
    );
}


export default Scoreboard;
Scoreboard.displayName = "Scoreboard";
