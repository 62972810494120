import {DartsGamesInitData} from "../../../../../store/cms/darts/adcGames/types";
import {useMemo} from "react";

interface ICountriesOption {
    key: number;
    value: string | number;
    text: string;
}
interface IOptions {
    key: number;
    value: number;
    text: string;
}

export const useGetOptions = ({countries, competitions, players}: DartsGamesInitData)  => {
    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };
    const countriesOptions: ICountriesOption[] = useMemo(() => {
        const options = countries?.map((country) => ({
            key: country.intCode,
            value: country.alpha2Code,
            text: country.englishName,
        }))
        return options
    }, [countries])

    const playersOptions: IOptions[] = useMemo(() => {

        const options = players?.map((player) => ({
            key: player.id,
            value: player.id,
            text: player.displayname,
        }))
        return options
    }, [players])

    const competitionOptions: IOptions[] = useMemo(() => {
        const options = competitions?.map((competition) => ({
            key: competition.id,
            value: competition.id,
            text: competition.name,
        }))
        return options

    }, [competitions])

    return {competitionOptions, countriesOptions, playersOptions, dayPickerOptions}
}
