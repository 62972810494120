import moment from "moment";
import React from "react";
import { Grid } from "semantic-ui-react";
import { convertUtcToLocalDate } from "../../../../helpers/date.helper";
import classnames from "classnames";
import "./styles.scss";
import {useAppSelector} from "../../../../hooks/hooks";
import {ILiveScoreEvents} from "../../../../store/pages/liveScoreInfo/types";

interface IMatchListItemProp {
  event: ILiveScoreEvents;
  onSelect: (eventId: number, isAdc: boolean) => void;
}

export const MatchListItem: React.FC<IMatchListItemProp> = ({
  event,
  onSelect,
}) => {
  const activeSidebarEvent = useAppSelector(
    (state) => state.liveScoreInfo.activeSideBarEvent
  );
  const isEventActive = event.id === activeSidebarEvent;

  const selectGame = () => {
    const isAdc = event.tournamentName.includes('ADC');
    onSelect(event.id!, isAdc);
  };

  const getStatus = (event: ILiveScoreEvents) => {
    if (event.status === "NotActive") {
      const localStartTime = convertUtcToLocalDate(event.timeStart);
      return moment(localStartTime).format("HH:mm");
    } else {
      return event.status;
    }
  };

  return (
    <div
      onClick={selectGame}
      className={classnames("game-item", {
        "game-item--active": isEventActive,
      })}
    >
      <Grid>
        <Grid.Column
          width="4"
          className="status"
          verticalAlign="middle"
          textAlign="center"
        >
          {getStatus(event)}
        </Grid.Column>
        <Grid.Column width="11">
          <Grid verticalAlign="middle">
            <Grid.Row verticalAlign="bottom">
              <span className="name">{event.players?.home}</span>
            </Grid.Row>
            <Grid.Row verticalAlign="top">
              <span className="name">{event.players?.away}</span>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width="1" verticalAlign="middle" textAlign="center">
          <Grid.Row>
            <span>{event.score?.home}</span>
          </Grid.Row>
          <hr />
          <Grid.Row>
            <span>{event.score?.away}</span>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
};
