import {IClient} from "../../../../store/cms/clients/types";

export const createClientPayload = (client: IClient) => {
    return {
        name: client.name,
        email: client.email,
        notificationUrl: client.notificationUrl,
        sendNotification: client.sendNotification

    };
};

export const updateClientPayload = (client: IClient) => {
    return {
        id: client.id,
        name: client.name,
        email: client.email,
        notificationUrl: client.notificationUrl,
        sendNotification: client.sendNotification
    }
}

export default createClientPayload;
