import React, {useMemo} from 'react';
import {IDartsGameCountry, IDartsGamePlayer, IDartsTournament} from "../../../../../store/cms/darts/games/types";

interface IUseGetOptions {
    countries:  IDartsGameCountry[];
    players:  IDartsGamePlayer[];
    tournaments:   IDartsTournament[];


}
const useGetOptions = ({countries, players, tournaments}: IUseGetOptions) => {

    const countriesOptions = useMemo(() => {
        return countries.map((country) => ({
            key: country.intCode,
            value: country.alpha2Code,
            text: country.englishName,
        }))
    }, [countries])

    const playersOptions = useMemo(() => {
        return players?.map((player) => ({
            key: player.id,
            value: player.id,
            text: player.displayname,
        }))
    }, [players])


    const tournamentsOptions = useMemo(() => {
        return tournaments?.map((tour) => ({
            key: tour.id,
            value: tour.id,
            text: tour.name,
        }))
    }, [tournaments])
    return (
        {countriesOptions, playersOptions, tournamentsOptions}
    )
};

export default useGetOptions;
