import {DartsPlayer} from "../../../../../store/cms/darts/players/types";

interface IGetFilteredPayload {
    dartsPlayers: DartsPlayer[];
    searchValue: string;
}
export const getFilteredPayload = ({dartsPlayers, searchValue}: IGetFilteredPayload) => {
    return dartsPlayers.filter(
        (player) =>
            player.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            player.surname.toLowerCase().includes(searchValue.toLowerCase()) ||
            player.hometown.toLowerCase().includes(searchValue.toLowerCase()) ||
            player.throwHand.toLowerCase().includes(searchValue.toLowerCase()) ||
            player.nickname?.toLowerCase().includes(searchValue.toLowerCase()) ||
            player.dateOfBirth.toLowerCase().includes(searchValue.toLowerCase())
            || player.displayname.toLowerCase().includes(searchValue.toLowerCase())
    );
}
