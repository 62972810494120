import React, { Fragment, useEffect, useState } from "react";
import { Button, Ref, Table } from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";

const ShootOutLogTable: React.FC = React.memo(() => {
  const gameId = useAppSelector((state) => state.gameHandler.game.id)
  const dartsCurrentScore = useAppSelector((state) => state.currentDartsGame.currentShootOutGameInitData)
  const dispatch = useAppDispatch();
  const { timeline } = dartsCurrentScore;
  const [infoLogs, setInfoLogs] = useState<null | any[] | undefined>(null);

  const refs = timeline?.reduce((acc: any, value: any, i: number) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToElement = (index: number) => {
    if (refs[index].current) {
      refs[index].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const reversTimeLine = (logs: any) => {
    const reverseLogs = logs.concat();
    return setInfoLogs(reverseLogs.reverse());
  };

  useEffect(() => {
    if (timeline?.length) {
      scrollToElement(0);
      reversTimeLine(timeline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeline]);

  const doubleAttemptRequest = (gameId: number, throwId: number) => {
    const doubleAttemptBody = {
      date: moment().utc().toDate(),
      gameId,
      throwId,
    };
    // dispatch(dartsCurrGameActionCreators.getDoubleAttempt(doubleAttemptBody));
  };

  const genDoubleAttemptionBtn = (
      isDoubleAttemption: boolean | null,
      throwId: number
  ) => {
    if (isDoubleAttemption) {
      return (
          <Button
              onClick={() => doubleAttemptRequest(gameId, throwId)}
              color="green"
              active
              content="Double Attempt"
          />
      );
    }
    if (isDoubleAttemption === false) {
      return (
          <Button
              onClick={() => doubleAttemptRequest(gameId, throwId)}
              color="grey"
              active
              content="Double Attempt"
          />
      );
    } else {
      return null;
    }
  };

  const generateBackground = (lineDetail: any) => {
    if (lineDetail.playerNum === 1 && lineDetail.isThrow) {
      return "lightblue";
    }
    if (lineDetail.playerNum === 2 && lineDetail.isThrow) {
      return "#f4b084";
    }
    if (lineDetail.playerNum === 3 && lineDetail.isThrow) {
      return "#27706f";
    }
    else {
      return "black";
    }
  };

  return (
      <Table className="time-line-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Leg</Table.HeaderCell>
            <Table.HeaderCell>Player</Table.HeaderCell>
            <Table.HeaderCell>DartScore</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            <Fragment>
              {infoLogs?.map((line, i) => {
                return (
                    <Ref innerRef={refs[i]} key={i}>
                      <Table.Row
                          key={i}
                          style={{
                            background: generateBackground(infoLogs[i]),
                            color: line.isThrow ? "black" : "white",
                          }}
                      >
                        <Table.Cell>{line.leg}</Table.Cell>
                        <Table.Cell>{removeNameSeparator(line.player)}</Table.Cell>
                        <Table.Cell>
                          {line.score === null ? "" : line.score}
                        </Table.Cell>
                        <Table.Cell>{line.description}</Table.Cell>
                        <Table.Cell>
                          {genDoubleAttemptionBtn(line.isDoubleAttempt, line.id)}
                        </Table.Cell>
                      </Table.Row>
                    </Ref>
                );
              })}
            </Fragment>
          }
        </Table.Body>
      </Table>
  );
})





export default ShootOutLogTable;
