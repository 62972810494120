import * as signalR from "@microsoft/signalr";
import {LS_TOKEN} from "./constants/localStorageItems";
import {isConnected, WEBSOCKET_CONNECT_TYPE} from "./store/webSockets/webSocketsSlice";
import {
    setEndGame,
    setLastShot,
    setSidebar,
    updateDataWithSocket
} from "./store/pages/liveScoreInfo/liveScoreInfoSlice";
import {
    ILiveScore,
    ILiveScoreSidebar,
    OnEndGameLiveScore,
    ShotUpdateSocketData
} from "./store/pages/liveScoreInfo/types";
import {getPreMatchStats} from "./store/pages/preMatch/preMatchSlice";
import {deleteActiveGameAndTournament, setCsvStatistics, setDartsInitData} from "./store/pages/schedule/scheduleSlice";
import {setActiveGameData} from "./store/pages/activeGameScore/toolkit/activeGameScoreSlice";
import {IActiveTournamentGameData} from "./store/pages/activeGameScore/toolkit/types";
import {
    setDataTurns180s,
    updateBestOf7Legs,
    updatePlayerStats, updateShootOutBestOf7Legs
} from "./store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import {
    IDataTurns180s,
    IScoreBoardBest7Leg,
    IScoreBoardPlayersStatistics
} from "./store/pages/scoreboardAndStats/types";
import {setPostMatchPlayerStatistics} from "./store/pages/postMatch/postMatchSlice";
import {shootOutSetGameData} from "./store/pages/shootOutActiveGameScore/shootOutActiveGameScoreSlice";
import {setLeagueTable} from "./store/pages/leagueTable/leagueTableSlice";
import {IDartsInitData} from "./store/pages/schedule/types";

const getConnection = (token: string): signalR.HubConnection => {

    return new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_ENDPOINT}/api/interactions`, {accessTokenFactory: () => token})
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
};

const createSocketMiddleware = () => {
    let hubConnection: signalR.HubConnection;
    let userId = -1;
    return (storeAPI: any) => (next: any) => (action: any) => {
        switch (action.type) {
            case WEBSOCKET_CONNECT_TYPE: {
                userId = action.payload;
                const token = localStorage.getItem(LS_TOKEN);
                if (!token) {
                    console.error('Token is empty');
                    return next(action);
                }
                hubConnection = getConnection(token);
                hubConnection.start().then(() => {
                    console.log("Connected");
                    storeAPI.dispatch(isConnected(true));
                });
                hubConnection.onclose((error: any = '') => {
                    console.log("Connection closed. " + error);
                    storeAPI.dispatch(isConnected(false));
                });

                hubConnection.on('onShotUpdate', (data) => {

                    const resp: ShotUpdateSocketData = JSON.parse(data);
                    storeAPI.dispatch(setLastShot(resp));
                });

                hubConnection.on('onLiveScoreSideBar', (data) => {
                    const sidebar: ILiveScoreSidebar = JSON.parse(data);
                    storeAPI.dispatch(setSidebar(sidebar.locations))
                });

                hubConnection.on('onUpdateLifeScore', (data) => {
                    const resp: ILiveScore = JSON.parse(data);
                    storeAPI.dispatch(updateDataWithSocket(resp))
                });

                hubConnection.on('onEndGameLiveScore', (data) => {
                    const resp: OnEndGameLiveScore = JSON.parse(data);
                    storeAPI.dispatch(setEndGame(resp))

                });

                hubConnection.on('onGameEnd', () => {
                    const url = window.location.href;
                    const penultimateSlashIndex = url.split('/');
                    const currentPageUrl = penultimateSlashIndex[penultimateSlashIndex.length - 2]

                    if (currentPageUrl === 'pre-match-animation' || currentPageUrl === 'pre-match-stats') {
                        storeAPI.dispatch(getPreMatchStats());
                    }
                    storeAPI.dispatch(deleteActiveGameAndTournament());
                    if (currentPageUrl === 'darts') {
                        window.location.href = '/schedule'
                    }
                });
                hubConnection.on('onTournamentActiveGameScore', (data) => {
                    const resp: IActiveTournamentGameData = JSON.parse(data);
                    storeAPI.dispatch(setActiveGameData(resp))
                });

                hubConnection.on('onTournamentActiveGame9shotsScore', (data) => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(shootOutSetGameData(resp))
                });

                hubConnection.on('onLiveScoreTurns180', (data) => {
                    const resp: IDataTurns180s = JSON.parse(data);
                    storeAPI.dispatch(setDataTurns180s(resp))
                });


                hubConnection.on('onPlayersStatistics', data => {
                    const resp: IScoreBoardPlayersStatistics = JSON.parse(data);
                    storeAPI.dispatch(updatePlayerStats(resp));
                    storeAPI.dispatch(setPostMatchPlayerStatistics(resp))
                });

                hubConnection.on('onBestOfSevenLeg', data => {
                    const resp: IScoreBoardBest7Leg = JSON.parse(data);
                    storeAPI.dispatch(updateBestOf7Legs(resp));
                });
                hubConnection.on('onBestOfSevenLeg9shots', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(updateShootOutBestOf7Legs(resp));
                });

                hubConnection.on('onLeagueTable', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(setLeagueTable(resp))
                });

                hubConnection.on('onStatisticsCSV', data => {
                    const resp = JSON.parse(data);
                    storeAPI.dispatch(setCsvStatistics(resp));
                });
                hubConnection.on('onDartsInit', data => {
                    const resp: IDartsInitData = JSON.parse(data);
                    storeAPI.dispatch(setDartsInitData(resp))
                });

                return next(action);
            }

            default: {
                return next(action)
            }
        }
    }
};

export {createSocketMiddleware}
