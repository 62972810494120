import React from "react";

export const renderFirstThrowerInLegPointer = (firstThrower: boolean | null) => {
    if (!firstThrower) {
        return
    }
    return (
        <div
            className="current-thrower"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/scoreboard-widget-arrow.png)`,
            }}
        />
    );
};
