import {getTagId} from "./getId";
import {IFormData} from "../MssNewsForm/MssNewsForm";
import {imgUrlToFile} from "./imgUrlToFile";

export const formDataHandlers = (formData: IFormData, textEditorValue: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', "0")
    bodyFormData.append('newsDate', formData.dateValue)
    bodyFormData.append('title', formData.titleValue)
    bodyFormData.append('subtitle', formData.subtitleValue)
    bodyFormData.append('text', textEditorValue)
    bodyFormData.append('tag', getTagId(formData.tagValue).toString())
    bodyFormData.append('images[0].id', "0")
    bodyFormData.append('images[0].newsId', "0")
    bodyFormData.append('images[0].image', imgUrlToFile(formData.fileValue, formData.imgName))
    bodyFormData.append('videoUrl', formData.youtubeLink)
    return bodyFormData
}
export const updateFormData = (formData: IFormData, textEditorValue: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', formData.newsId.toString())
    bodyFormData.append('newsDate', formData.dateValue)
    bodyFormData.append('title', formData.titleValue)
    bodyFormData.append('subtitle', formData.subtitleValue)
    bodyFormData.append('text', textEditorValue)
    bodyFormData.append('tag', getTagId(formData.tagValue).toString())
    bodyFormData.append('images[0].id', formData.imageId.toString())
    bodyFormData.append('images[0].newsId', formData.newsId.toString())
    bodyFormData.append('images[0].image', imgUrlToFile(formData.imgValue, formData.imgName))
    return bodyFormData
}