import React from "react";
import {Grid} from "semantic-ui-react";
import "./styles.scss";
import {IScoreBoardProps} from "./types";
import {useAppDispatch} from "../../../../hooks/hooks";
import {ISetFirstPlayer} from "../../../../store/dartsGame/currentGame/types";
import {setFirstPlayer} from "../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {Player} from "../Player/Player";
import {setSelectedColumnStyle} from "./helpers/setSelectedColumnStyle";
import {GameTypes} from "../../../../enums/gameEvents";
import {useHistory} from "react-router-dom";

const ScoreBoard: React.FC<IScoreBoardProps> = React.memo(({
       currentPlayerId,
       initData,
    }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const pathName = history.location.pathname;
    const dartsAdc = pathName.includes("darts-adc");
    const score: any[] = initData?.score;
    const scoreForCurrentGame = score?.filter((scoreArray) => scoreArray !== null);
    const setDartsPlayer = (payload: ISetFirstPlayer) => {
        if (!currentPlayerId) {
            const {gameData} = payload;
            dispatch(setFirstPlayer({
                gameType: dartsAdc ? GameTypes.ADC : GameTypes.CLASSIC,
                gameData
            }));
        } else {
            return alert("Game already started and first player already changed");
        }
    };
    const basicStyles: React.CSSProperties = {
        width: "50%",
        height: "100%",
        position: "absolute",
        background: "#fbbd08",
    };

    const getTextColorForColumn = (id: number | null) =>
        id === currentPlayerId ? {color: "#454d55"} : {color: "white"};

    return (
        <Grid columns={2} divided>
            <div
                className="custom-background"
                style={setSelectedColumnStyle({
                    id: initData?.playerIDs?.home,
                    currentPlayerId,
                    basicStyles
                })}
            />

            <Grid.Row>
                <Grid.Column style={getTextColorForColumn(initData?.playerIDs?.home)}>
                        <Player
                            isPlayingFirst={initData?.playerIDs?.home === currentPlayerId}
                            isHome={true}
                            onSelect={setDartsPlayer}
                            leg={initData?.legs?.home}
                            playerId={initData?.playerIDs?.home}
                            gameId={initData?.gameId}
                            isCurrentPlayerId={currentPlayerId}
                        />
                </Grid.Column>

                <Grid.Column style={getTextColorForColumn(initData?.playerIDs?.away)}>
                        <Player
                            isPlayingFirst={initData?.playerIDs?.away === currentPlayerId}
                            isHome={false}
                            onSelect={setDartsPlayer}
                            leg={initData?.legs?.away}
                            playerId={initData?.playerIDs?.away}
                            gameId={initData?.gameId}
                            isCurrentPlayerId={currentPlayerId}
                        />
                </Grid.Column>
            </Grid.Row>

            {scoreForCurrentGame?.map((playerScore) => {
                return (
                    <Grid.Row>
                        <Grid.Column style={getTextColorForColumn(initData.playerIDs.home)}>
                            {playerScore.home}
                        </Grid.Column>
                        <Grid.Column style={getTextColorForColumn(initData.playerIDs.away)}>
                            {playerScore.away}
                        </Grid.Column>
                    </Grid.Row>
                );
            })}
        </Grid>
    );
})

export {ScoreBoard};
