import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {Pagination} from "semantic-ui-react";
import {getUsers} from "../../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {FilterValues} from "../../../../../store/cms/usersNRoles/types";

interface IUsersPaginationProps {
    itemsCount: number;
    limitItemsInPage: number;
}
const UsersPagination: React.FC<IUsersPaginationProps> = ({
                                                              itemsCount,
                                                              limitItemsInPage,
                                                          }) => {
    const dispatch = useAppDispatch();

    const {filterValues} = useAppSelector((state) => state.cmsUsersNRoles)
    let totalPages = Math.ceil(itemsCount / limitItemsInPage);

    const onChangePage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, {activePage}: any) => {
        const params = {...filterValues, pNum: activePage}
        fetchUsers(params);
    };

    const fetchUsers = (params: FilterValues): void => {
        dispatch(getUsers(params));
    }

    return (
        <div>
            <Pagination
                floated="right"
                size="mini"
                activePage={filterValues.pNum}
                totalPages={totalPages}
                onPageChange={onChangePage}
            />
        </div>
    );
};

export default UsersPagination;
