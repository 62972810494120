import React, {PropsWithChildren, useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";
import {client} from "../../../../../services/api.service";
import {AxiosResponse} from "axios";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {setFirstPlayer} from "../../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {
    ICurrentShootOutGame,
    IFirstPlayerPayload,
    IShootOutCurrentGame
} from "../../../../../store/dartsGame/currentGame/types";
import {getSeparateDisplayNames} from "./helpers/getSeparateDisplayNames";
import {GameTypes} from "../../../../../enums/gameEvents";


interface IScoreBoardProps {
    currentPlayerId: number | null;
    initData: IShootOutCurrentGame;
    gameId: number
    propsAreEqual?: (
        prevProps: Readonly<PropsWithChildren<IScoreBoardProps>>,
        nextProps: Readonly<PropsWithChildren<IScoreBoardProps>>
    ) => boolean
}

const ShootOutScoreBoard: React.FC<IScoreBoardProps> = React.memo(({
                                                                       currentPlayerId,
                                                                       initData,
                                                                       gameId
                                                                   }) => {
    const [currentGameInfo, setCurrentGameInfo] = useState<ICurrentShootOutGame | null>(null)
    const dispatch = useAppDispatch();
    const score: any[] = initData?.score;
    const setDartsPlayer = (playerId: number | null, payload: IFirstPlayerPayload) => {
        if (!playerId) {
            const setFirstPlayerPayload = {
                gameType: GameTypes.SHOOTOUT,
                gameData: payload
            }
            dispatch(setFirstPlayer(setFirstPlayerPayload))
        } else {
            return alert("Game already started and first player already changed");
        }
    };
    const basicStyles: React.CSSProperties = {
        width: "33.3333%",
        height: "100%",
        position: "absolute",
        background: "#fbbd08",
        color: '#454d55'
    };
    const getActiveGameInfo = async (gameId: number) => {
        try {
            //in the 9 shots uses tournamentId instead of gameId
            const resp: AxiosResponse = await client.get(`/api/DartsGames9shots/${gameId}`)
            const DartsGames9Shots: ICurrentShootOutGame = resp.data;
            setCurrentGameInfo(DartsGames9Shots);
        } catch (e) {
            if (e instanceof Error) {
                console.log(e.message)
            }
        }
    };

    useEffect(() => {
        if (gameId) {
            getActiveGameInfo(gameId)
        }
    }, [gameId]);


    const getSelectedColumnStyle = (id: number | null) => {
        if (!currentPlayerId) {
            return {...basicStyles, display: "none"};
        } else if (id === currentGameInfo?.player1id) {
            return basicStyles;
        } else if (id === currentGameInfo?.player2id) {
            return {...basicStyles, right: '33%'};
        } else {
            return {...basicStyles, right: 0};
        }
    };

    getSeparateDisplayNames(initData.gameName);

    const getTextColorForColumn = (id: number | undefined) => id === currentPlayerId ? {color: "#454d55"} : {color: "white"};

    return (
        <Grid columns={3} divided>
            <div
                className="custom-background"
                style={getSelectedColumnStyle(currentPlayerId)}
            />
            <Grid.Row>
                <Grid.Column style={getTextColorForColumn(currentGameInfo?.player1id)}>
                    <Player
                        playerName={getSeparateDisplayNames(initData.gameName)?.pl1Name}
                        playerSurname={getSeparateDisplayNames(initData.gameName)?.pl1Surname}
                        onSelect={setDartsPlayer}
                        playerId={currentGameInfo?.player1id}
                        gameId={gameId}
                        currentPlayerId={currentPlayerId}
                    />
                </Grid.Column>
                <Grid.Column style={getTextColorForColumn(currentGameInfo?.player2id)}>
                    <Player
                        playerName={getSeparateDisplayNames(initData.gameName)?.pl2Name}
                        playerSurname={getSeparateDisplayNames(initData.gameName)?.pl2Surname}
                        onSelect={setDartsPlayer}
                        playerId={currentGameInfo?.player2id}
                        gameId={gameId}
                        currentPlayerId={currentPlayerId}
                    />
                </Grid.Column>
                <Grid.Column style={getTextColorForColumn(currentGameInfo?.player3id)}>
                    <Player
                        playerName={getSeparateDisplayNames(initData.gameName)?.pl3Name}
                        playerSurname={getSeparateDisplayNames(initData.gameName)?.pl3Surname}
                        onSelect={setDartsPlayer}
                        playerId={currentGameInfo?.player3id}
                        gameId={gameId}
                        currentPlayerId={currentPlayerId}
                    />
                </Grid.Column>
            </Grid.Row>

            {currentGameInfo && score?.map((playerScore) => {
                return (
                    <Grid.Row>
                        <Grid.Column style={getTextColorForColumn(currentGameInfo!.player1id)}>
                            {playerScore.p1}
                        </Grid.Column>
                        <Grid.Column style={getTextColorForColumn(currentGameInfo!.player2id)}>
                            {playerScore.p2}
                        </Grid.Column>
                        <Grid.Column style={getTextColorForColumn(currentGameInfo!.player3id)}>
                            {playerScore.p3}
                        </Grid.Column>
                    </Grid.Row>
                );
            })}
        </Grid>
    );
})

interface IPlayerProps {
    playerId: number | undefined;
    playerName: string | undefined;
    playerSurname: string | undefined;
    leg?: number;
    onSelect: (playerId: number | null, payload: any) => void;
    gameId: number;
    currentPlayerId: number | null;
}

const Player: React.FC<IPlayerProps> = ({
                                            playerName,
                                            playerSurname,
                                            onSelect,
                                            leg,
                                            playerId,
                                            gameId,
                                            currentPlayerId
                                        }) => {
    const payload = {
        gameId,
        playerId: playerId,
        date: moment().utc().toDate(),
    };
    return (
        <div
            className="player-name__shoot-container"
            onClick={() => onSelect(currentPlayerId, payload)}
        >
            <div className="player-name__score">{leg}</div>
            <div className="player-name__shoot-details">
                <div className="player-name__shoot-details-name">{playerName}</div>
                <div className="player-name__shoot-details-surname">{playerSurname}</div>
            </div>
        </div>
    );
};

export {ShootOutScoreBoard};
