import React, {useEffect, useState} from "react";
import {Button} from "semantic-ui-react";
import "./styles.scss";
import {getPlayerName} from "../../../helpers/playersNames.helper";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {renderQualifierIcon} from "./helpers/renderQualifierIcon";
import {getStatisticPicture} from "./helpers/getStatisticsPicture";
import {getLeagueTableData} from "../../../store/pages/leagueTable/leagueTableSlice";

interface ILeagueTableProps {
    leagueId: number;
    isShowDownLoadBtn: boolean;
}

const LeagueTable: React.FC<ILeagueTableProps> = React.memo(({leagueId, isShowDownLoadBtn}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    const {table, name, tournamentId} = useAppSelector((state) => state.leagueTable)

    useEffect(() => {
        dispatch(getLeagueTableData());
    }, []);

    return (
        <div className="league-table-container">
            {(
                <div>
          <span className="title-container">
            <h1 className="title">{name?.split(',')?.slice(1)?.join(',')}</h1>
              {isShowDownLoadBtn && <div className="download-stats-btn">
                  <Button className="download-png-stats-btn" color="red" loading={isLoading}
                          onClick={() => getStatisticPicture({setIsLoading, leagueId})}>
                      Download Table
                  </Button>
              </div>}
          </span>
                    <div className="table-container">
                        {tournamentId && (
                            <div className="league-table">
                                <div className="table-header-wrapp">
                                    <div className="league-table-cell">P</div>
                                    <div className="league-table-cell">W</div>
                                    <div className="league-table-cell">L</div>
                                    <div className="league-table-cell">LW</div>
                                    <div className="league-table-cell league-table-cell-plus-minus">(+/-)</div>
                                    <div className="league-table-cell league-table-cell-pts">PTS</div>
                                </div>
                                <div className="league-table-body">

                                    {table?.items
                                        .map((teamStats, index: number) => {
                                            return (
                                                <div className="league-table-row"
                                                     key={teamStats?.playerId || teamStats?.player}>
                                                    <div
                                                        className="cell-padding cell-padding-index">{index + 1}
                                                    </div>
                                                    <div className="cell-padding cell-padding-names">
                                                        <span className="cell-padding-firstname">
                                                            {getPlayerName(teamStats?.player)[0]}
                                                        </span>
                                                        {getPlayerName(teamStats?.player)[1]}
                                                        {
                                                            renderQualifierIcon(teamStats?.adcQualifier)

                                                        }
                                                    </div>
                                                    <div className='cell-padding'>{teamStats?.p}</div>
                                                    <div className='cell-padding'>{teamStats?.w}</div>
                                                    <div className='cell-padding'>{teamStats?.l}</div>
                                                    <div className='cell-padding'>{teamStats?.legs}</div>
                                                    <div className='cell-padding'>{teamStats?.diff}</div>
                                                    <div className="points">
                                                        {teamStats?.pts}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                        {table.items.length === undefined && (
                            <div>
                                <h2 className="no-matches">No matches have been played yet!</h2>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
})

export default LeagueTable;
LeagueTable.displayName = "LeagueTable";
