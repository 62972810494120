import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IDataTurns180s,
    IGetScoreBoardStatsPayload, IScoreBoardAndStatsResp,
    IScoreBoardAndStatsState,
    IScoreBoardBest7Leg,
    IScoreBoardPlayersStatistics, IScoreBoardShootOutBest7Legs
} from "./types";
import {
    API_DARTS_BEST_OF_7_LEGS_WIDGET,
    API_DARTS_SCOREBOARD_STATS,
    API_DARTS_SCOREBOARD_STATS_ADC,
    API_DARTS_SCOREBOARD_STATS_SHOOTOUT
} from "../../../constants";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {GameTypes} from "../../../enums/gameEvents";
import {ILeagueTable} from "../leagueTable/types";


const initialState: IScoreBoardAndStatsState = {
    playersStatistics: {} as IScoreBoardPlayersStatistics,
    bestOfSevenLeg: {} as IScoreBoardBest7Leg,
    shootOutBestOfSevenLegs: {} as IScoreBoardShootOutBest7Legs,
    leagueTable: {} as ILeagueTable,
    dataTurns180s: {} as IDataTurns180s,
    statsLoader: true,
    currentGameId: 0
}

export const getScoreboardStatsInit = createAsyncThunk<IScoreBoardAndStatsResp, IGetScoreBoardStatsPayload, { rejectValue: string }>(
    'scoreboardAndStats/schedule',
    async (gameParams, {rejectWithValue}) => {
        const {gameId, gameType} = gameParams;
        try {
            if (gameType.toUpperCase() === GameTypes.ADC) {
                const {data} = await client.get(`${API_DARTS_SCOREBOARD_STATS_ADC}/${gameId}`);
                return data;
            }
            if (gameType.toUpperCase() === GameTypes.CLASSIC) {
                const {data} = await client.get(`${API_DARTS_SCOREBOARD_STATS}/${gameId}`);
                return data;
            }
        } catch (e) {
            if (e as AxiosError) {
                toastr.error('Scoreboard and stats:', `${e.response.data}`);
                console.log(e?.message)
                return rejectWithValue(e.response.data);
            }
        }
    }
)

export const getBestOf7Legs = createAsyncThunk<IScoreBoardBest7Leg, number, { rejectValue: string }>(
    'scoreboardAndStats/bestOf7Legs',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_BEST_OF_7_LEGS_WIDGET}/${id}`);
            return data;
        } catch (e) {
            toastr.error('Best of 7 legs widget:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }
    }
)

export const getScoreboardStatsShootOutInit = createAsyncThunk<
    IScoreBoardShootOutBest7Legs,
    number,
    { rejectValue: string }
    >(
    'scoreboardAndStats/shootOutInit',
    async (gameId, {rejectWithValue}) => {
        try {
                const {data} = await client.get(`${API_DARTS_SCOREBOARD_STATS_SHOOTOUT}/${gameId}`);
                return data;
        } catch (e) {
            if (e as AxiosError) {
                toastr.error('Scoreboard and stats 9 legs:', `${e.response.data}`);
                console.log(e?.message)
                return rejectWithValue(e.response.data);
            }
        }
    }
)

export const scoreboardAndStatsSlice = createSlice({
    name: 'scoreboardAndStats',
    reducers: {
        setCurrentGameId: (state, action: PayloadAction<number>) => {
            state.currentGameId = action.payload;
        },
        setDataTurns180s: (state, action: PayloadAction<IDataTurns180s>) => {
            state.dataTurns180s = action.payload;
        },
        updatePlayerStats: (state, action: PayloadAction<IScoreBoardPlayersStatistics>) => {
            state.playersStatistics = action.payload;
        },
        updateBestOf7Legs: (state, action: PayloadAction<IScoreBoardBest7Leg>) => {
            state.bestOfSevenLeg = action.payload;
        },
        updateShootOutBestOf7Legs: (state, action: PayloadAction<IScoreBoardShootOutBest7Legs>) => {
            state.shootOutBestOfSevenLegs = action.payload;
        },
        resetHomeAwayTurn: (state) => {
            state.dataTurns180s.playerHomeAway = null
        },

    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getScoreboardStatsInit.pending, (state: IScoreBoardAndStatsState) => {
                state.statsLoader = true;
            })
            .addCase(getScoreboardStatsInit.fulfilled, (state: IScoreBoardAndStatsState, action) => {
                state.leagueTable = action.payload.ligaTable;
                state.bestOfSevenLeg = action.payload.bestOfSevenLeg;
                state.playersStatistics = action.payload.playersStatistics;
                state.dataTurns180s.turns180inPhase = action.payload.turns180inPhase;
                state.dataTurns180s.turns180inWeek = action.payload.turns180inWeek;
                state.statsLoader = false;
            })
            .addCase(getScoreboardStatsInit.rejected, (state: IScoreBoardAndStatsState) => {
                state.statsLoader = false;
            })
            .addCase(getScoreboardStatsShootOutInit.pending, (state: IScoreBoardAndStatsState) => {
                state.statsLoader = true;
            })
            .addCase(getScoreboardStatsShootOutInit.fulfilled, (state: IScoreBoardAndStatsState, action) => {
                state.shootOutBestOfSevenLegs = action.payload;
                state.statsLoader = false;
            })
            .addCase(getBestOf7Legs.fulfilled, (state: IScoreBoardAndStatsState, action) => {
                state.bestOfSevenLeg = action.payload;
            })
            .addCase(getScoreboardStatsShootOutInit.rejected, (state: IScoreBoardAndStatsState) => {
                state.statsLoader = false;
            })
    }})

export const {
    setCurrentGameId,
    setDataTurns180s,
    updatePlayerStats,
    updateBestOf7Legs,
    updateShootOutBestOf7Legs,
    resetHomeAwayTurn
} = scoreboardAndStatsSlice.actions;
export default scoreboardAndStatsSlice.reducer;
