import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DartsGames,
    DartsGamesData,
    GetDartsGamesResponse,
    ICreateDartsGamePayload,
    IDartsGameCountry,
    IDartsGamePlayer, IDartsGamesFilter,
    IDartsGameStatus,
    IDartsTournament, IEditDartsGamePayload,
    IGamesInitData,
} from "./types";
import {client} from "../../../../services/api.service";
import {API_DARTS_GAMES, API_DARTS_GAMES_INIT_DATA} from "../../../../constants";
import {toastr} from "react-redux-toastr";
import {errorLogger} from "../../../../services/error-logger";

import {
    ICurrentDartsTours, IDartsGroup,
} from "../tournaments/types";
import {createFilterParamsPayload} from "./helpers/createFilterParamsPayload";
import {IDartsPhase} from "../phases/types";

const initialState: DartsGames = {
    dartsGames: [] as DartsGamesData[],
    initGamesData: {
        statuses: [] as IDartsGameStatus[],
        tournaments: [] as IDartsTournament[],
        players: [] as IDartsGamePlayer[],
        countries: [] as IDartsGameCountry[],
        groups:[] as IDartsGroup[],
        competitions: [] as IDartsTournament[],
        phases: [] as IDartsPhase[]
    },
    loadingGames: null,
    editMode: false,
    editGameId: null,
    isVisibleForm: false,
    gamesCount: 0,
    isVisibleFullWeekForm: false,
    noContent: null,
    filterValues: {
        competitionID: 0,
        Week: 0,
        Group: 0,
        Phase: 0,
        location: null,
        Player: 0,
        From: "",
        Status: 0,
        pNum: 1,
        pSize: 10,
        gameDateDirection: 0
    }
}

export const getDartsGames = createAsyncThunk<GetDartsGamesResponse, string>(
    'dartsGames/get',
    async (queryString,{rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_GAMES}?${queryString}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts games.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `dartsGames/get: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const onClearFilterParams = createAsyncThunk<void, void>(
    'dartsGames/clearFilterParams',
    async (_,{dispatch,rejectWithValue}) => {
        try {
            dispatch(clearFilterParams());
            const {data} = await client.get(`${API_DARTS_GAMES}?pNum=${1}&pSize=${10}`);
            const {allGames} = data;
            dispatch(setDartsGames(allGames));


        } catch (error) {
            toastr.error('CMS', 'Failed to clear the filter params.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `dartsGames/clearFilterParams: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            dispatch(setGamesNoContent(true));
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getDartsGamesInit = createAsyncThunk<IGamesInitData | undefined, void>(
    'dartsGames/getDartsInit',
    async (_, {dispatch, rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_GAMES_INIT_DATA}`);
            return data as IGamesInitData;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts games schedule data.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `dartsGames/getDartsInit: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createDartsGame = createAsyncThunk<DartsGamesData, ICreateDartsGamePayload>(
    'dartsGames/create',
    async (game, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_DARTS_GAMES, game);
            toastr.success("CMS", "Darts game has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `dartsGames/create: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const editDartsGame = createAsyncThunk<DartsGamesData, IEditDartsGamePayload>(
    'dartsGames/edit',
    async (game, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_GAMES}/${game.id}`, game);
            toastr.success("CMS", "Darts game has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a handler.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `dartsGames/edit: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteDartsGame = createAsyncThunk<ICurrentDartsTours, number>(
    'dartsGames/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_GAMES}/${id}`);
            toastr.success("", "Darts game has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a darts handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `dartsGames/delete: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const cmsGames = createSlice({
    name: 'cmsDartsGames',
    reducers: {
        showGamesForm: (state) => {
            state.isVisibleForm = true
        },
        hideGamesForm: (state, ) => {
            state.isVisibleForm = false;
            state.editMode = false;
        },
        setEditMode: (state, action: PayloadAction<number>) => {
            state.editMode = true
            state.isVisibleForm = true;
            state.editGameId = action.payload;
        },
        clearFilterParams: (state) => {
            state.filterValues = createFilterParamsPayload()
        },
        setDartsGames: (state, action: PayloadAction<DartsGamesData[]>) => {
            state.dartsGames = action.payload;
        },
        setGamesCount: (state, action: PayloadAction<number>) => {
            state.gamesCount = action.payload;
        },
        setGamesNoContent: (state, action: PayloadAction<boolean>) => {
            state.noContent = action.payload;
        },
        setGameFilterValues: (state, action: PayloadAction<IDartsGamesFilter>) => {
            const {field, value} = action.payload;
            state.filterValues = {...state.filterValues, [field]: value};
        },
        setCurrentPaginationPage: (state,action:PayloadAction<number>) => {
            state.filterValues = {...state.filterValues,pNum:action.payload}
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsGames.pending, (state) => {
                state.loadingGames = true;
            })
            .addCase(getDartsGames.fulfilled, (state, action) => {
                if (action.payload) {
                    state.gamesCount = action.payload.totalQuantity;
                    state.dartsGames = action.payload.games;
                    state.noContent = false;
                    state.loadingGames = false;
                }
            })
            .addCase(getDartsGames.rejected, (state) => {
                state.loadingGames = false;
            })
            .addCase(createDartsGame.pending, (state) => {
                state.loadingGames = true;
                state.isVisibleForm = false;
            })
            .addCase(createDartsGame.fulfilled, (state, action) => {
                state.loadingGames = false;
                state.dartsGames = state.dartsGames.concat(action.payload);
            })
            .addCase(createDartsGame.rejected, (state) => {
                state.loadingGames = false;
            })
            .addCase(editDartsGame.pending, (state) => {
                state.loadingGames = true;
                state.isVisibleForm = false;
            })
            .addCase(editDartsGame.fulfilled, (state, action) => {
                state.loadingGames = false;
                state.dartsGames = state.dartsGames.map((game) => {
                    if (game.id === action.payload.id) {
                        game = action.payload
                    }
                    return game
                })
            })
            .addCase(editDartsGame.rejected, (state) => {
                state.loadingGames = false;
            })

            .addCase(getDartsGamesInit.fulfilled, (state, action) => {
                state.initGamesData = action.payload as IGamesInitData;
            })
            .addCase(deleteDartsGame.pending, (state) => {
                state.loadingGames = true;
            })
            .addCase(deleteDartsGame.fulfilled, (state, action) => {
                state.loadingGames = false;
                state.dartsGames = state.dartsGames.filter(
                    (game) => game.id !== action.meta.arg
                );
            })
            .addCase(deleteDartsGame.rejected, (state) => {
                state.loadingGames = false;
            })





    }})
export const {
    showGamesForm,
    hideGamesForm,
    setEditMode,
    clearFilterParams,
    setDartsGames,
    setGamesNoContent,
    setGameFilterValues,
    setCurrentPaginationPage
} = cmsGames.actions;
export default cmsGames.reducer;
