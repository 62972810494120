import {IUser, IUserPayload} from "../../../../../store/cms/usersNRoles/types";
import {createUser, editUser} from "../../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {AppDispatch} from "../../../../../store";
import {toastr} from "react-redux-toastr";

interface ISetUserHandler {
    user: IUserPayload;
    allUsers: IUser[];
    dispatch: AppDispatch;
    editMode: boolean;
}

export const setUserHandler = (
    {
        user,
        allUsers,
        dispatch,
        editMode
    }: ISetUserHandler) => {

    const existingUserEmail = allUsers.find((existingUser) => {
        return existingUser?.email?.trim() === user.email.trim();
    });

    if (editMode) {
            return dispatch(editUser(user))
    }

    if (existingUserEmail && !editMode) {
        return toastr.info('CMS', 'This e-mail is already used.');
    }

    return dispatch(createUser(user))
};
