import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "./styles.scss";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    getScoreboardStatsShootOutInit,
    resetHomeAwayTurn
} from "../../../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import GameScoreFooter from "../GameScores/components/GameScoreFooter";

const ShootOutGameScores: React.FC = () => {
    const {gameId} = useParams<{ gameId: string }>();
    const dispatch = useAppDispatch();
    const [animationAway180, setAnimationAway180] = useState(false);
    const [animationHome180, setAnimationHome180] = useState(false);
    const {dataTurns180s} = useAppSelector((state) => state.scoreboardAndStats);
    const {
        players,
        numThrows,
        firstPlayer,
        score,
        nextThrow
    } = useAppSelector((state) => state.scoreboardAndStats.shootOutBestOfSevenLegs);

    useEffect(() => {
        if (dataTurns180s.playerHomeAway === false) {
            setAnimationAway180(true);
            setTimeout(() => {
                setAnimationAway180(false);
            }, 2000);
            dispatch(resetHomeAwayTurn())
        }
        if (dataTurns180s.playerHomeAway === true) {
            setAnimationHome180(true);
            setTimeout(() => {
                setAnimationHome180(false);
            }, 2000);
            dispatch(resetHomeAwayTurn())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTurns180s.playerHomeAway]);

    useEffect(() => {
        dispatch(getScoreboardStatsShootOutInit(Number(gameId)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentThrowerStyles = (currThrower: any) => {
        return {
            background: currThrower

                ? "linear-gradient(rgb(255, 207, 64), rgb(255, 207, 64))"
                : "inherit",
        };
    };


    const renderFirstThrowerInLegPointer = (firstThrower: boolean) => {

        if (!firstThrower) {
            return;
        }
        return (
            <div
                className="current-thrower"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/dark-green-dart.png)`,
                }}
            />
        );
    };


    return (
        <div className="root__container">
            <div className="game-scores">
                <div className="game-scores__container">
                    <div className="wrapper">
                        <div className="game-scores-table">
                            <div className="game-scores-header">
                                <div className="name-cell">
                                    <div>9 Dart Shootout</div>
                                </div>
                                <div className="game-scores-header-right-wrapper">
                                    <div className="game-scores-right-score">
                                        <div>Score</div>
                                    </div>
                                </div>

                            </div>
                            <div className="game-scores-body">
                                <div className="game-scores-row">
                                    <div className="game-scores-row__player">
                                        <div className="player__container">
                                            <div className="player__info">
                                                <div
                                                    className="player__throws"
                                                    style={currentThrowerStyles(nextThrow === 1)}
                                                >
                                                    {numThrows?.i1}
                                                </div>
                                                <div
                                                    className="player__name"
                                                >
                                                    {removeNameSeparator(players?.s1)}
                                                </div>
                                            </div>
                                            <div>
                                                {renderFirstThrowerInLegPointer(firstPlayer === 1)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="game-scores-body-scores-wrapper">
                                        <div className="score score-value">
                                            <div>{score?.i1}</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="game-scores-row">
                                    <div className="game-scores-row__player">
                                        <div className="player__container">
                                            <div className="player__info">
                                                <div
                                                    className="player__throws"
                                                    style={currentThrowerStyles(nextThrow === 2)
                                                    }
                                                >
                                                    {numThrows?.i2}
                                                </div>
                                                <div className="player__name"
                                                >
                                                    {removeNameSeparator(players?.s2)}
                                                </div>
                                            </div>
                                            {renderFirstThrowerInLegPointer(firstPlayer === 2)}
                                        </div>
                                    </div>

                                    <div className="score score-value">
                                        <div>{score?.i2}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="game-scores-row">
                                <div className="game-scores-row__player">
                                    <div className="player__container">
                                        <div className="player__info">
                                            <div
                                                className="player__throws"
                                                style={currentThrowerStyles(nextThrow === 3)
                                                }
                                            >
                                                {numThrows?.i3}
                                            </div>
                                            <div className="player__name"
                                            >
                                                {removeNameSeparator(players?.s3)}
                                            </div>
                                        </div>
                                        {renderFirstThrowerInLegPointer(firstPlayer === 3)}
                                    </div>
                                </div>
                                <div className="game-scores-body-scores-wrapper">
                                    <div className="score score-value">
                                        <div>{score?.i3}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GameScoreFooter/>
                    </div>
                    <div
                        className={
                            animationAway180
                                ? "animation-180-away-active"
                                : "animation-180-away"
                        }
                    >
                        <p>180</p>
                    </div>
                    <div
                        className={
                            animationHome180
                                ? "animation-180-home-active"
                                : "animation-180-home"
                        }
                    >
                        <p>180</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShootOutGameScores;
