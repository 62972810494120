import React, {Fragment, useState} from "react";
import {Button, Icon, Modal} from "semantic-ui-react";
import {ConfirmationModal} from "../../../../components/ConfirmationModal";
import moment from "moment";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {DartsGameItem, ICurrentShootOutGame, IGameData} from "../../../../store/dartsGame/handler/types";
import {setWalkover} from "../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {setWalkoverModal} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {IWalkoverPayload} from "../../../../store/dartsGame/currentGame/types";
import {GameTypes} from "../../../../enums/gameEvents";

interface IWalkoverModalProps {
  open: boolean;
  onConfirm: (winnerTeamId: any) => void;
  onDecline: () => void;
  currentGameInfo: DartsGameItem | IGameData | ICurrentShootOutGame;
}

const WalkoverModal: React.FC<IWalkoverModalProps> = ({
  open,
  onDecline,
  currentGameInfo,
}) => {
  const history = useHistory();
  const pathName = history.location.pathname;
  const isDartsAdc = pathName.includes("darts-adc");
  const {isShowWalkoverModal} = useAppSelector((state) => state.gameHandler);
  const {currentGameInitData} = useAppSelector((state) => state.currentDartsGame);
  const dispatch = useAppDispatch();
  const [selectedTeam, setSelectedTeam] = useState(undefined);

  const handleHomeSelected = () => {

    const homeTeam = {
      id: currentGameInitData?.playerIDs?.home,
      name: currentGameInitData?.playerNames?.home,
    };
    //@ts-ignore
    setSelectedTeam(homeTeam);
    return dispatch(setWalkoverModal(false));
  };

  const handleAwaySelected = () => {
    const awayTeam = {
      id: currentGameInitData?.playerIDs?.away,
      name: currentGameInitData?.playerNames?.away,
    };

    //@ts-ignore
    setSelectedTeam(awayTeam);
    return dispatch(setWalkoverModal(false));
  };


  const handleConfirmationModalConfirm = () => {
    if (selectedTeam !== undefined) {
      const walkoverPayload: IWalkoverPayload = {
        gameData: {
          gameId: currentGameInfo.id,
          date: moment().utc().toDate(),
          //@ts-ignore
          winnerId: selectedTeam?.id,
        },
        gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC,
        history
      };
      setSelectedTeam(undefined);
      dispatch(setWalkover(walkoverPayload))
    }
  };

  const handleConfirmationModalDecline = () => {
    onDecline();
    setSelectedTeam(undefined);
  };

  const closeHandler = () => dispatch(setWalkoverModal(false));

  return (
    <Fragment>
      <Modal open={open && isShowWalkoverModal} size="tiny" autoFocus>
        <Icon
            color="red"
            name="close"
            size="large"
            onClick={closeHandler}
            style={{float: "right", cursor: "pointer", transform: 'translate(-30px, 35px)'}}
        />
        <Modal.Header>Additional action required</Modal.Header>
        <Modal.Content>Which player has been awarded with win?</Modal.Content>
        <Modal.Actions>
          <Button
            onClick={handleHomeSelected}
          >{`Home - ${removeNameSeparator(currentGameInitData?.playerNames?.home)}`}</Button>
          <Button
            onClick={handleAwaySelected}
          >{`Away - ${removeNameSeparator(currentGameInitData?.playerNames?.away)}`}</Button>
        </Modal.Actions>
      </Modal>
      <ConfirmationModal
        open={selectedTeam !== undefined}
        onConfirm={handleConfirmationModalConfirm}
        onDecline={handleConfirmationModalDecline}
        //@ts-ignore
        content={`Confirm ${removeNameSeparator(selectedTeam?.name)} has been awarded the win due to a walkover?`}
      />
    </Fragment>
  );
};

export default WalkoverModal;
