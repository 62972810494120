import {client} from "../../../services/api.service";
import {errorLogger} from "../../../services/error-logger";
import React from "react";
import {DartsPlayer} from "../../../store/cms/darts/players/types";

interface IShowPlayerCard {
    btnName: string;
    playerId: number;
    setPlayerInfo: React.Dispatch<React.SetStateAction<DartsPlayer>>;
    setActiveBtnName:  React.Dispatch<React.SetStateAction<string | null>>;
    setIsShowPlayerCard: React.Dispatch<React.SetStateAction<boolean>>;
}
export const showPlayerCard = async ({
                                         btnName,
                                         playerId,
                                         setPlayerInfo,
                                         setActiveBtnName,
                                         setIsShowPlayerCard
}: IShowPlayerCard) => {
    if (playerId === 0) return;
    try {
        const playerInfo = await client.get(`api/DartsPlayers/${playerId}`)
        setPlayerInfo(playerInfo.data as DartsPlayer)
        setActiveBtnName(btnName)
        setIsShowPlayerCard(true)
    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `showPlayerCard: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
        }
    }
}
