import React, {useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {Icon} from 'semantic-ui-react';
import {SidebarContent} from './SidebarContent/SidebarContent';
import './styles.scss'
import {useAppSelector} from "../../../../hooks/hooks";
import {renderSidebarItems} from "./helpers/renderSidebarItems";

interface ISidebarProps  {
    name: string;
}

const Sidebar: React.FC<ISidebarProps> = ({name}) => {
    const [isOpen, setIsOpen] = useState(true);
    const {url} = useRouteMatch();
    const {user} = useAppSelector((state) => state.auth);
    const toogleSidebar = () => setIsOpen(!isOpen);

    let items;
    if (user.id) {
        items = renderSidebarItems(user, url);
        return (
            <div className='animation-container'>
                <div className='sidebar-container' style={{
                    backgroundColor: isOpen ? 'lightgray' : 'white',
                    position: isOpen ? 'fixed' : 'absolute',
                    height: !isOpen ? 'calc(100ch - 95px)' : ''
                }}>
                    <div className='sidebar-icon'>
                        <div
                            className='sidebar-icon-text'
                            onClick={toogleSidebar}>
                            <Icon
                                name={isOpen ? 'close' : 'bars'}
                                size={!isOpen ? 'large' : 'small'}
                            />
                            {!isOpen && name}
                        </div>
                    </div>
                    <SidebarContent
                        initialItems={items}
                        isOpen={isOpen}
                    />
                </div>
            </div>

        )
    }
        return null

}


export default Sidebar;
