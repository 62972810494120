import React from "react";
import { Grid } from "semantic-ui-react";
import classnames from "classnames";
import "./styles.scss";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";

interface IHeaderProps {
  reverse?: boolean;
  playerName: string;
  legsCount: number;
  isPlayerHomeAway: boolean;
  remainingScore: number;
}


const PlayerScoreboardHeader: React.FC<IHeaderProps> = ({
  reverse,
  playerName,
  legsCount,
  isPlayerHomeAway,
  remainingScore,
}) => {
  return (
    <Grid columns={16} className="player-scoreboard-header">
      {(
          <>
            <Grid.Row
                className={classnames("row", "name-row", {
                  reverse: reverse,
                  active: isPlayerHomeAway,
                })}
            >
              <Grid.Column width={16} className="player-name col name-col">
                {removeNameSeparator(playerName)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
                className={classnames("row", "scores-row", {reverse: reverse})}
            >
              <Grid.Column width={12} className="col remaining-score-col">
                {remainingScore}
              </Grid.Column>
              <Grid.Column width={4} className="col won-legs-col">
                {legsCount}
              </Grid.Column>
            </Grid.Row>
          </>
      )}
    </Grid>
  );
};

export default PlayerScoreboardHeader
