import React from 'react';
import {Button, DropdownItemProps} from "semantic-ui-react";
import {GameTypes} from "../../../../enums/gameEvents";
import {IGameType} from "../../hooks/useSelectCurrentGame";
import {useHistory} from "react-router-dom";

interface IScheduleInformButtonsProps {
    gamesOptions: DropdownItemProps[] | undefined
    selectedGame: number
    selectedTournament: number
    gamesType: IGameType
}

const ScheduleInformButtons: React.FC<IScheduleInformButtonsProps> = (
    {
        gamesOptions,
        selectedGame,
        selectedTournament,
        gamesType

    }) => {

    const history = useHistory();

    const goToLeagueList = () => {
        history.push(`/darts/leagues/${selectedTournament}`);
    };

    const gotToPreMatchAnimation = () => {
        history.push(`/pre-match-animation/${selectedGame}`);
    };

    const gotToPreMatchStats = () => {
        history.push(`/pre-match-stats/${selectedGame}`);
    };

    const gotToPostMatch = () => {
        history.push(`post-match-page/${selectedGame}`);
    };

    return (
        <div className="schedule__login">
            {!!(selectedTournament && selectedGame && gamesOptions !== undefined && gamesOptions.length > 0) &&
                <>
                    <Button
                        disabled={gamesType.type === GameTypes.ADC}
                        id="league_table"
                        color="brown"
                        onClick={goToLeagueList}
                    >
                        League table
                    </Button>
                    <Button
                        disabled={gamesType.type === GameTypes.ADC} id="scoreboard"
                        color="blue"
                        onClick={gotToPreMatchAnimation}>
                        Pre-Match Animation
                    </Button>
                    <Button
                        disabled={gamesType.type === GameTypes.ADC} id="scoreboard"
                        color="facebook"
                        onClick={gotToPreMatchStats}>
                        Pre-Match Image
                    </Button>
                    <Button
                        id="scoreboard"
                        color="violet"
                        onClick={gotToPostMatch}>
                        Post-Match
                    </Button>
                </>
            }


        </div>
    );
};

export default ScheduleInformButtons;
