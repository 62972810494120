import {Button, Form, Icon, Label, Select} from "semantic-ui-react";
import React, {useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    clearSingleUserState,
    createModeHandler,
    editModeHandler, getRoles
} from "../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {
    IUserPayload,
    IUserFormData,
    IRole
} from "../../../../store/cms/usersNRoles/types";
import {createUserPayload} from "./helpers/createPayload";
import {setUserHandler} from "./helpers/hasExistingUser";


const UsersForm = () => {
    const dispatch = useAppDispatch();
    const {roles, allUsers, user, editMode} = useAppSelector((state) => state.cmsUsersNRoles);
    const {register, control, handleSubmit, errors, setValue} = useForm();

    const roleNames = useMemo(() => {
        return roles.map((role: IRole) => ({
            key: role.id,
            value: role.name,
            text: role.name,
        }))
    }, [roles])

    const onSubmit = (inputData: IUserFormData) => {
        if (editMode) {
            const payload: IUserPayload = createUserPayload({inputData, roles, userId: user.id})
            setUserHandler({user: payload, allUsers, dispatch, editMode})
        }
        if (!editMode) {
            const payload: IUserPayload = createUserPayload({inputData, roles});
            setUserHandler({user: payload, allUsers, dispatch, editMode});
        }
    }


    const closeForm = () => {
        dispatch(createModeHandler(false));
        dispatch(editModeHandler(false));
        dispatch(clearSingleUserState());
    }

    useEffect(() => {
        if (editMode && user.roles) {
            setValue('userRole', user.roles)
        }
    }, [user])

    useEffect(() => {
        dispatch(getRoles())
    }, [dispatch]);

    return (
        <div className="form-overlay">
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className={"darts-users-form"}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={closeForm}
                    style={{float: "right", cursor: "pointer"}}
                />

                <Form.Field>
                    <Label className="darts-users-form-label">User name</Label>
                    <input defaultValue={user.name} name="userName" ref={register({required: true, minLength: 3})}/>

                    {errors.userName && errors.userName.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The user name field is required</p>
                        </div>}
                    {errors.userName && errors.userName.type === 'minLength' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>This field required min 3 symbols</p>
                        </div>}
                </Form.Field>

                <Form.Field>
                    <Label className="darts-users-form-label">E-mail</Label>
                    <input type={'email'} defaultValue={user.email} name="userEmail"
                           ref={register({required: true, minLength: 5})}/>

                    {errors.userEmail && errors.userEmail.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The user e-mail field is required</p>
                        </div>}
                    {errors.userEmail && errors.userEmail.type === 'minLength' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>This field required min 5 symbols</p>
                        </div>}
                </Form.Field>

                {!editMode &&
                    <Form.Field>
                        <Label className="darts-users-form-label">Password</Label>
                        <input name="userPassword" ref={register({required: true, minLength: 4})}/>

                        {errors.userPassword && errors.userPassword.type === 'required' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>The user password field is required</p>
                            </div>}
                        {errors.userPassword && errors.userPassword.type === 'minLength' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>This field required min 5 symbols</p>
                            </div>}
                    </Form.Field>
                }

                <Form.Field>
                    <Label className="darts-users-form-label">Role</Label>

                    {
                        editMode ?
                            <Controller
                                name="userRole"
                                control={control}
                                render={(props) => (
                                    <Select
                                        multiple
                                        value={props.value}
                                        id="userRole"
                                        options={roleNames}
                                        placeholder="Select user role"
                                        onChange={(e, {value}) => props.onChange(value)}
                                    />

                                )}
                            />
                            :
                            <Controller
                                name="userRole"
                                control={control}
                                render={(props) => (
                                    <Select
                                        multiple
                                        value={props.value}
                                        id="userRole"
                                        options={roleNames}
                                        placeholder="Select user role"
                                        onChange={(e, {value}) => props.onChange(value)}
                                    />
                                )}
                            />
                    }
                </Form.Field>

                <Button id="save_game" color="green" type="submit">
                    {editMode ? 'Update' : 'Create'}
                </Button>
            </Form>
        </div>
    );
}

export default UsersForm;
