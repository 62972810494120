import {Table} from "semantic-ui-react";
import {INewsItem} from "../../../../store/cms/modusNews/types";
import moment from "moment";
import {renderControlButtons} from "./renderControlButtons";
import React from "react";
import noPhoto from "../../../../img/no-photo.jpeg";
import {AppDispatch} from "../../../../store";

interface IGenerateNewsTableProps {
    news: INewsItem[];
    isLoading: boolean;
    isOpen: boolean;
    editMode: boolean;
    dispatch: AppDispatch;
}

export const renderNewsTable = (newsTableProps: IGenerateNewsTableProps): React.JSX.Element => {
    const IMG_SRC = 'data:image/png;base64,';
    const {news, isLoading, isOpen, editMode, dispatch} = newsTableProps;
    const getImage = (item: INewsItem) => {
        if (item.images !== null) {
            return `${IMG_SRC} ${item?.images[0]?.icon}`;
        }
        return noPhoto;
    }
    return (
        <div className='mss-news-container'>
            <Table className='mss-table'>
                <Table.Header className='mss-table-header'>
                    <Table.Row>
                        <Table.HeaderCell>
                            Title
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Image
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    isLoading
                }
                <Table.Body className='mss-table-body'>
                    {
                        news.map((item: INewsItem) => {
                            return (
                                <Table.Row className='mss-table-row' key={item.id}>
                                    <Table.Cell className={'mss-table-title'}>
                                        {item?.title}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {moment(item?.newsDate).format("MMM Do YY")}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <img className={'newsImg'} alt={'image'} src={getImage(item)}/>
                                    </Table.Cell>
                                    {renderControlButtons({
                                            id: item.id,
                                            date: moment(item.newsDate).format("MMM Do YY"),
                                            isLoading,
                                            isOpen,
                                            editMode,
                                            dispatch,
                                        }
                                    )
                                    }

                                </Table.Row>
                            );
                        })
                    }
                </Table.Body>
            </Table>
        </div>
    );
};
