import React, {useEffect, useState} from "react";
import {Button, Form, FormField, Icon, Input, Label} from "semantic-ui-react";
import './style.scss'
import {toastr} from "react-redux-toastr";
import CheckboxCms from "../../../../../components/CheckboxCms/CheckboxCms";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    createDartsCompetition,
    editDartsCompetition,
    hideCompetitionForm
} from "../../../../../store/cms/darts/competitions/competitionsSlice";
import {DartsCompetition} from "../../../../../store/cms/darts/competitions/types";
import {isExistsCompetitionName} from "./helpers/isExistsCompetitionName";

const DartsCompetitionsForm = () => {
    const dispatch = useAppDispatch();
    const {
        dartsCompetitions,
        editMode, editCompetitionId
    } = useAppSelector((state) => state.cmsCompetitions);
    const [isADC, setIsADC] = useState(false);
    const [competitionName, setCompetitionName] = useState<string>('');

    useEffect(() => {
        if (editMode) {
            const updatedCompetition = dartsCompetitions.find(
                (competition) => competition.id === editCompetitionId
            );
            setCompetitionName(updatedCompetition!.name);
            setIsADC(updatedCompetition!.isADC);
        }
    }, [editMode]);


    const submitCompetitions = () => {
        if (dartsCompetitions) {
            const isExists = isExistsCompetitionName(
                {
                    dartsCompetitions,
                    competitionName: competitionName as string
                }
            );
            if (!isExists) {
                const newCompetitions: DartsCompetition = {
                    name: competitionName!,
                    isADC: isADC
                };
                if (!competitionName?.trim()) return toastr.error("", "The competition name field can not be empty");
                dispatch(createDartsCompetition(newCompetitions));
                setCompetitionName('');

            } else return toastr.error("", "This competition already exists");
        }
        setCompetitionName('');
    };

    const updateCompetition = (id: number) => {
        const updatedCompetition = {
            id,
            name: competitionName,
            isADC
        };
        if (!competitionName?.trim()) return toastr.error("", "The competition name field can not be empty");
        dispatch(editDartsCompetition(updatedCompetition as DartsCompetition));
        setCompetitionName('');
    };

        const onCloseForm = () => {
            dispatch(hideCompetitionForm());
        }

        const onSubmit = (editMode: boolean, editCompetitionId?: number) => {
            return editMode ? updateCompetition(editCompetitionId!) : submitCompetitions()
        }

        return (
            <div className="form-overlay">
                <Form
                    onSubmit={() => onSubmit(editMode, editCompetitionId!)}
                    className={"competition-form"}
                >
                    <Icon
                        color="red"
                        name="close"
                        size="large"
                        onClick={onCloseForm}
                        style={{float: "right", cursor: "pointer"}}
                    />
                    <FormField>
                        <Label className={'competition-label'}>Competition name:</Label>
                        <Input
                            id="competition_name"
                            value={competitionName}
                            onChange={(e) => setCompetitionName(e.target.value)}
                        />
                    </FormField>
                    <FormField>
                        <div className={'competition-adc-qualifier'}>
                            <Label className={'competition-label'}>ADC Qualifier:</Label>
                            <div className={'competition-checkbox'}>
                                <CheckboxCms value={isADC} handler={() => setIsADC(!isADC)}/>
                            </div>
                        </div>
                    </FormField>
                    <Button id="save_competition" type="submit" color="green" size="mini">
                        submit
                    </Button>
                </Form>
            </div>
        );
    };

export default DartsCompetitionsForm;
