import moment from "moment";
import React, {Fragment, useEffect, useState} from "react";
import {Button, Container, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import "./styles.scss";
import {GameStatus} from "../../../../enums/gameStatus";
import {
    deleteDartsAdcGame, getAdcDartsGames,
    getAdcDartsGamesInit, getOneDartsAdcGame, setSelectedAdcDartsGame
} from "../../../../store/cms/darts/adcGames/cmsAdcGamesSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {DartsAdcGamesData} from "../../../../store/cms/darts/adcGames/types";
import GamesPagination from "../../../../components/GamesPagination/GamesPagination";
import DartsAdcGamesForm from "./DartsAdcGamesForm";
import DartsAdcGamerFilter from "./DartsAdcGamerFilter";
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsAdcGamesTable = () => {
    const dispatch = useAppDispatch();
    const {
        games,
        isLoading,
        gamesCount,
        filterValues,
        noContent} = useAppSelector((state) => state.cmsAdcGames);
    const [isShowForm, setIsShowForm] = useState(false);
    const [isShowFilter, setIsShowFilter] = useState(false);
    const {user} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getAdcDartsGamesInit());
        dispatch(getAdcDartsGames(buildQueryString(filterValues)))
    }, []);


    const editHandler = (id: number) => {
        setIsShowForm(true);
        dispatch(getOneDartsAdcGame(id));
    }

    const generateButtons = (game: DartsAdcGamesData) => {
        //@ts-ignore
        if (game.status === GameStatus.Cancelled  || game.status === GameStatus.End) return
        return (
            <Fragment>
                <DartsDeleteModal
                    id={game.id}
                    deleteAction={(id: number) => deleteDartsAdcGame(id)}
                    confirmText={"Are you sure you want to delete your handler?"}
                    titleModal={"Delete your Game"}
                />
                <Button
                    onClick={() => editHandler(game.id)}
                    size="mini"
                    style={{marginTop: 5}}
                    color="yellow"
                >
                    Update
                </Button>
            </Fragment>
        );
    };

    const closeFormHandler = () => {
        setIsShowForm(false);
        dispatch(setSelectedAdcDartsGame(null))
    }

    const closeFilter = () => {
        setIsShowFilter(false)
    }
    const showForm = () => {
        setIsShowForm(true);
    }
    const onOpenFilter = () => {
        setIsShowFilter(true);
    };
    return (
        <Container>
            <div className="cms-action-btns-inner">
                <div>
                    <Button color="yellow" onClick={onOpenFilter}>
                        Filter
                    </Button>
                    <Button disabled={isLoading} color="green" id="add_game" onClick={showForm}>Create game</Button>
                </div>
            </div>
            {isLoading ? <DimmerLoader/> :
                <Table celled>
                        <Table.Header>
                            <Table.Row>
                                {checkIsUserAdmin(user) &&
                                    <Table.HeaderCell textAlign="center">
                                        Id
                                    </Table.HeaderCell>
                                }
                                <Table.HeaderCell textAlign="center">
                                    Competition
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Home player
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Away player
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Date start
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Legs to win
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Status
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center"/>
                            </Table.Row>
                        </Table.Header>

                            <Table.Body>
                                {/* eslint-disable-next-line array-callback-return */}
                                {Boolean(games.length) && games?.map((game) => {
                                        return (
                                            <Table.Row key={game.id}>
                                                {checkIsUserAdmin(user) &&
                                                    <Table.Cell textAlign="center">
                                                        {game?.id}
                                                    </Table.Cell>
                                                }
                                                <Table.Cell textAlign="center">
                                                    {game?.competitionName}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">{game?.location}</Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {game?.playerHomeName}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {game?.playerAwayName}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {moment(game?.timeStart)
                                                        .local()
                                                        .format("YYYY-MM-DD hh:mm:ss")}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {game?.legsToWin}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {game?.status}
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    {game?.status === 'NotActive' && generateButtons(game)}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                })}
                            </Table.Body>
                </Table>}
            {isShowForm &&
                <DartsAdcGamesForm
                    closeHandler={closeFormHandler}
                />
            }
            {isShowFilter &&
                <DartsAdcGamerFilter closeFilter={closeFilter}/>
            }
            {!noContent && (
                <GamesPagination
                    itemsCount={gamesCount}
                    limitItemsInPage={10}
                    isAdcGame={true}
                />
            )}
        </Container>
    );
};

export default DartsAdcGamesTable;
