import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {
    DartsTournaments, ICreateTournamentPayload,
    ICurrentDartsTours, IEditTournamentPayload,
    ITournamentsInit, IToursOnCurrentPage
} from "./types";
import {API_DARTS_TOURNAMENT_INIT, API_DARTS_TOURNAMENTS} from "../../../../constants";


const initialState: DartsTournaments = {
    dartsTournaments: {} as ITournamentsInit,
    currentDartsTournaments: [] as ICurrentDartsTours[],
    editTournamentId: null,
    editMode: false,
    isVisibleForm: false,
    tournamentsCount: 0,
    tournamentsOnCurrentPage: [] as IToursOnCurrentPage[],
    currentPage: 1,
    isLoading: true,
    noContent: null,
}

export const getDartsTournaments = createAsyncThunk<ICurrentDartsTours[]>(
    'dartsTournaments/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_DARTS_TOURNAMENTS);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts tournaments.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Tournaments: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.message);
        }
    }
)
export const getDartsTournamentsInit = createAsyncThunk<ITournamentsInit>(
    'dartsTournamentsInit/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_DARTS_TOURNAMENT_INIT);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts tournaments init.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Tournaments Init: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const createDartsTournament = createAsyncThunk<ICurrentDartsTours, ICreateTournamentPayload>(
    'dartsTournament/post',
    async (tournament, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_DARTS_TOURNAMENTS, tournament);
            toastr.success("CMS", "Darts tournament has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a tournament.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create darts tournament: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)


export const editDartsTournament = createAsyncThunk<ICurrentDartsTours, IEditTournamentPayload>(
    'dartsTournament/edit',
    async (tournament, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_TOURNAMENTS}/${tournament.id}`, tournament);
            toastr.success("CMS", "Darts tournament has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a tournament.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit darts tournament: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteDartsTournament = createAsyncThunk<string | undefined, number>(
    'dartsTournament/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_TOURNAMENTS}/${id}`);
            toastr.success("", "Darts tournament has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a darts tournament.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete darts tournament: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const cmsTournamentsSlice = createSlice({
    name: 'cmsDartsTournaments',
    reducers: {
        setTournaments: (state, action: PayloadAction<ITournamentsInit>) => {
            state.dartsTournaments = action.payload;
        },
        setCurrentTournaments: (state, action: PayloadAction<ICurrentDartsTours[]>) => {
            state.currentDartsTournaments = action.payload;
            state.tournamentsCount = state.currentDartsTournaments.length;
        },
        showTournamentForm: (state) => {
            state.isVisibleForm = true
        },
        hideTournamentForm: (state) => {
            state.isVisibleForm = false;
            state.editMode = false;
        },
        setEditTournamentMode: (state, action: PayloadAction<number | null>) => {
            state.editMode = true;
            state.isVisibleForm = true;
            state.editTournamentId = action.payload;
        },
        setCurrentCmsTournamentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setTournamentsOnCurrentPage: (state, action: PayloadAction<IToursOnCurrentPage[]>) => {
            state.tournamentsOnCurrentPage = action.payload
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsTournamentsInit.fulfilled, (state, action) => {
                state.dartsTournaments = action.payload;
                state.isLoading = false;
            })
            .addCase(getDartsTournamentsInit.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getDartsTournaments.fulfilled, (state, action) => {
                state.currentDartsTournaments = action.payload;
                state.tournamentsCount = action.payload.length;
                state.isLoading = false;
            })
            .addCase(getDartsTournaments.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(deleteDartsTournament.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDartsTournament.fulfilled, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'string') {
                    state.currentDartsTournaments = state.currentDartsTournaments.filter(
                        (tournament) => tournament.id !== action.meta.arg
                    );
                }
            })
            .addCase(deleteDartsTournament.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createDartsTournament.pending, (state) => {
                state.isLoading = true;
                state.isVisibleForm = false;
            })
            .addCase(createDartsTournament.fulfilled, (state, action) => {
                state.currentDartsTournaments.unshift(action.payload);
                state.isLoading = false;
            })
            .addCase(createDartsTournament.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(editDartsTournament.pending, (state) => {
                state.isVisibleForm = false;
                state.isLoading = true;
            })
            .addCase(editDartsTournament.fulfilled, (state, action) => {
                state.currentDartsTournaments = state.currentDartsTournaments.map((tournament) => {
                    if (tournament.id === action.payload.id) {
                        tournament = action.payload
                    }
                    return tournament
                })
                state.editTournamentId = 0;
                state.isLoading = false;
            })
            .addCase(editDartsTournament.rejected, (state) => {
                state.editTournamentId = 0;
                state.isLoading = false;
            });
    },
});

export const {
    setTournaments,
    setCurrentTournaments,
    showTournamentForm,
    hideTournamentForm,
    setEditTournamentMode,
    setTournamentsOnCurrentPage,
    setCurrentCmsTournamentPage

} = cmsTournamentsSlice.actions
export default cmsTournamentsSlice.reducer;

