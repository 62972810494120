import {detectGameType} from "../helpers/detectGameType";
import {useEffect, useState} from "react";
import {selectAdcGame, selectClassicGame, selectTournament} from "../../../store/pages/schedule/scheduleSlice";
import {useAppDispatch} from "../../../hooks/hooks";
import {DartsGameTypes} from "../../../constants/globalTypes";
import {DartsGameItem} from "../../../store/dartsGame/handler/types";
import {GameTypes} from "../../../enums/gameEvents";

export interface IGameType {
    type: DartsGameTypes
}
export const useSelectCurrentGameByTour = (
    selectedTournament: number,
    selectedGame: number,
    activeGameId: number,
    games: DartsGameItem[],
    activeTournamentId: number,
    activeIsADC: boolean,
    activeIs9shots: boolean,

) => {
    const dispatch = useAppDispatch()
    const [dartsGameType, setDartsGameType] = useState<IGameType>({type: GameTypes.ADC});
    const [activeGame, setActiveGame] = useState<DartsGameItem | null | undefined>(null);
    const selectCurrentGame = () => {
        if (!selectedTournament && !activeTournamentId) {
            return
        }
        if (selectedTournament && selectedGame) {
            const game = games.find((game: DartsGameItem) => game.id === selectedGame)
            setActiveGame(game)
        }
        if (activeGameId && detectGameType(activeIsADC, activeIs9shots) === GameTypes.CLASSIC) {
            dispatch(selectClassicGame(activeGameId));
            dispatch(selectTournament(activeTournamentId));
            const game = games.find((game: DartsGameItem) => game.id === activeGameId)
            setActiveGame(game)
            setDartsGameType({...dartsGameType, type: detectGameType(activeIsADC, activeIs9shots)})
        }
        if (activeGameId && detectGameType(activeIsADC, activeIs9shots) === GameTypes.ADC) {
            dispatch(selectAdcGame(activeGameId));
            setDartsGameType({...dartsGameType, type: detectGameType(activeIsADC, activeIs9shots)})
        }
        if (activeGameId && detectGameType(activeIsADC, activeIs9shots) === GameTypes.SHOOTOUT) {
            dispatch(selectClassicGame(activeGameId));
            dispatch(selectTournament(activeTournamentId));
            setDartsGameType({...dartsGameType, type: detectGameType(activeIsADC, activeIs9shots)})
        }
    }

    useEffect(() => {
        selectCurrentGame()
    }, [activeGameId, selectedTournament]);

    return {dartsGameType, setDartsGameType, activeGame}
}
