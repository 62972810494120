import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../services/api.service";
import {API_DARTS_ACTIVE_GAME_SCORE_SHOOTOUT} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {I9ShotsActiveGameData, I9ShotsActiveGameScoreReducer} from "./types";

const initialState: I9ShotsActiveGameScoreReducer = {
    activeGameData: {} as I9ShotsActiveGameData,
}

export const getShootOutActiveGameScoreData = createAsyncThunk<I9ShotsActiveGameData, number, { rejectValue: string }>(
    'shootOutactiveGameScore/get',
    async (tournamentId, {rejectWithValue}) => {
        try {
                const {data} = await client.get(`${API_DARTS_ACTIVE_GAME_SCORE_SHOOTOUT}/${tournamentId}`);
            return data;
        } catch (e) {
            toastr.error('Shoot out active handler score:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }

    }
)


export const shootOutActiveGameScoreSlice = createSlice({
    name: 'activeGameScore',
    reducers: {
        shootOutSetGameData: (state, action: PayloadAction<I9ShotsActiveGameData>) => {
            state.activeGameData = action.payload;
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getShootOutActiveGameScoreData.fulfilled, (state: I9ShotsActiveGameScoreReducer, action) => {
                state.activeGameData = action.payload;
            })
    }})
export const {shootOutSetGameData} = shootOutActiveGameScoreSlice.actions;
export default shootOutActiveGameScoreSlice.reducer;
