import {handleError} from "../../../../../services/api.service";
import {AxiosError} from "axios";
import {getBestOf7Legs} from "../../../../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import {AppThunkDispatch} from "../../../../../store";

interface IGetBestOfSevenLegs {
    id: number;
    dispatch: AppThunkDispatch;
}
export const getBestOfSevenLegs = async ({id, dispatch}: IGetBestOfSevenLegs) => {
        if (id) {
            try {
                dispatch(getBestOf7Legs(id));
            } catch (err) {
                return handleError(err as AxiosError, undefined);
            }
        }
    };
