export enum UserRole {
    Admin = 1,
    DCUser = 2,
    Client = 3,
    ReadonlyUser = 4
}
export enum UserRoles {
    DCUser = 'DCUser',
    Client = 'Client',
    ReadonlyUser = 'ReadonlyUser',
    StatsUser = 'StatsUser',
    CrmUser = 'CrmUser',
    Viewer = 'Viewer',
    Scoring = 'Scoring',
    Admin = 'Admin'
}
export interface IUserRolesState {
    roles: IRole[];
    allUsers: IUser[];
    user: IUser;
    usersLoader: boolean;
    rolesLoader: boolean;
    createMode: boolean;
    editMode: boolean;
    usersCount: number;
    filterValues : FilterValues;
}

export interface IGetUsersResponse {
    users: IUser[];
    totalQuantity: number;
}

export type FilterValues = {
    from: string;
    search: string;
    status: number;
    pNum: number;
    pSize: number;
    sort: boolean;
}
export interface IUser {
    id: number,
    name: string,
    email?: string,
    roles: string[] | null
}

export interface IUserPayload {
    id?: number;
    password?: string;
    roleIDs: number[] | null;
    email: string;
    name: string;
}

export interface IRole {
    id: number;
    name: string;
    description: string;
}

export interface IUserFormData {
    userRole: string;
    userName: string;
    userEmail: string;
    userPassword?: string;
}
