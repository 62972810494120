import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {errorLogger} from "../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {API_DARTS_DATA_INIT, API_DARTS_FILTER_TOURNAMENTS} from "../../../constants";
import {client} from "../../../services/api.service";
import {DartsInitState, IDartsInitData, IFilteredTournaments, IFilteredTournamentsPayload} from "./types";
import {DartsPlayer} from "../../cms/darts/players/types";
import {IDartsGameCountry} from "../../cms/darts/games/types";

const initialState: DartsInitState = {
    initData: {} as IDartsInitData,
    selectedTournament: 0,
    selectedGame: 0,
    selectedAdcGame: 0,
    players: [] as DartsPlayer[],
    countries: [] as IDartsGameCountry[],
    isLoading: false,
    isFilterLoading: false,
    isFilterContent: true,
    statistics: 0,
}

export const getDartsInit = createAsyncThunk<IDartsInitData | undefined, void>(
    'scheduleSlice/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} =  await client.get(API_DARTS_DATA_INIT);
            return data as IDartsInitData;
        } catch (error) {
            console.error(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `getDartsInit: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            toastr.error("Error:", " Failed get schedule data");
            return rejectWithValue(error.response.data);
        }
    }
);

export const getFilteredTournaments = createAsyncThunk<IFilteredTournaments, IFilteredTournamentsPayload>(
    'scheduleSlice/filteredTournament',
    async (filterPayload, {rejectWithValue}) => {
        try {
            const {data} =  await client.post(API_DARTS_FILTER_TOURNAMENTS, filterPayload);
            return data;
        } catch (error) {
            console.error(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `getDartsInit: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
);

export const scheduleSlice = createSlice({
    name: 'scheduleSlice',
    reducers: {
        setDartsInitData: (state, action: PayloadAction<IDartsInitData>) => {
            state.initData = action.payload
        },
        selectClassicGame: (state, action: PayloadAction<number>) => {
            state.selectedGame = action.payload
        },
        selectAdcGame: (state, action: PayloadAction<number>) => {
            state.selectedAdcGame = action.payload
        },
        selectTournament: (state, action: PayloadAction<number>) => {
            state.selectedTournament = action.payload
        },
        deleteActiveGameAndTournament: (state) => {
            state.selectedTournament = 0;
            state.selectedGame = 0;
            state.selectedAdcGame = 0;
        },
        setCsvStatistics: (state, action: PayloadAction<number>) => {
            state.statistics = action.payload;
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsInit.pending, (state: DartsInitState) => {
                state.isLoading = true;
            })
            .addCase(getDartsInit.fulfilled, (state: DartsInitState, action) => {
                state.initData = action.payload as IDartsInitData;
                state.isLoading = false;
            })
            .addCase(getDartsInit.rejected, (state: DartsInitState) => {
                state.isLoading = false;
            })
            .addCase(getFilteredTournaments.pending, (state: DartsInitState) => {
                state.isFilterLoading = true;
            })
            .addCase(getFilteredTournaments.fulfilled, (state: DartsInitState, action) => {
                const {tournaments, games} = action.payload
                state.initData.tournaments = tournaments;
                state.initData.games = games;
                state.isFilterLoading = false;
            })
            .addCase(getFilteredTournaments.rejected, (state: DartsInitState) => {
                state.isFilterLoading = false;
            })
    }})
export const {
    setDartsInitData,
    selectClassicGame,
    selectAdcGame,
    selectTournament,
    deleteActiveGameAndTournament,
    setCsvStatistics
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
