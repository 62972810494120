import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../services/api.service";
import {API_LEAGUE_TABLE} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {errorLogger} from "../../../services/error-logger";
import {ILeagueTable, ILeagueTableItem} from "./types";

const initialState: ILeagueTable  = {
    name: '',
    table: {
        items: [] as ILeagueTableItem[]
    },
    tournamentId: 0
};

export const getLeagueTableData = createAsyncThunk<ILeagueTable>(
    'leagueTable/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_LEAGUE_TABLE);
            return data;
        } catch (error) {
            toastr.error('League table', 'Failed to fetch data.');
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `getLeagueTableData: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const leagueTableSlice = createSlice({
    name: 'preMatch',
    reducers: {
        setLeagueTable: (state, action: PayloadAction<ILeagueTable>) => {

            const {name, table, tournamentId} = action.payload;
            state.name = name;
            state.table = table;
            state.tournamentId = tournamentId
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLeagueTableData.fulfilled, (state, action) => {
                const {name, table, tournamentId} = action.payload;
                state.name = name;
                state.table = table;
                state.tournamentId = tournamentId;

            })
    }})

export const {
    setLeagueTable,
} = leagueTableSlice.actions;
export default leagueTableSlice.reducer;
