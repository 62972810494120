import {ILiveScoreItemTimeLine} from "../../../../../store/pages/liveScoreInfo/types";
import {GameEvent} from "../../../../../enums/gameEvents";
import {Icon} from "semantic-ui-react";
import React from "react";

export const renderIconForHomePlayer = (log: ILiveScoreItemTimeLine) => {
    if (log.homeAway) {
        switch (log.eventType) {
            case GameEvent.PlayerChanged: {
                return <Icon name="user"/>;
            }
            case GameEvent.LegWin: {
                return <Icon name="certificate"/>;
            }
            case GameEvent.WinSet: {
                return <Icon name="check square outline"/>;
            }
            case GameEvent.TurnFinished: {
                return <Icon name="location arrow"/>;
            }

            default:
                break;
        }
    }
};
export const renderIconForAwayPlayer = (log: ILiveScoreItemTimeLine) => {
    if (!log.homeAway) {
        switch (log.eventType) {
            case GameEvent.PlayerChanged: {
                return <Icon name="user"/>;
            }
            case GameEvent.LegWin: {
                return <Icon name="certificate"/>;
            }
            case GameEvent.WinSet: {
                return <Icon name="check square outline"/>;
            }
            case GameEvent.TurnFinished: {
                return <Icon name="location arrow"/>;
            }

            default:
                break;
        }
    }
};
