import React, {Fragment, useEffect, useState} from "react";
import {Button, Ref, Table} from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {IDartsCurrentGameTimeline} from "../../../../store/dartsGame/currentGame/types";
import {doubleAttempt} from "../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {GameTypes} from "../../../../enums/gameEvents";
import {useHistory} from "react-router-dom";


const LogsTable: React.FC = React.memo(() => {
    const dispatch = useAppDispatch();
    const {gameId} = useAppSelector((state) => state.currentDartsGame.currentGameInitData)
    const dartsCurrentScore = useAppSelector((state) => state.currentDartsGame.currentGameInitData)
    const {timeline} = dartsCurrentScore;
    const [infoLogs, setInfoLogs] = useState<null | IDartsCurrentGameTimeline[] | undefined>(null);
    const refs = timeline?.reduce((acc: any, value, i) => {
        acc[i] = React.createRef();
        return acc;
    }, {});

    const history = useHistory();
    const pathName = history.location.pathname;
    const isDartsAdc = pathName.includes("darts-adc");
    const scrollToElement = (index: number) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    const reversTimeLine = (logs: any) => {
        const reverseLogs = logs.concat();
        return setInfoLogs(reverseLogs.reverse());
    };
    useEffect(() => {
        if (timeline?.length) {
            scrollToElement(0);
            reversTimeLine(timeline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline]);

    const doubleAttemptRequest = (gameId: number, throwId: number) => {
        const doubleAttemptBody = {
            gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC,
            gameData: {
                date: moment().utc().toDate(),
                gameId,
                throwId,
            }
        };
        dispatch(doubleAttempt(doubleAttemptBody));
    };

    const genDoubleAttemptionBtn = (doubleAttemption: number | null, throwId: number,) => {
        if (doubleAttemption) {
            return (
                <Button
                    onClick={() => doubleAttemptRequest(gameId, throwId)}
                    color="green"
                    active

                >
                    {`Double Attempt ${doubleAttemption}`}
                </Button>
            );
        }
        if (doubleAttemption === 0) {
            return (
                <Button
                    onClick={() => doubleAttemptRequest(gameId, throwId)}
                    color="grey"
                    active
                    content="Double Attempt"
                />
            );
        } else {
            return null;
        }
    };

    const generateBack = (lineDetail: IDartsCurrentGameTimeline) => {
        if (lineDetail.homeAway && lineDetail.isThrow) {
            return "lightblue";
        }
        if (!lineDetail.homeAway && lineDetail.isThrow) {
            return "#f4b084";
        } else {
            return "black";
        }
    };

    return (
        <Table className="time-line-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Leg</Table.HeaderCell>
                    <Table.HeaderCell>Player</Table.HeaderCell>
                    <Table.HeaderCell>DartScore</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    <Fragment>
                        {infoLogs?.map((line, i) => {
                            return (
                                <Ref innerRef={refs[i]} key={i}>
                                    <Table.Row
                                        key={i}
                                        style={{
                                            background: generateBack(infoLogs[i]),
                                            color: line.isThrow ? "black" : "white",
                                        }}
                                    >
                                        <Table.Cell>{line.leg}</Table.Cell>
                                        <Table.Cell>{removeNameSeparator(line.player)}</Table.Cell>
                                        <Table.Cell>
                                            {line.score === null ? "" : line.score}
                                        </Table.Cell>
                                        <Table.Cell>{line.description}</Table.Cell>
                                        <Table.Cell>
                                            {genDoubleAttemptionBtn(line.doubleAttempt, line.id)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Ref>
                            );
                        })}
                    </Fragment>
                }
            </Table.Body>
        </Table>
    );
})



export default LogsTable;
