import React from "react";
import {selectClassicGame} from "../../../store/pages/schedule/scheduleSlice";
import {AppDispatch} from "../../../store";

export const isGamesExistForSelectedTour = (tourId: number,scheduleSelectedGame: number, dispatch: AppDispatch) => {
    if (tourId !== 0 && !scheduleSelectedGame) {
        dispatch(selectClassicGame(0));
        return (
            <div style={{textAlign: "center"}}>No games for this tournament</div>
    );
    } else {
        return null;
    }
}
