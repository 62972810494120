import {IGamesFilterValues} from "../../../../../store/cms/darts/games/types";

export const buildQueryString = (filter: any): string => {
    const queryParams: string[] = [];
    for (const key in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, key)
            && filter[key as keyof IGamesFilterValues] !== null
            && filter[key as keyof IGamesFilterValues] !== 0
            && filter[key as keyof IGamesFilterValues] !== ""
        ) {
            queryParams.push(`${key}=${filter[key as keyof IGamesFilterValues]}`)
        }
    }

    return queryParams.join('&');
}