import {DartsPlayer} from "../../../../../store/cms/darts/players/types";
import _ from "lodash";

interface iUseSortPlayers {
    playersOnCurrentPage: DartsPlayer[];
    sortedField: string;
    sortDirection: string;
}
export const useSortPlayers = ({
                                   playersOnCurrentPage,
                                   sortedField,
                                   sortDirection}: iUseSortPlayers) => {
    const direction = sortDirection === "asc" ? "desc" : "asc";
    const sortedPlayers: DartsPlayer[] = _.orderBy(playersOnCurrentPage, [sortedField], [direction]);
    return {direction, sortedPlayers};
};
