import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DartsPlayerPayload,
    DartsPlayer,
    DartsPlayersTable, IUploadPhotoPayload, DeletePlayerPayload,
} from "./types";
import {toastr} from "react-redux-toastr";
import {API_PLAYERS, API_PLAYERS_PHOTO} from "../../../../constants";
import {errorLogger} from "../../../../services/error-logger";
import {client} from "../../../../services/api.service";
import {upload} from "../../../../services/upload.api";

const initialState: DartsPlayersTable = {
    dartsPlayers: [] as DartsPlayer[],
    loadingPlayers: false,
    editMode: false,
    editPlayerId: 0,
    isVisibleForm: false,
    playerCount: 0,
    playersOnCurrentPage: [] as DartsPlayer[],
    currentPage: 1,
    noContent: false,
    filteredPlayers: [] as DartsPlayer[],
    filteredPlayersCount: 0,
    filteredPlayersCurrentPage: 0,
    filteredPlayersOnCurrentPage: [] as DartsPlayer[],
    isEditPhotoMode: false,
    isShowModal: false,
    photoLoader:false
}

export const getDartsPlayers = createAsyncThunk<DartsPlayer[]>(
    'dartsPlayers/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_PLAYERS);
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to fetch players.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Players: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createDartsPlayer = createAsyncThunk<DartsPlayer, DartsPlayerPayload>(
    'dartsPlayers/post',
    async (player, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_PLAYERS, player);
            toastr.success("CMS", "Darts player has been created");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to create a player.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create darts player: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.reponse.data);
        }
    }
)

export const editDartsPlayer = createAsyncThunk<DartsPlayer, DartsPlayerPayload>(
    'dartsPlayers/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_PLAYERS}/${payload.id}`, payload);
            toastr.success("CMS", "Player has been edited");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to edit a player.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit darts player: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteDartsPlayer = createAsyncThunk<DartsPlayer, number>(
    'dartsPlayers/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_PLAYERS}/${id}`);
            toastr.success("CMS", "Player has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a player.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete Darts Player: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const uploadPlayersPhoto = createAsyncThunk<DartsPlayer, IUploadPhotoPayload>(
    'dartsPlayersPhoto/upload',
    async (payload, {dispatch, rejectWithValue}) => {
        try {
            const {data} = await upload.post(`${API_PLAYERS_PHOTO}/${payload.id}`, payload.picture);
            toastr.success("CMS", "Player's photo has been uploaded");
            dispatch(getDartsPlayers());
            return data;
        } catch (error) {
            toastr.error('CMS', "Failed to upload a player's photo.")
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Upload Players Photo: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const updatePlayersPhoto = createAsyncThunk<DartsPlayer, IUploadPhotoPayload>(
    'dartsPlayersPhoto/update',
    async (payload, {dispatch, rejectWithValue}) => {
        try {
            const {data} = await upload.put(`${API_PLAYERS_PHOTO}/${payload.id}`, payload.picture);
            toastr.success("CMS", "Player's photo has been updated");
            dispatch(getDartsPlayers());
            return data;
        } catch (error) {
            toastr.error('CMS', "Failed to update a player's photo.")
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Update Players Photo: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);


        }
    }
)

export const deletePlayersPhoto = createAsyncThunk<DartsPlayer, DeletePlayerPayload>(
    'dartsPlayersPhoto/delete',
    async ({playerId,setIsOpenForm}, {dispatch, rejectWithValue}) => {
        try {
            const {data} = await upload.delete(`${API_PLAYERS_PHOTO}/${playerId}`);
            toastr.success("CMS", "Player's photo has been deleted");
            setIsOpenForm(false)
            dispatch(getDartsPlayers());
            return data;
        } catch (error) {
            toastr.error('CMS', "Failed to delete a player's photo.")
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete Players Photo: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.message);
        }
    }
)


export const cmsPlayersSlice = createSlice({
    name: 'cmsPlayersSlice',
    reducers: {
        setCurrentCmsPlayersPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setPlayersOnCurrentPage: (state, action: PayloadAction<DartsPlayer[]>) => {
            state.playersOnCurrentPage = action.payload
        },
        setFilteredPlayersOnCurrentPage: (state, action: PayloadAction<DartsPlayer[]>) => {
            state.filteredPlayersOnCurrentPage = action.payload
        },
        setCmsFilteredPlayers: (state, action: PayloadAction<DartsPlayer[]>) => {
            state.filteredPlayers = action.payload
        },
        setPlayersIsShowModal: (state, action: PayloadAction<boolean>) => {
            state.isShowModal = action.payload
        },
        setPlayersEditPhotoMode: (state, action: PayloadAction<boolean>) => {
            state.isEditPhotoMode = action.payload
        },
        playersShowMode: (state) => {
            state.isVisibleForm = true;
            state.editMode = false;
        },
        playersEditMode: (state, action: PayloadAction<number>) => {
            state.editMode = true;
            state.editPlayerId = action.payload;
            state.isVisibleForm = true;
        },
        playersEditPhotoMode: (state, action: PayloadAction<boolean>) => {
            state.isEditPhotoMode = action.payload;
        },
        playersHideForm: (state) => {
            state.isVisibleForm = false;
        },
        playerSetModal: (state, action: PayloadAction<boolean>) => {
            state.isShowModal = action.payload;
        },
        setPlayerPhotoLoader: (state, action: PayloadAction<boolean>) => {
            state.photoLoader = action.payload;
        },

    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsPlayers.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(getDartsPlayers.fulfilled, (state: DartsPlayersTable, action) => {
                state.dartsPlayers = action.payload;
                state.playerCount = action.payload.length;
                state.noContent = false;
                state.loadingPlayers = false;
            })
            .addCase(getDartsPlayers.rejected, (state: DartsPlayersTable) => {
                state.noContent = true;
                state.loadingPlayers = false;
            })
            .addCase(deleteDartsPlayer.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(deleteDartsPlayer.fulfilled, (state: DartsPlayersTable, action) => {
                state.dartsPlayers = state.dartsPlayers.filter((player) => player.id !== action.meta.arg);
                state.filteredPlayers = state.filteredPlayers.filter((player) => player.id !== action.meta.arg);
                state.loadingPlayers = false;
            })
            .addCase(deleteDartsPlayer.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(createDartsPlayer.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(createDartsPlayer.fulfilled, (state: DartsPlayersTable, action) => {
                state.dartsPlayers = state.dartsPlayers.concat(action.payload);
                state.isVisibleForm = false;
                state.loadingPlayers = false;
            })
            .addCase(createDartsPlayer.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(editDartsPlayer.pending, (state: DartsPlayersTable) => {
                state.isVisibleForm = false;
                state.loadingPlayers = true;

            })
            .addCase(editDartsPlayer.fulfilled, (state: DartsPlayersTable, action) => {
                state.editPlayerId = action.meta.arg.id!;
                state.editMode = true;
                state.dartsPlayers = state.dartsPlayers.map(player => player.id === action.payload.id ? action.payload : player)
                state.filteredPlayers = state.filteredPlayers.map(player => player.id === action.payload.id ? action.payload : player)
                state.loadingPlayers = false;
            })
            .addCase(editDartsPlayer.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(updatePlayersPhoto.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(updatePlayersPhoto.fulfilled, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(updatePlayersPhoto.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(uploadPlayersPhoto.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
            })
            .addCase(uploadPlayersPhoto.fulfilled, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(uploadPlayersPhoto.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
            })
            .addCase(deletePlayersPhoto.pending, (state: DartsPlayersTable) => {
                state.loadingPlayers = true;
                state.isShowModal = false;
                state.photoLoader = true
            })
            .addCase(deletePlayersPhoto.fulfilled, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
                state.photoLoader = false
                state.isShowModal = false
            })
            .addCase(deletePlayersPhoto.rejected, (state: DartsPlayersTable) => {
                state.loadingPlayers = false;
                state.isShowModal = false
                state.photoLoader = false
            })
    },
});

export const {
    setCurrentCmsPlayersPage,
    setPlayersOnCurrentPage,
    setFilteredPlayersOnCurrentPage,
    setCmsFilteredPlayers,
    setPlayersIsShowModal,
    setPlayersEditPhotoMode,
    playersShowMode,
    playersEditMode,
    playersEditPhotoMode,
    playersHideForm,
    playerSetModal,
    setPlayerPhotoLoader
} = cmsPlayersSlice.actions
export default cmsPlayersSlice.reducer;

