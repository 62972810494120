import moment from "moment";
import React, {useEffect} from "react";
import {Container, Table, Button} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DartsPhaseForm from "./DartsPhaseForm/DartsPhaseForm";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    deleteDartsPhase,
    getDartsPhases,
    setEditModePhaseForm, showPhaseForm
} from "../../../../store/cms/darts/phases/cmsPhasesSlice";
import {IWeek} from "../../../../store/cms/darts/phases/types";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsPhase = () => {
    const dispatch = useAppDispatch();
    const {isVisibleForm, dartsPhase, noContent} = useAppSelector((state) => state.cmsPhases);
    const {user} = useAppSelector((state) => state.auth);
    const getDateRange = (week: IWeek) => {
        return `${moment(week.from).format("LL")} - ${moment(week.to).format("LL")}`;
    };

    useEffect(() => {
        dispatch(getDartsPhases());
    }, [dispatch]);

    return (
        <Container>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button onClick={() => dispatch(showPhaseForm())} id="add_phase" color="green">
                    Add Phase
                </Button>
            </div>
            {noContent === true ? (
                <div className="schedule-info-block">No phases</div>
            ) : (
                <Table basic celled structured>
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">Id</Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Week</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Dates</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!!dartsPhase?.phases?.length &&
                            dartsPhase?.phases?.map((phase) => {
                                const rowSpanValue = phase?.weeks?.length + 1;
                                return (
                                    <>
                                        <Table.Row key={phase?.id}>
                                            {checkIsUserAdmin(user) &&
                                                <Table.Cell textAlign="center" rowSpan={`${rowSpanValue}`}>
                                                    {phase?.id}
                                                </Table.Cell>
                                            }
                                            <Table.Cell textAlign="center" rowSpan={`${rowSpanValue}`}>
                                                {phase?.name}
                                            </Table.Cell>
                                        </Table.Row>
                                        {phase?.weeks?.map((week: IWeek, index: number) => {
                                            if (index === 0) {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell textAlign="center">
                                                            {week?.number}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {week?.year}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {getDateRange(week)}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            textAlign="center"
                                                            rowSpan={`${rowSpanValue}`}
                                                        >
                                                            <DartsDeleteModal
                                                                id={phase.id!}
                                                                deleteAction={(id: number) => deleteDartsPhase(id)}
                                                                confirmText={
                                                                    "Are you sure you want to delete your phase?"
                                                                }
                                                                titleModal={"Delete your Phase"}
                                                            />
                                                            <Button
                                                                size="mini"
                                                                onClick={() =>
                                                                    dispatch(setEditModePhaseForm(phase.id!))
                                                                }
                                                                color="yellow"
                                                            >
                                                                update
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            } else {
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell textAlign="center">
                                                            {week.number}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {week.year}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            {getDateRange(week)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            }
                                        })}
                                    </>
                                );
                            })}
                    </Table.Body>
                </Table>
            )}
            {isVisibleForm && (
                <DartsPhaseForm
                    dartsPhase={dartsPhase.phases}
                    getDateRange={getDateRange}
                />
            )}
        </Container>
    );
};

export default DartsPhase;
