import React from 'react';
import {Button} from "semantic-ui-react";
import {GameTypes} from "../../../../enums/gameEvents";
import {DartsGameItem} from "../../../../store/dartsGame/handler/types";
import {useHistory} from "react-router-dom";
import {DartsADCGame, DartsGame} from "../../../../store/pages/schedule/types";
import {selectAdcGame, selectClassicGame} from "../../../../store/pages/schedule/scheduleSlice";
import {setDartsGame} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {useAppDispatch} from "../../../../hooks/hooks";
import {IGameType} from "../../hooks/useSelectCurrentGame";
import {setCurrentGameId} from "../../../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";

interface IScheduleActiveButtonsProps {
    isScoringUser: boolean | undefined
    isViewerUser: boolean | undefined
    selectedAdcGame: number
    selectedGame: number
    games: DartsGame[]
    activeAdcGame:DartsADCGame | null | undefined
    isAdcGameSelected:boolean
    activeIs9shots: boolean
    activeGameId:number,
    setIsAdcGameSelected: React.Dispatch<React.SetStateAction<boolean>>
    setDartsGameType:  React.Dispatch<React.SetStateAction<IGameType>>
    activeIsADC:boolean
    activeTournamentId:number
    selectedTournament:number
}

const ScheduleActiveButtons: React.FC<IScheduleActiveButtonsProps> = (

    {
        isScoringUser,
        isViewerUser,
        selectedGame,
        selectedAdcGame,
        games,
        activeAdcGame,
        isAdcGameSelected,
        activeIs9shots,
        activeGameId,
        setIsAdcGameSelected,
        setDartsGameType,
        activeIsADC,
        activeTournamentId,
        selectedTournament
    }) => {

    const history = useHistory();
    const dispatch = useAppDispatch()

    const gotToLiveScoreDarts = () => {
        if (activeAdcGame || isAdcGameSelected || selectedAdcGame) {
            return history.push(`darts/livescore/${GameTypes.ADC.toLowerCase()}/${selectedAdcGame}`);
        }
        if (selectedGame && !isAdcGameSelected) {
            return history.push(`darts/livescore/${GameTypes.CLASSIC.toLowerCase()}/${selectedGame}`);
        }
        const chosenGame = games.find((game: DartsGameItem) => game.id === selectedGame)
        if (chosenGame!.is9shots) {
            return alert("This widget for regular games only ")
        }
        history.push(`darts/livescore/${selectedGame}`);
    };

    const checkIs9ShotGameSelected = (gameId:number) => {
        const currentGame = games.find(game => game.id === gameId)
        if (currentGame) {
            return currentGame.is9shots
        }
    }

    const login = () => {
        if (activeGameId && !activeIsADC) {
            if (isAdcGameSelected && activeGameId) {
                dispatch(selectAdcGame(Number(0)));
                dispatch(selectClassicGame(Number(activeGameId)));
                setIsAdcGameSelected(false)
                setDartsGameType({type: GameTypes.CLASSIC})
                alert("Standard handler is running now");
                return;
            }
            // @ts-ignore
            if ((activeGameId !== selectedGame)) {
                alert("This game is not active");
                return;
            }
        } else if (activeIsADC) {
            // @ts-ignore
            if (activeGameId !== selectedAdcGame) {
                alert("This game is not active");
                return;
            }
        }

        if (selectedAdcGame) {
            return dispatch(setDartsGame({game: activeAdcGame!, history, selectedAdcGame}));
        }

        if (selectedGame || activeGameId) {
            const game = games?.find((game: DartsGameItem) => game.id === selectedGame);
            if (game) {
                return dispatch(setDartsGame({game, history}));
            }
        }
    };

    const goToMatchStats = () => {

        if (activeAdcGame || isAdcGameSelected || selectedAdcGame) {
            dispatch(setCurrentGameId(selectedAdcGame));
            return history.push(`match-stats-table/${GameTypes.ADC.toLowerCase()}/${selectedAdcGame}`);
        }
        if (selectedGame && !isAdcGameSelected && !activeIs9shots) {
            dispatch(setCurrentGameId(selectedGame));
            return history.push(`match-stats-table/${GameTypes.CLASSIC.toLowerCase()}/${selectedGame}`);
        }
        if (activeIs9shots) {
            const currentActiveGame = games.find((game: DartsGameItem) => game.id === selectedGame)
            if (currentActiveGame!.is9shots) {
                history.push(`shootout-match-stats-table/${activeTournamentId}`);
            }
        }

    };

    const goToActiveGameScore = () => {
        if (activeIs9shots) {
            return history.push(`/shootout-active-game-scores/${selectedTournament}`);
        }
        if (selectedAdcGame || isAdcGameSelected) {
            return history.push(`/active-game-scores/${GameTypes.ADC.toLowerCase()}/${selectedTournament}`);
        }
        return history.push(`/active-game-scores/${GameTypes.CLASSIC.toLowerCase()}/${selectedTournament}`);
    };

    return (
        <div className="schedule__login">
            {
                isScoringUser &&
                <Button
                    id="login"
                    color="red"
                    onClick={login}>
                    Login
                </Button>
            }

            {
                isViewerUser &&
                <>
                    <Button
                        disabled={activeIs9shots || checkIs9ShotGameSelected(selectedGame)}
                        id="live_score"
                        color="blue"
                        onClick={gotToLiveScoreDarts}
                    >
                        Live score
                    </Button>
                    <Button
                        id="scoreboard"
                        color="yellow"
                        onClick={goToMatchStats}
                    >
                        Scoreboard & Stats
                    </Button>
                    <Button
                        id="active_score"
                        color="green"
                        disabled={!activeGameId}
                        onClick={goToActiveGameScore}
                    >
                        Active Game Score
                    </Button>
                </>
            }

        </div>
    );
};

export default ScheduleActiveButtons;
