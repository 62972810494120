export const formatAverageScore = (average: number): string => {
    const stringAverage = average.toString().split('.')[1]
    if (!stringAverage) {
        return average + ".00"
    }
    if (stringAverage.length === 1) {
        return average + "0"
    }
    return average.toString()
}
