import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Button, DropdownItemProps, Form, Icon, Label, Message, Select} from "semantic-ui-react";
import {yupResolver} from "@hookform/resolvers/yup";
import {IWeek} from "../../../../../store/cms/darts/phases/types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    createDartsTournament, editDartsTournament,
    hideTournamentForm
} from "../../../../../store/cms/darts/tournaments/cmsTournamentsSlice";
import {
    ICreateTournamentPayload,
    ICurrentDartsTours,
    IEditTournamentPayload
} from "../../../../../store/cms/darts/tournaments/types";
import {checkIsExistTournament} from "./helpers/checkIsExistTournament";
import useTournamentsOptions from "./helpers/useTournamentsOptions";
import * as yup from "yup";
import './style.scss';
import {toastr} from "react-redux-toastr";

interface ITournamentsFormProps {
    allWeek: IWeek[],
    tournamentsIds: ICurrentDartsTours[],
}

const DartsTournamentsTableForm: React.FC<ITournamentsFormProps> = ({
    allWeek,
    tournamentsIds,
    }) => {
    const dispatch = useAppDispatch();
    const {
        competitions,
        phases,
        groups
    } = useAppSelector((state) => state.cmsTournaments.dartsTournaments);
    const {editMode, editTournamentId,currentDartsTournaments} = useAppSelector((state) => state.cmsTournaments);
    const [selectedPhaseId, setSelectedPhaseId] = useState<any>(null);
    const [weeksOptions, setWeeksOptions] = useState<DropdownItemProps[] | null>([]);

    const {
        competitionsOptions,
        groupsOptions,
        phasesOptions
    } = useTournamentsOptions({competitions, groups, phases})


    useEffect(() => {
        if (editMode && editTournamentId) {
            const allWeekOptions = allWeek.map((week) => ({
                text: `${week.sequential}`,
                key: week.id,
                value: week.id,
            }));

            const editTour = currentDartsTournaments.find(
                (tour) => tour.id === editTournamentId
            );

            const defaultCompetitionsOptions = competitionsOptions.find(
                (competition) => competition.value === editTour!.competitionId
            );
            const defaultWeeksOptions = allWeekOptions?.find(
                (option) => option.value === editTour!.weekId
            );

            const defaultPhasesOptions = phasesOptions?.find(
                (option) => option.value === editTour!.phaseId
            );
            const defaultGroupOptions = groupsOptions.find(
                (option) => option.value === editTour!.groupId
            );
            setValue("competitionName", defaultCompetitionsOptions!.value);
            setValue("week", defaultWeeksOptions!.value);
            setValue("group", defaultGroupOptions!.value);
            setValue("phaseName", defaultPhasesOptions!.value);
            setSelectedPhaseId(defaultPhasesOptions!.value);
        }
    }, [editMode, editTournamentId]);

    let validationSchema = yup.object().shape({
        competitionName: yup.number().required(),
        week: yup.number().required(),
        group: yup.number().required(),
        phaseName: yup.number(),
        whenWeekInputDisabled: yup
            .number()
            .test("match", "Select a phase before using the week list", function () {
                return selectedPhaseId !== null;
            }),
    });

    const {control, errors, handleSubmit, setValue, getValues} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (selectedPhaseId) {
            const selectedPhase = phases?.find((phase) => phase.id === selectedPhaseId);
            const newWeeksOptions = selectedPhase!.weeks?.map((week) => ({
                key: week?.id,
                value: week?.id,
                text: week?.sequential,
            }));
            const sortedWeeks = newWeeksOptions?.sort((a, b) => a.text - b.text)
            setWeeksOptions(sortedWeeks);
        }
    }, [selectedPhaseId]);

    const submitNewTournament = () => {
        const newTournament: ICreateTournamentPayload = {
            competitionId: getValues("competitionName"),
            weekId: getValues("week"),
            groupId: getValues("group"),
        }
        const isTourExisting =  checkIsExistTournament({
            tournamentsIds,
            editMode,
            editTournamentId,
            selectedWeek: newTournament.weekId,
            selectedGroup: newTournament.groupId,
            selectedCompetition: newTournament.competitionId
        });
        if (isTourExisting) {
            return toastr.error("","Tournament is already exists")
        }
        dispatch(createDartsTournament(newTournament));
    };

    const updateTournament = () => {
        const updatedTournament: IEditTournamentPayload = {
            id: editTournamentId,
            competitionId: getValues("competitionName"),
            weekId: getValues("week"),
            groupId: getValues("group"),
        };
        const isTourExisting =  checkIsExistTournament({
            tournamentsIds,
            editMode,
            editTournamentId,
            selectedWeek: updatedTournament.weekId,
            selectedGroup: updatedTournament.groupId,
            selectedCompetition: updatedTournament.competitionId
        });
        if (isTourExisting) {
            return toastr.error("","Tournament is already exists")
        }
        dispatch(editDartsTournament(updatedTournament));
    };

    return (
        <div className="form-overlay">
            <Form
                className={"tours-form"}
                onSubmit={
                    editMode
                        ? handleSubmit(updateTournament)
                        : handleSubmit(submitNewTournament)
                }
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={() => dispatch(hideTournamentForm())}
                    style={{float: "right", cursor: "pointer"}}
                />

                <Form.Field>
                    <Label>Competition:</Label>
                    <Controller
                        name="competitionName"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("competitionName")}
                                id="competitionName"
                                options={competitionsOptions}
                                placeholder="Select competition"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.competitionName && (
                        <Message negative>{errors.competitionName.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Phase:</Label>
                    <Controller
                        name="phaseName"
                        control={control}
                        render={() => (
                            <Select
                                value={selectedPhaseId}
                                id="phaseName"
                                options={phasesOptions}
                                placeholder="Select phase"
                                onChange={(e, {value}) => setSelectedPhaseId(value)}
                            />
                        )}
                    />
                    {errors.phaseName && (
                        <Message negative>{errors.phaseName.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Week:</Label>
                    <Controller
                        name="week"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("week")}
                                id="week"
                                options={weeksOptions ? weeksOptions : []}
                                placeholder="Select week"
                                disabled={!selectedPhaseId}
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.week && <Message negative>{errors.week.message}</Message>}
                    {errors.whenWeekInputDisabled && (
                        <Message negative>{errors.whenWeekInputDisabled.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Group:</Label>
                    <Controller
                        name="group"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("group")}
                                id="group"
                                options={groupsOptions}
                                placeholder="Select group"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.group && <Message negative>{errors.group.message}</Message>}
                </Form.Field>
                <Button color="green" id="save_tournament" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    );
};

export default DartsTournamentsTableForm;
