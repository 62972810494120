import React, {useEffect} from "react";
import {Container, Table, Button} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import TablePagination from "../../../../components/Pagination/Pagination";
import DartsCompetitionsForm from "./DartsCompetitionsForm/DartsCompetitionsForm";
import CheckboxCms from "../../../../components/CheckboxCms/CheckboxCms";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    clearSingleCompetition,
    deleteDartsCompetition,
    getDartsCompetitions, getDartsSingleCompetition,
    setCompetitionOnCurrentPage,
    setCurrentCmsCompetitionPage,
    setEditModeCompetitionForm,
    showCompetitionForm
} from "../../../../store/cms/darts/competitions/competitionsSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsCompetitions = () => {
    const dispatch = useAppDispatch();
    const {
        dartsCompetitions,
        isVisibleForm,
        competitionsCount,
        currentPage,
        competitionsOnCurrentPage,
        isLoading,
        noContent,
    } = useAppSelector((state) => state.cmsCompetitions);
    const {user} = useAppSelector((state) => state.auth);
    useEffect(() => {
        dispatch(getDartsCompetitions());
    }, []);

    const openCompetitionForm = () => {
        dispatch(showCompetitionForm());
    }

    const updateCompetitionBtnHandler = (id: number) => {
        dispatch(getDartsSingleCompetition(id));
        dispatch(setEditModeCompetitionForm(id));
        dispatch(clearSingleCompetition());
    }

    return (
        <Container>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button onClick={openCompetitionForm} id="add_competition" color="green">
                    Add competition
                </Button>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No competitions</div>
            ) : (
                <Table celled>
                    {isLoading && <DimmerLoader/>}
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user)&&
                                <Table.HeaderCell textAlign="center">
                                    Id
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">
                                Competition name
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                ADC Qualifier
                            </Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {competitionsOnCurrentPage?.map((competition) => {
                            return (
                                <Table.Row key={competition.id}>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">
                                            {competition.id}
                                        </Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">
                                        {competition.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <CheckboxCms value={competition.isADC} isDisabled={true} isReadyOnly={true}/>
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        <DartsDeleteModal
                                            id={competition.id!}
                                            deleteAction={(id: number) => deleteDartsCompetition(id)}
                                            confirmText={"Are you sure you want to delete your competition?"}
                                            titleModal={"Delete your Competition"}
                                        />
                                        <Button
                                            color="yellow"
                                            size="mini"
                                            onClick={() => updateCompetitionBtnHandler(competition?.id!)}
                                        >
                                            update
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {isVisibleForm && <DartsCompetitionsForm/>}
            {!noContent && (
                <TablePagination
                    setCurrentPage={setCurrentCmsCompetitionPage}
                    setItemsInCurrentPage={setCompetitionOnCurrentPage}
                    itemsCount={competitionsCount}
                    items={dartsCompetitions}
                    currentPage={currentPage}
                    limitItemsInPage={10}
                />
            )}
        </Container>
    );
};

export default DartsCompetitions;
