import {ILiveScoreItemTimeLine} from "../../../../../store/pages/liveScoreInfo/types";

export const getLogContentHomePlayer = (log: ILiveScoreItemTimeLine) => {
    if (log.homeAway) {
        return log.description;
    }
};

export const getLogContentAwayPlayer = (log: ILiveScoreItemTimeLine) => {
    if (!log.homeAway) {
        return log.description;
    }
};
