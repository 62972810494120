import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ILiveScore,
    ILiveScoreAllInfo,
    ILiveScoreInitPayload,
    ILiveScoreLocations,
    ILiveScoreReducer,
    ILiveScoreSidebar,
    OnEndGameLiveScore,
    OnShotForLiveScore,
    ShotUpdateSocketData
} from "./types";
import {API_LIVE_SCORE_ADC_INIT, API_LIVE_SCORE_INIT, API_LIVE_SCORE_SIDEBAR} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {formatSideBar} from "./helpers/formatSideBar";
import {
    disconnectFromGameSocket,
    joinDartsOnSpecifiedDay,
    joinGameAndGetPermission
} from "../../../services/connection.service";
import {GameTypes} from "../../../enums/gameEvents";


const initialState: ILiveScoreReducer = {
    allInformation: {
        liveScore: {} as ILiveScore,
        sidebar: {} as ILiveScoreSidebar,
        sidebarEvents: {},
        gameSrartDate: ''
    },
    lastShot: {} as OnShotForLiveScore,
    liveScoreLoader: false,
    gameInfo: {},
    activeSideBarEvent: 0
}

export const getLiveScoreInfo = createAsyncThunk<ILiveScoreAllInfo, ILiveScoreInitPayload, { rejectValue: string }>(
    'liveScoreInfo/schedule',
    async (payload, {rejectWithValue}) => {
        const {gameId, gameType} = payload;
        try {
            await joinGameAndGetPermission(payload.gameId)
            if (gameType?.toUpperCase() === GameTypes.ADC) {
                const {data} = await client.get(`${API_LIVE_SCORE_ADC_INIT}/${gameId}`);
                return data;
            }
            if (gameType?.toUpperCase() === GameTypes.CLASSIC) {
                const {data} = await client.get(`${API_LIVE_SCORE_INIT}/${gameId}`);
                return data;
            }

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }

    }
)

export const changeGameLiveScore = createAsyncThunk<ILiveScoreAllInfo, ILiveScoreInitPayload, { rejectValue: string }>(
    'liveScoreInfo/changeGame',
    async (payload, {rejectWithValue} ) => {
        const {gameId, gameIdInSocket} = payload;
        try {
            if (gameIdInSocket !== gameId) {
                await disconnectFromGameSocket(gameIdInSocket);
            }
            await joinGameAndGetPermission(gameId);
            if (payload.gameType === GameTypes.ADC) {
                const {data} = await client.get(`${API_LIVE_SCORE_ADC_INIT}/${payload.gameId}`);
                return data;
            }
            const {data} = await client.get(`${API_LIVE_SCORE_INIT}/${payload.gameId}`);
            return data;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message);
            return rejectWithValue(e.response.data);
        }

    }
)

export const updateSidebar = createAsyncThunk<ILiveScoreLocations[], string, { rejectValue: string}>(
    'liveScoreInfo/updateSideBar',
    async (date, {rejectWithValue}) => {
        try {
            await joinDartsOnSpecifiedDay(date);
            const { data } = await client.get(`${API_LIVE_SCORE_SIDEBAR}/${date}`);
            return data.locations;

        } catch (e) {
            toastr.error('Live score info:', `${e.response.data}`);
            console.log(e?.message);
            return rejectWithValue(e.response.data);
        }
    }
)
export const liveScoreInfoSlice = createSlice({
    name: 'liveScoreInfo',
    reducers: {
        setLastShot: (state, action: PayloadAction<ShotUpdateSocketData>) => {
            const {bed, ring, message, turnShots, turnShotsHomeAway} = action.payload;
            state.lastShot = {bed, ring, message};
            state.allInformation.liveScore.turnShotsHomeAway = turnShotsHomeAway;
            state.allInformation.liveScore.turnShots = turnShots;

        },
        updateDataWithSocket: (state, action: PayloadAction<ILiveScore>) => {
            const { playerIDs, legs, timeline, statistics, scores, status } = action.payload;
            state.allInformation.liveScore.playerIDs = playerIDs;
            state.allInformation.liveScore.legs = legs;
            state.allInformation.liveScore.timeline = timeline;
            state.allInformation.liveScore.statistics = statistics;
            state.allInformation.liveScore.scores = scores;
            state.allInformation.liveScore.status = status;
        },
        setEndGame: (state, action: PayloadAction<OnEndGameLiveScore>) => {
            const { legs, scores, status } = action.payload;
            state.allInformation.liveScore.legs = legs;
            state.allInformation.liveScore.scores = scores;
            state.allInformation.liveScore.status = status;
        },
        setSidebar: (state, action: PayloadAction<ILiveScoreLocations[]>) => {
            state.allInformation.sidebarEvents = formatSideBar(action.payload);
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLiveScoreInfo.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(getLiveScoreInfo.fulfilled, (state: ILiveScoreReducer, action) => {
                state.allInformation.liveScore = action.payload.liveScore;
                state.allInformation.sidebar = action.payload.sidebar;
                state.activeSideBarEvent = action.meta.arg.gameId;
                state.liveScoreLoader = false;
                if (action.payload.sidebar) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload.sidebar.locations);
                }
            })
            .addCase(getLiveScoreInfo.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(changeGameLiveScore.pending, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = true;
            })
            .addCase(changeGameLiveScore.fulfilled, (state: ILiveScoreReducer, action) => {
                state.liveScoreLoader = false;
                state.allInformation.liveScore = action.payload.liveScore;
                state.activeSideBarEvent = action.meta.arg.gameId;
                state.liveScoreLoader = false;
            })
            .addCase(changeGameLiveScore.rejected, (state: ILiveScoreReducer) => {
                state.liveScoreLoader = false;
            })
            .addCase(updateSidebar.fulfilled, (state: ILiveScoreReducer, action) => {
                if (action.payload) {
                    state.allInformation.sidebarEvents = formatSideBar(action.payload);
                }
            })
    }})

export const {
    setLastShot,
    updateDataWithSocket,
    setEndGame,
    setSidebar
} = liveScoreInfoSlice.actions;
export default liveScoreInfoSlice.reducer;
