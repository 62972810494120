import React, {useState} from "react";
import {Button, Container, Table, Input, Loader} from "semantic-ui-react";
import {useEffect} from "react";
import DartsPlayersTableForm from "../../../../components/Form/DartsPlayersTableForm/DartsPlayersTableForm";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import TablePagination from "../../../../components/Pagination/Pagination";
import './style.scss'
import moment from "moment/moment";
import PhotoUploader from "./PhotoUploader/PhotoUploader";
import {
    getDartsPlayers,
    setCmsFilteredPlayers,
    setCurrentCmsPlayersPage,
    setFilteredPlayersOnCurrentPage,
    setPlayersOnCurrentPage, playersShowMode, deleteDartsPlayer, playersEditMode, playersEditPhotoMode
} from "../../../../store/cms/darts/players/cmsPlayersSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {setAdcQualifier} from "./helpers/setAdcQualifier";
import {useSortPlayers} from "./hooks/useSortPlayers";
import {showFullSizePhoto} from "./helpers/showFullSizePhoto";
import {getFilteredPayload} from "./helpers/getFilteredPayload";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";

const DartsPlayersTableDashBoard = () => {
    const {
        isVisibleForm,
        playersOnCurrentPage,
        dartsPlayers,
        playerCount,
        noContent,
        currentPage,
        loadingPlayers,
        filteredPlayers,
        filteredPlayersOnCurrentPage,
        isEditPhotoMode
    } = useAppSelector((state) => state.cmsPlayers);
    const {user} = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [filterMod, setFilterMod] = useState(false);
    const [sortedField, setSortedField] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [isShowPhotoWidget, setIsShowPhotoWidget] = useState(false)
    const [playerPhotoId, setPlayerPhotoId] = useState<number>(0)
    const [isExistPhoto, setIsExistPhoto] = useState('')

    const onSearch = () => {
        const filteredPlayers = getFilteredPayload({dartsPlayers, searchValue})
        dispatch(setCmsFilteredPlayers(filteredPlayers));
    };

    useEffect(() => {
        dispatch(getDartsPlayers());
    }, []);

    useEffect(() => {
        if (searchValue) {
            onSearch();
            setFilterMod(true);
            dispatch(setCurrentCmsPlayersPage(1))
        } else {
            setFilterMod(false);
        }
    }, [searchValue]);


    const {direction, sortedPlayers} = useSortPlayers(
        {
            sortedField,
            sortDirection,
            playersOnCurrentPage
        });

    const getPlayersForTable = (filterMod: boolean) => {
        return filterMod ? filteredPlayersOnCurrentPage : sortedPlayers;
    };


    const onSort = (field: string, sortDirection: string) => {
        setSortedField(field);
        setSortDirection(sortDirection);
    };
    const gotToPhotoWidget = (id:number) => {
        setIsShowPhotoWidget(true)
        setPlayerPhotoId(id)
    }

    return (
        <Container>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    <Input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search....."
                    />
                </div>
                <Button
                    color="green"
                    id="add_player"
                    onClick={() => dispatch(playersShowMode())}
                >
                    Add player
                </Button>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No players</div>
            ) : (
                <Table className='players-table' sortable celled compact>
                    <Table.Header>
                        {loadingPlayers ? (
                            <DimmerLoader/>
                        ) : (
                            <Table.Row>
                                {checkIsUserAdmin(user) &&
                                    <Table.HeaderCell
                                        textAlign="center"
                                    >
                                        Id
                                    </Table.HeaderCell>
                                }
                                <Table.HeaderCell
                                    sorted={
                                        sortedField === "name"
                                            ? direction === "asc"
                                                ? "ascending"
                                                : "descending"
                                            : undefined
                                    }
                                    onClick={() => onSort("name", direction)}
                                    textAlign="center"
                                >
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={sortedField === "surname" ? direction === "asc" ? "ascending" : "descending" : undefined}
                                    onClick={() => onSort("surname", direction)}
                                    textAlign="center"
                                >
                                    Surname
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center"
                                >
                                    Avatar

                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">Nickname</Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">Abbr</Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">Gender</Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    Displayname
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">Hometown</Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    Nationality
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    Darts used
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    Career best
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    Throw hand
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    Date of birth
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                    ADC Qualifier
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="table-header-cell"
                                    textAlign="center">
                                </Table.HeaderCell>
                            </Table.Row>
                        )}
                    </Table.Header>
                    {loadingPlayers ? null : (
                        <Table.Body>
                            {getPlayersForTable(filterMod)?.map((player) => {
                                return (
                                    <Table.Row key={player.id}>
                                        {checkIsUserAdmin(user) &&
                                            <Table.Cell textAlign="center">{player.id}</Table.Cell>
                                        }
                                        <Table.Cell textAlign="center">{player.name}</Table.Cell>
                                        <Table.Cell textAlign="center">{player.surname}</Table.Cell>
                                        <Table.Cell textAlign="center"><img
                                            alt='players-avatar'
                                            className='players-table-avatar'
                                            src={`data:image/png;base64,${player.avatar}`}
                                            onClick={() => showFullSizePhoto({
                                                id: player.id,
                                                setIsExistPhoto,
                                                dispatch
                                            })}
                                        /></Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.nickname}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.abbr.slice(0, 3)}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">{player.gender}</Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.displayname}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.hometown}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.nationality}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.dartsUsed}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.careerBest}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {player.throwHand}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {moment(player.dateOfBirth).format("YYYY-MM-DD")}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            {setAdcQualifier(player.adcQualifier)}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            <DartsDeleteModal
                                                id={player.id}
                                                confirmText={
                                                    "Are you sure you want to delete your player?"
                                                }
                                                deleteAction={() => dispatch(deleteDartsPlayer(player.id))}
                                                titleModal={"Delete your Player"}
                                            />
                                            <Button
                                                color="yellow"
                                                onClick={() => dispatch(playersEditMode(player.id))}
                                                size="mini"
                                                style={{marginTop: 10}}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                color='blue'
                                                size="mini"
                                                onClick={() => gotToPhotoWidget(player.id)}
                                                style={{marginTop: 10}}
                                            >
                                               Photo
                                            </Button>
                                            {isEditPhotoMode &&
                                                <div className="photo-uploader__show-full-size">
                                                    <div
                                                        onClick={() => dispatch(playersEditPhotoMode(false))}
                                                        className="photo-uploader__show-full-size-close"/>
                                                    {
                                                        isExistPhoto ?
                                                            <img src={`data:image/png;base64,${isExistPhoto}`} alt="full-players-photo"/>
                                                            :
                                                            <Loader active/>
                                                    }
                                                </div>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    )}
                </Table>
            )}
            {isVisibleForm && <DartsPlayersTableForm/>}
            {!noContent && (
                <TablePagination
                    setCurrentPage={setCurrentCmsPlayersPage}
                    setItemsInCurrentPage={
                        filterMod
                            ? setFilteredPlayersOnCurrentPage
                            : setPlayersOnCurrentPage
                    }
                    itemsCount={filterMod ? filteredPlayers.length : playerCount}
                    items={filterMod ? filteredPlayers : dartsPlayers}
                    currentPage={currentPage}
                    limitItemsInPage={5}
                />
            )}
            {isShowPhotoWidget &&
            <PhotoUploader
                playerId={playerPhotoId}
                closeUploaderHandler={setIsShowPhotoWidget}
            />}
        </Container>
    );
};

export default DartsPlayersTableDashBoard;
