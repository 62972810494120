import React, {useMemo} from 'react';
import {IDartsPhase} from "../../../../../../store/cms/darts/phases/types";
import {IDartsGroup, IDartsTournament} from "../../../../../../store/cms/darts/tournaments/types";

interface IUseTournamentsOptions {
    competitions: IDartsTournament[];
    phases: IDartsPhase[];
    groups: IDartsGroup[];
}

const useTournamentsOptions = ({competitions, groups, phases}: IUseTournamentsOptions) => {

    const competitionsOptions = useMemo(() => {
        return competitions?.map((comp) => ({
            value: comp.id,
            text: comp.name,
        }))
    }, [competitions])

    const groupsOptions = useMemo(() => {
        return groups?.map((group) => ({
            key: group.id,
            value: group.id,
            text: group.name,
        }))
    }, [groups])

    const phasesOptions = useMemo(() => {
        return phases?.map((phase) => ({
            key: phase.id,
            value: phase.id,
            text: phase.name,
        }))
    }, [phases])
    return (
        {competitionsOptions, groupsOptions, phasesOptions}
    );
};

export default useTournamentsOptions;
