import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AuthStateType, IAuthResponse, ILoginData} from "./types";
import {LS_TOKEN, LS_CURRENT_USER} from "../../constants/localStorageItems";
import {API_LOGIN, API_VERSION} from "../../constants";
import {errorLogger} from "../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {auth} from "../../services/auth.api";
import {IUser} from "../cms/usersNRoles/types";
import {connect} from "../webSockets/webSocketsSlice";

const initialState: AuthStateType = {
    user: {} as IUser,
    userEmail: '',
    apiVersion: '',
    errorMessage: '',
    isFailedRequest: false,
}


export const getOnStartUp = createAsyncThunk<IUser, void>(
    'auth/startUp',
    //@ts-ignore
    async (_, {dispatch, rejectWithValue}) => {
        try {
            if (localStorage.getItem(LS_CURRENT_USER)) {

                const storedUser = JSON.parse(localStorage.getItem(LS_CURRENT_USER)!);

                const currentUser: IUser = {
                    id: storedUser.id,
                    name: storedUser.name,
                    roles: storedUser.roles
                };
                dispatch(connect(currentUser.id));
                return currentUser
            }
        } catch (e) {
            toastr.error('Login start up:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)

export const getAuth = createAsyncThunk<IAuthResponse, ILoginData, { rejectValue: string }>(
    'auth/login',
    async (ILoginData, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await auth.post(API_LOGIN, ILoginData);
            const {user, token: {token}} = data;
            localStorage.setItem(LS_TOKEN, token);
            localStorage.setItem(LS_CURRENT_USER, JSON.stringify(user));
            ILoginData.history.push('/schedule');
            dispatch(getOnStartUp());
            return data;
        } catch (e) {
            toastr.error('Login error:', `${e.response.data}`);
            return rejectWithValue(e.response.data);
        }

    }
)

export const getVersion = createAsyncThunk(
    'auth/version',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await auth.get(API_VERSION);
            return data;
        } catch (error) {
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Login-version: ${error.name}, ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.message)
        }
    }
)


export const authSlice = createSlice({
    name: 'auth',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAuth.pending, (state: AuthStateType) => {
                state.errorMessage = "";
            })
            .addCase(getAuth.fulfilled, (state: AuthStateType, action) => {
                state.userEmail = action.meta.arg.email;
                state.user = action.payload.user;
            })
            .addCase(getAuth.rejected, (state: AuthStateType, action) => {
                state.errorMessage = action.payload;
            })
            .addCase(getVersion.fulfilled, (state: AuthStateType, action) => {
                state.apiVersion = action.payload;
            })
            .addCase(getOnStartUp.fulfilled, (state: AuthStateType, action) => {
                state.user = action.payload!;
            })
    }})

export default authSlice.reducer;



