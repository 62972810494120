import React from "react";
import {Pagination} from "semantic-ui-react";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {getDartsGames, setCurrentPaginationPage} from "../../store/cms/darts/games/cmsGamesSlice";
import {getAdcDartsGames, setCurrentAdcPaginationPage} from "../../store/cms/darts/adcGames/cmsAdcGamesSlice";
import {buildQueryString} from "../../screens/CMS/Darts/DartsGames/helpers/buildQueryString";
import "./index.scss";

interface ITablePaginationProps {
    itemsCount: number;
    limitItemsInPage: number;
    isAdcGame?: boolean;
}

const GamesPagination: React.FC<ITablePaginationProps> = ({
      itemsCount,
      limitItemsInPage,
      isAdcGame
    }) => {

    const {filterValues} = useAppSelector((state) => state.cmsGames);
    const adcFilterValues = useAppSelector((state) => state.cmsAdcGames.filterValues)
    const dispatch = useAppDispatch();
    let totalPages = Math.ceil(itemsCount / limitItemsInPage);

    const onChangePage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, {activePage}: any) => {
        if (isAdcGame) {
            const payload = {...adcFilterValues, pNum: activePage}
            return getGames(payload)
        }
        const payload = {...filterValues, pNum: activePage}
        getGames(payload);
    };

    const getGames = (payload:  any): void => {
        if (isAdcGame) {
            dispatch(setCurrentAdcPaginationPage(payload.pNum))
            return dispatch(getAdcDartsGames(buildQueryString(payload)));
        }
        dispatch(setCurrentPaginationPage(payload.pNum))
        dispatch(getDartsGames(buildQueryString(payload)));
    }

    return (
        <div>
            <Pagination
                floated="right"
                size="mini"
                activePage={isAdcGame ? adcFilterValues.pNum : filterValues.pNum}
                totalPages={totalPages}
                onPageChange={onChangePage}
            />
        </div>
    );
};

export default GamesPagination;
