import {useMemo} from 'react';
import {GameEvent, HomeAwayScore} from "../../../enums/gameEvents";
import {ILiveScoreItemTimeLine} from "../../../store/pages/liveScoreInfo/types";

const useLastLegNumber = (timeLines: ILiveScoreItemTimeLine[]): number => {
    const currentLegScore = useMemo(() => {
        return {
            home: timeLines?.filter((log) => !log.homeAway && log.eventType === GameEvent.LegWin).length,
            away: timeLines?.filter((log) => log.homeAway && log.eventType === GameEvent.LegWin).length,
        } as HomeAwayScore
    }, [timeLines])

    const isWonSet = timeLines?.findIndex((log) => log.eventType === GameEvent.WinSet) !== -1;

    return isWonSet
        ? currentLegScore.away + currentLegScore.home
        : currentLegScore.away + currentLegScore.home + 1
};

export default useLastLegNumber;
