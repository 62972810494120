import {Action, configureStore, ThunkDispatch, getDefaultMiddleware} from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";
import authReducer from './store/login/authSlice';
import webSocketReducer from './store/webSockets/webSocketsSlice';
import scheduleReducer from './store/pages/schedule/scheduleSlice';
import activeGameScoreReducer from './store/pages/activeGameScore/toolkit/activeGameScoreSlice';
import shootOutActiveGameScoreReducer from './store/pages/shootOutActiveGameScore/shootOutActiveGameScoreSlice';
import liveScoreInfoReducer from './store/pages/liveScoreInfo/liveScoreInfoSlice';
import postMatchReducer from './store/pages/postMatch/postMatchSlice';
import preMatchReducer from './store/pages/preMatch/preMatchSlice';
import leagueTableReducer from './store/pages/leagueTable/leagueTableSlice';
import scoreboardStatsReducer from './store/pages/scoreboardAndStats/scoreboardAndStatsSlice';
import cmsGamesReducer from './store/cms/darts/games/cmsGamesSlice';
import cmsAdcGamesReducer from './store/cms/darts/adcGames/cmsAdcGamesSlice'
import cmsShootOutGamesReducer from './store/cms/darts/shootOutGames/cmsShootOutGamesSlice';
import cmsPlayersReducer from './store/cms/darts/players/cmsPlayersSlice';
import cmsCompetitionsReducer from './store/cms/darts/competitions/competitionsSlice';
import cmsPhasesReducer from './store/cms/darts/phases/cmsPhasesSlice';
import cmsWeeksReducer from './store/cms/darts/weeks/cmsWeeksSlice';
import cmsTournamentsReducer from './store/cms/darts/tournaments/cmsTournamentsSlice';
import cmsUsersNRolesReducer from './store/cms/usersNRoles/cmsUsersNRolesSlice';
import cmsClientsReducer from './store/cms/clients/cmsClientsSlice';
import cmsNewsReducer from './store/cms/modusNews/cmsModusNewsSlice';
import gameHandlerReducer from './store/dartsGame/handler/gameHandlerSlice';
import dartsCurrentGameReducer from './store/dartsGame/currentGame/dartsCurrentGameSlice';

import { createSocketMiddleware } from './socket';

const socketMiddleware = createSocketMiddleware();

const middleware = [...getDefaultMiddleware(), socketMiddleware];

export const rootReducer = {
    toastr: toastrReducer,
    connection: webSocketReducer,
    auth: authReducer,
    schedule: scheduleReducer,
    gameHandler: gameHandlerReducer,
    currentDartsGame: dartsCurrentGameReducer,
    scoreboardAndStats: scoreboardStatsReducer,
    activeGameScore: activeGameScoreReducer,
    shootOutActiveGameScore: shootOutActiveGameScoreReducer,
    liveScoreInfo: liveScoreInfoReducer,
    postMatch: postMatchReducer,
    preMatch: preMatchReducer,
    leagueTable: leagueTableReducer,
    cmsAdcGames: cmsAdcGamesReducer,
    cmsGames: cmsGamesReducer,
    cmsShootOutGames: cmsShootOutGamesReducer,
    cmsPlayers: cmsPlayersReducer,
    cmsCompetitions: cmsCompetitionsReducer,
    cmsPhases: cmsPhasesReducer,
    cmsWeeks: cmsWeeksReducer,
    cmsTournaments: cmsTournamentsReducer,
    cmsUsersNRoles: cmsUsersNRolesReducer,
    cmsClients: cmsClientsReducer,
    cmsNews: cmsNewsReducer,
}
const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: true,
});

export default store;


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch<ReturnType = void> = ThunkDispatch<RootState, void, Action<string>>;
