interface IDisplayNamesShoot {
    pl1Name: string;
    pl1Surname: string;
    pl2Name: string;
    pl2Surname: string;
    pl3Name: string;
    pl3Surname: string;
}

export const getSeparateDisplayNames = (gameName: string): IDisplayNamesShoot | undefined=> {
    const players: string[] = gameName?.split(' vs ');
    const playerNames: string[] = players?.map((player) => player?.trim()?.split(' '))?.flat();
    if (playerNames?.length) {
        return {
            pl1Name: playerNames[0]?.replace(/_/g, ' '),
            pl1Surname: playerNames[1]?.replace(/_/g, ' '),
            pl2Name: playerNames[2]?.replace(/_/g, ' '),
            pl2Surname: playerNames[3]?.replace(/_/g, ' '),
            pl3Name: playerNames[4]?.replace(/_/g, ' '),
            pl3Surname: playerNames[5]?.replace(/_/g, ' '),
        }
    }
}
