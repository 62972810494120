import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import ShootOutPlayerScoreboardHeader from "../PlayerShootOutScoreboardHeader/ShootoutPlayerScoreboardHeader";
import ShootOutPlayerScoreboardBody from "../PlayerShootOutScoreboardBody/ShootOutPlayerScoreboardBody";
import '../styles.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    getShootOutActiveGameScoreData
} from "../../../store/pages/shootOutActiveGameScore/shootOutActiveGameScoreSlice";


const ShootOutScoreboard: React.FC = () => {
    const { leagueId: leagueIdParam } = useParams<{ leagueId: string }>();
    const dispatch = useAppDispatch();
    const {activeGameData} = useAppSelector((state) => state.shootOutActiveGameScore);
    const connected = useAppSelector((state) => state.connection.connection);
    const {currentPlayerId,playerIDs,playersName,playersScore,scores} = activeGameData

    useEffect(() => {
        if (connected && leagueIdParam)
            dispatch(getShootOutActiveGameScoreData(Number(leagueIdParam)));
    }, [connected, leagueIdParam]);

    return (
        <Grid columns={3} className="scoreboard shootout">
            {playersScore && scores ? (
                <>
                    <Grid.Row className="scoreboard_header">
                        <Grid.Column>
                            <ShootOutPlayerScoreboardHeader
                                isCurrentPlayerThrow={currentPlayerId=== playerIDs[0]}
                                remainingScore={playersScore[0]}
                                playerName={playersName[0]}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ShootOutPlayerScoreboardHeader
                                isCurrentPlayerThrow={currentPlayerId=== playerIDs[1]}
                                remainingScore={playersScore[1]}
                                playerName={playersName[1]}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ShootOutPlayerScoreboardHeader
                                isCurrentPlayerThrow={currentPlayerId=== playerIDs[2]}
                                remainingScore={playersScore[2]}
                                playerName={playersName[2]}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="scoreboard_body">
                        <Grid.Column className={'scores-column'}>
                            <div className={'scores-column-item'}>
                                <ShootOutPlayerScoreboardBody
                                    turnData={scores[0]}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column className={'scores-column'}>
                            <div className={'scores-column-item'}>
                                <ShootOutPlayerScoreboardBody
                                    turnData={scores[1]}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column className={'scores-column'}>
                            <div className={'scores-column-item'}>
                                <ShootOutPlayerScoreboardBody
                                    turnData={scores[2]}
                                />
                            </div>
                        </Grid.Column>

                    </Grid.Row>
                </>
            ) : <OverlayLoader />}
        </Grid>
    );
}


export default ShootOutScoreboard;
ShootOutScoreboard.displayName = "ShootOutScoreboard";
