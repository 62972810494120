import React  from "react";
import {Form, Label, Select, Icon, Button} from "semantic-ui-react";
import Flatpickr from "react-flatpickr";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {useGetOptions} from "./hooks/useSelectOptions";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {filterValidationSchema} from "./helpers/filterValidationSchema";
import {
    clearAdcFilter,
    filterAdcGames,
    getAdcDartsGames,
    setAdcGameFilter,
} from "../../../../store/cms/darts/adcGames/cmsAdcGamesSlice";
import {dartsGameStatusesOptions} from "../../../../constants/gameStatuses";

import {defaultAdcFilter} from "../../../../store/cms/darts/adcGames/helpers/createFilterPayload";
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";

interface IGameFilterPros {
    closeFilter: () => void;
}

const DartsGamesAdcFilter: React.FC<IGameFilterPros> = ({closeFilter}) => {
    const dispatch = useAppDispatch();
    const {players, competitions, countries} = useAppSelector(
        (state) => state.cmsAdcGames.initData
    );
    const {filterValues} = useAppSelector(state => state.cmsAdcGames)

    const {
        control,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(filterValidationSchema),
    });
    const {
        playersOptions,
        competitionOptions,
        countriesOptions,
        dayPickerOptions,
    } = useGetOptions({players, competitions, countries});

    const clearFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        dispatch(getAdcDartsGames(buildQueryString(defaultAdcFilter)))
        dispatch(clearAdcFilter(defaultAdcFilter))
    };

    const getFilteredGames = () => {
        dispatch(filterAdcGames(buildQueryString(filterValues)));
    };

    return (
        <div className="filter-overlay">
            <Form className="cms-filter">
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={closeFilter}
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label pointing="below">Competition</Label>
                    <Controller
                        name="competition"
                        control={control}
                        render={() => (
                            <Select
                                defaultValue={filterValues.Competition}
                                value={filterValues.Competition}
                                id="competition"
                                options={competitionOptions}
                                name="Competition"
                                placeholder="Select competition"
                                onChange={(e, {name, value}) =>
                                    dispatch(setAdcGameFilter({field: name, value}))}
                            />
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <Label pointing="below">Location</Label>
                    <Controller
                        name="location"
                        control={control}
                        render={() => (
                            <Select
                                id="location"
                                name="Location"
                                defaultValue={filterValues.Location}
                                value={filterValues.Location}
                                options={countriesOptions}
                                placeholder="Select location"
                                onChange={(e, {name, value}) =>
                                    dispatch(setAdcGameFilter({field: name, value}))}
                            />
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <Label pointing="below">Player</Label>
                    <Controller
                        name="player"
                        control={control}
                        render={() => (
                            <Select
                                id="player"
                                name="Player"
                                defaultValue={filterValues.Player}
                                value={filterValues.Player}
                                options={playersOptions}
                                placeholder="Select player"
                                onChange={(e, {name, value}) =>
                                    dispatch(setAdcGameFilter({field: name, value}))}
                            />
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <Label pointing="below">Status</Label>
                    <Controller
                        name="status"
                        control={control}
                        render={() => (
                            <Select
                                id="status"
                                name={"Status"}
                                defaultValue={filterValues.Status}
                                value={filterValues.Status}
                                options={dartsGameStatusesOptions}
                                placeholder="Select status"
                                onChange={(e, {name, value}) =>
                                    dispatch(setAdcGameFilter({field: name, value}))}
                            />
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <Label pointing="below">From</Label>
                    <Flatpickr
                        id={"dateFrom"}
                        options={dayPickerOptions}
                        value={filterValues.From}
                        onChange={(date: Date, dayStr: string) => {
                            dispatch(setAdcGameFilter({field: "From", value: dayStr}));
                        }}
                    />
                </Form.Field>
                <div className="cms-filter__btn-inner">
                    <Button onClick={getFilteredGames} color="green">
                        Filter
                    </Button>
                    <Button onClick={(e) => clearFilter(e)} color="green">
                        Reset Filter
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default DartsGamesAdcFilter;
