import {client} from "../../../../services/api.service";
import {API_DARTS_MATCH_STATUS} from "../../../../constants";
import {IMatchStatusPayload} from "../types";
import {AppThunkDispatch} from "../../../../store";
import {setCurrentGameStatus} from "../dartsCurrentGameSlice";
import {GameTypes} from "../../../../enums/gameEvents";
import {clearGame} from "../../handler/gameHandlerSlice";
import {selectAdcGame, selectClassicGame} from "../../../pages/schedule/scheduleSlice";

export const setMatchStatusForCertainGame = async (payload: IMatchStatusPayload, dispatch: AppThunkDispatch): Promise<void> => {
    const {gameType, gameData, history} = payload;
    const API_CLASSIC = `${API_DARTS_MATCH_STATUS}-${gameData.status}/${gameData.gameId}`;
    const API_ADC = `${API_DARTS_MATCH_STATUS}-${gameData.status}-adc/${gameData.gameId}`;
    const API_SHOOTOUT = `${API_DARTS_MATCH_STATUS}-${gameData.status}-9shots/${gameData.gameId}`;
    switch (gameType) {
        case GameTypes.ADC:
            const adcResponse = await client.put(API_ADC, gameData);
            dispatch(setCurrentGameStatus(gameData.status));
            dispatch(clearGame());
            dispatch(selectAdcGame(0))
            if (history) {
                history.push('/schedule')
            }
            return adcResponse.data;

        case GameTypes.SHOOTOUT:
            const shootOutResponse = await client.put(API_SHOOTOUT, gameData);
            dispatch(setCurrentGameStatus(gameData.status));
            dispatch(clearGame());
            if (history) {
                history.push('/schedule')
            }
            return shootOutResponse.data;

        default:
            const classicResponse = await client.put(API_CLASSIC, gameData);
            dispatch(setCurrentGameStatus(gameData.status));
            dispatch(clearGame());
            dispatch(selectClassicGame(0))
            if (history) {
                history.push('/schedule')
            }
            return classicResponse.data;
    }
};
