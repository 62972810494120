export const defaultAdcFilter = {
    Competition: 0,
    Location: "",
    Player: 0,
    From: '',
    Status: 0,
    pNum: 1,
    pSize: 10,
    Sort: false,
}
