import "./styles.scss";
import React, {useCallback, useEffect, useState} from "react";
import { Container, Grid, Icon } from "semantic-ui-react";
import { GameStatus } from "../../../../enums/gameStatus";
import {getPlayerName} from "../../../../helpers/playersNames.helper";
import {useAppSelector} from "../../../../hooks/hooks";
import {ILiveScoreAllInfo} from "../../../../store/pages/liveScoreInfo/types";
import {dartsGameStatuses} from "../../../../constants/gameStatuses";

interface IDartboardHeaderProps {
  lastLegNumber: number;
  allInfo: ILiveScoreAllInfo;
}

export const DartboardHeader: React.FC<IDartboardHeaderProps> = React.memo((props) => {
  const {lastLegNumber } = props;
  const { playerNames, tournamentName,status,scores,legs,turnShots,turnShotsHomeAway } = useAppSelector((state) => state.liveScoreInfo.allInformation.liveScore)
  const playersScores = scores;
  const [currentStatus, setCurrentStatus] = useState('');
  useEffect(() => {
    if (status) {
      const statusString = dartsGameStatuses?.find(
          (item) => item.key === status
      );
      if (statusString?.value) {
        setCurrentStatus(statusString.value)
      }
    }


  }, [status])

  const notThrownDart = <Icon name="location arrow" fitted />;

  const getPlayerScoresElement = useCallback((playerScore: number[]) => {
    if (playerScore) {
      const firstThrow = playerScore[0] ? playerScore[0] : notThrownDart;
      const secondThrow = playerScore[1] ? playerScore[1] : notThrownDart;
      const thirdThrow = playerScore[2] ? playerScore[2] : notThrownDart;
      const totalRoundScore = playerScore.reduce(
          (val, round) => val + round,
          0
      );
      return (
          <div className="header__game-info__score__rounds" key="current">
            <div className="throw">{firstThrow}</div>
            <div className="throw">{secondThrow}</div>
            <div className="throw">{thirdThrow}</div>
            <div className="throw round-total">{totalRoundScore}</div>
          </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[turnShots])

  const awayPlayerScoresElement = getPlayerScoresElement(turnShots!);
  const homePlayerScoresElement = getPlayerScoresElement(turnShots!);

  return (
      <Container className="header">
        <Container className="header__league">
          <span className="header__league__title">{tournamentName}</span>
          <span className="header__league__type"/>
        </Container>
        <Grid columns={3} className="header__game-info">
          <Grid.Column width={5} id="player_home" className="header__game-info__home">
            {getPlayerName(playerNames?.home)?.map((item) => {
              if (item) {
                return ` ${item}`
              }
            })}
          </Grid.Column>
          <Grid.Column width={6} className="header__game-info__score">
            <Grid>
              <Grid.Row columns={5} className="np centered-v">
                <Grid.Column textAlign="right">
                  {turnShotsHomeAway ? homePlayerScoresElement : null}
                </Grid.Column>
                <Grid.Column className="current-points" id="left_score" textAlign="right">
                  {playersScores!== null ? playersScores?.home : null}
                </Grid.Column>
                <Grid.Column>
                  <Grid
                      className="header__game-info__score__short"
                      textAlign="center"
                  >
                    <Grid.Row className="header__game-info__score__short__status">
                      {<span id="game_status" className="centered-h">
                      {currentStatus}
                    </span>}
                    </Grid.Row>
                    <Grid.Row className="header__game-info__score__short__current">
                    <span id="leg_home" className="header__game-info__score__short__current__home">
                      {legs?.home}
                    </span>
                      <span className="header__game-info__score__short__current__semi">
                      :
                    </span>
                      <span id="leg_away" className="header__game-info__score__short__current__away">
                      {legs?.away}
                    </span>
                    </Grid.Row>
                                  <Grid.Row className="header__game-info__score__short__round">
                                      {(status !== GameStatus.End && status !== GameStatus.PreEnd) && (
                          <span id="round_num" className="centered-h round-name">
                        {`Leg ${lastLegNumber}`}
                      </span>
                      )}
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column className="current-points" id="right_score" textAlign="left">
                  {playersScores !== null ? playersScores?.away : null}
                </Grid.Column>
                <Grid.Column textAlign="left">
                  {turnShotsHomeAway === false ? awayPlayerScoresElement : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={5} id="player_away" className="header__game-info__away">
            {getPlayerName(playerNames?.away)?.map((item) => {
              if (item) {
              return ` ${item}`
              }
            })}
          </Grid.Column>
        </Grid>
      </Container>
  );
})
