import React from "react";
import {Container, Grid} from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";
import {useAppSelector} from "../../../../hooks/hooks";
import {renderIconForAwayPlayer, renderIconForHomePlayer} from "./helpers/getIcon";
import {getLogContentAwayPlayer, getLogContentHomePlayer} from "./helpers/getLogContent";

export const DartTimeline: React.FC = React.memo(() => {
    const allInfo = useAppSelector((state) => state.liveScoreInfo.allInformation);
    const timeLines = allInfo?.liveScore?.timeline?.items;
    const {playerNames} = allInfo?.liveScore
    const formatTime = (seconds: number): string => {
        return moment.utc(seconds * 1000).format("mm:ss");
    };

    return (
        <Container>
            <div>
                <Grid>
                    <Grid.Row columns={16} className="timeline__round__teams">
                        <Grid.Column width={8} textAlign="left">
                            {removeNameSeparator(playerNames?.home)}
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                            {removeNameSeparator(playerNames?.away)}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid className="timeline">
                    {timeLines?.map((line, index) => {
                        return (
                            <Grid.Row
                                key={index}
                                columns={16}
                                className="timeline__round"
                                verticalAlign="middle"
                            >
                                <Grid.Column width={2} className="timeline__round__time">
                                    {formatTime(line?.seconds)}
                                </Grid.Column>
                                <Grid.Column
                                    width={5}
                                    className="timeline__round__content"
                                    textAlign="right"
                                >
                                    {getLogContentHomePlayer(line)}
                                </Grid.Column>
                                <Grid.Column width={1} className="timeline__round__icon">
                                    {renderIconForHomePlayer(line)}
                                </Grid.Column>
                                <Grid.Column width={2} className="timeline__round__score">
                                    {line.score}
                                </Grid.Column>
                                <Grid.Column width={1} className="timeline__round__icon">
                                    {renderIconForAwayPlayer(line)}
                                </Grid.Column>
                                <Grid.Column
                                    width={5}
                                    className="timeline__round__content"
                                    textAlign="left"
                                >
                                    {getLogContentAwayPlayer(line)}
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })}
                </Grid>
            </div>
        </Container>
    );
})

DartTimeline.displayName = "DartTimeline";
