import React, {PropsWithChildren, useState} from 'react';
import {Icon, Segment} from 'semantic-ui-react';
import {GameStatus} from '../../../../enums/gameStatus';
import ActionButtons from '../ActionButtons/ActionButtons';
import './styles.scss';
import ShootOutLogTable from "../ShootOutLogTable/ShootOutLogTable";
import ShootOutActionButtons from "../ShootOutActionsBtn/ShootOutActionButtons";
import LogsTable from "../LogsTable/LogsTable";
import {SportType} from "../../../../enums/gameEvents";

interface IActionsContainerAdcProps {
    isAdcGame?: boolean;
    sportType: SportType;
    onClickActionButton: (eventStatus: GameStatus, newStatus: GameStatus, message: string) => void;
    isReadonly: boolean;
    setIsOpenEndGameModal: (flag: boolean) => void;
    isShootOutGame?: boolean
    propsAreEqual?: (
        prevProps: Readonly<PropsWithChildren<IActionsContainerAdcProps>>,
        nextProps: Readonly<PropsWithChildren<IActionsContainerAdcProps>>
    ) => boolean;
}

const ActionsContainer: React.FC<IActionsContainerAdcProps> = React.memo(({
   isShootOutGame, isAdcGame,
   setIsOpenEndGameModal,
   sportType,
   isReadonly,
   onClickActionButton
}) => {
    const [visible, setVisible] = useState<boolean>(true);
    const icon = visible ? 'angle double left' : 'angle double right';

    const renderTab = () => {
        if (visible && isShootOutGame) {
            return (<ShootOutLogTable/>);
        }
        if (visible && !isShootOutGame) {
            return (<LogsTable/>);
        }

        return (
            <>
                {isShootOutGame &&

                    <ShootOutActionButtons
                        setIsOpenEndGameModal={setIsOpenEndGameModal}
                        onClickActionButton={onClickActionButton}
                        sportType={sportType}
                    />
                }
                {!isAdcGame && !isShootOutGame &&
                    <ActionButtons
                        setIsOpenEndGameModal={setIsOpenEndGameModal}
                        onClickActionButton={onClickActionButton}
                        sportType={sportType}
                    />
                }
            </>
        );
    }

    return (
        <div className='actions-container'>
            <Segment>
                {
                    !isReadonly && (
                        <div
                            className='actions-container__toggle'
                            onClick={() => setVisible(!visible)}>
                            <Icon name={icon}/>
                        </div>
                    )
                }
                <div className='actions-container__sidebar'>
                    {renderTab()}
                </div>
            </Segment>
        </div>
    );
},(prevProps, nextProps) => {
    return !(nextProps.sportType !== prevProps.sportType && nextProps.isReadonly !== prevProps.isReadonly);

})

export {ActionsContainer};
