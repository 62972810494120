import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Button, Dropdown, DropdownProps, Label} from "semantic-ui-react";
import {useFormatGameOptions} from "./hooks/useFormatGameOption";
import {useSelectCurrentGameByTour} from "./hooks/useSelectCurrentGame";
import {isGamesExistForSelectedTour} from "./helpers/isGamesExistForSelectedTour";
import "./styles.scss";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    getDartsInit, getFilteredTournaments,
    selectAdcGame,
    selectClassicGame,
    selectTournament
} from "../../store/pages/schedule/scheduleSlice";
import {DartsADCGame} from "../../store/pages/schedule/types";
import {IFilteredTournaments} from "../../store/cms/darts/tournaments/types";
import {GameTypes} from "../../enums/gameEvents";
import useRolesAccess from "./hooks/useRolesAccess";
import ScheduleTopButtons from "./components/ScheduleTopButtons/ScheduleTopButtons";
import ScheduleInformButtons from "./components/ScheduleInformButtons/ScheduleInformButtons";
import ScheduleActiveButtons from "./components/ScheduleActiveButtons/ScheduleActiveButtons";

const Schedule = () => {
    const dispatch = useAppDispatch();
    const {
        selectedTournament,
        selectedGame,
        selectedAdcGame,
        isLoading,
        isFilterLoading,
        isFilterContent
    } = useAppSelector((state) => state.schedule);
    const {
        games,
        gamesADC,
        activeIs9shots,
        activeGameId,
        activeTournamentId,
        tournaments,
        phases,
        statuses,
        activeIsADC,
    } = useAppSelector((state) => state.schedule.initData);
    const {user} = useAppSelector((state) => state.auth);
    const [scheduleStats, setScheduleStats] = useState({visible: false});
    const [activeAdcGame, setActiveAdcGame] = useState<DartsADCGame | null | undefined>(null);
    const [filterParams, setFilterParams] = useState<number>(0);
    const {
        gamesOptions,
        adcGamesOption
    } = useFormatGameOptions(selectedTournament, games, gamesADC, statuses, dispatch);
    const [isAdcGameSelected, setIsAdcGameSelected] = useState(false);
    const {setDartsGameType, dartsGameType} = useSelectCurrentGameByTour(
        selectedTournament,
        selectedGame, activeGameId,
        games, activeTournamentId,
        activeIsADC,
        activeIs9shots
    )
    useEffect(() => {
        activeIsADC ?
            setDartsGameType({type: GameTypes.ADC}) :
            setDartsGameType({type: GameTypes.CLASSIC});
    }, [])

    useEffect(() => {
        const game = gamesADC?.find((game: DartsADCGame) => game.id === selectedAdcGame);
        setActiveAdcGame(game)
    }, [selectedAdcGame])

    useEffect(() => {
        if (!selectedGame && activeIsADC) {
            setIsAdcGameSelected(true);
            setDartsGameType({type: GameTypes.ADC})
        }
    }, [activeIsADC])

    useEffect(() => {
        if (!selectedGame && activeGameId) {
            dispatch(selectClassicGame(activeGameId))
        }
    }, [selectedGame])

    const handleChangeActiveGame = (dropDownData: DropdownProps) => {
        if (!dropDownData.value) {
            dispatch(selectAdcGame(0));
            dispatch(selectClassicGame(0));

        }

        if (dropDownData.id === GameTypes.CLASSIC) {
            setDartsGameType({...dartsGameType, type: GameTypes.CLASSIC})
            dispatch(selectClassicGame(Number(dropDownData.value)));
            dispatch(selectAdcGame(0));
            setIsAdcGameSelected(false);
        }

        if (dropDownData.id === GameTypes.ADC) {
            setDartsGameType({...dartsGameType, type: GameTypes.ADC})
            dispatch(selectAdcGame(Number(dropDownData.value)));
            dispatch(selectClassicGame(0));
            setIsAdcGameSelected(true);
        }
    };

    const getDartsInitData = () => {
        dispatch(getDartsInit());
        setScheduleStats({
            ...scheduleStats,
            visible: !scheduleStats.visible,
        });
    };

    const getFilteredTourns = () => {
        if (filterParams === null) {
            const payload = {
                phaseIDs: null,
            };
            dispatch(getFilteredTournaments(payload))
            return;
        }
        const payload = {
            phaseIDs: [filterParams]
        };
        dispatch(getFilteredTournaments(payload))
    };

    const handleChangeActiveTournaments = (dropDownData: DropdownProps) => {
        dispatch(selectTournament(Number(dropDownData.value)));
    };

    const tournamentsOptions = useCallback(() => {
        return tournaments?.map((tour: IFilteredTournaments) => {
            return {key: tour.id, value: tour.id, text: tour.name};
        });
    }, [tournaments]);

    const getFilterOptions = useCallback(() => {
        const filterOptions = phases?.map((phase: { id: number, name: string }) => ({
            key: phase.id,
            icon: "filter",
            text: `Phase name : ${phase.name}`,
            value: phase.id,
        }));
        filterOptions?.unshift({
            //@ts-ignore
            key: "all",
            icon: "filter",
            //@ts-ignore
            value: null,
            text: "All",
        });
        return filterOptions;
    }, [phases]);

    const {isCrmUser, isScoringUser, isViewerUser, isStatisticsUser} = useRolesAccess(user);

    return (
        <Fragment>
            <ScheduleTopButtons user={user} isStatisticsUser={isStatisticsUser} isCrmUser={isCrmUser}/>
            <div className="schedule__container">
                <div className="schedule__sports">
                    <Button
                        className="sport-select-btn"
                        secondary
                        id="sport_select"
                        onClick={getDartsInitData}
                    >
                        <img
                            alt="logo"
                            src={process.env.PUBLIC_URL + "/modus-home-page-logo.png"}
                        />
                    </Button>
                </div>
                <div className="schedule__match-select">
                    {isLoading && <div style={{textAlign: "center"}}>Loading...</div>}
                    {scheduleStats.visible && !isLoading &&
                        <div className="schedule-match-inner">
                            <div className="schedule__tournaments-inner">
                                <div className="schedule__input-inner">
                                    <Dropdown
                                        placeholder="Select a tournament"
                                        fluid
                                        selection
                                        disabled={!isFilterContent}
                                        value={selectedTournament}
                                        onChange={(_, data) => {
                                            handleChangeActiveTournaments(data);
                                        }}
                                        options={tournamentsOptions()}
                                    />
                                    <Button.Group
                                        color="green"
                                        className="schedule__filter-btn"
                                    >
                                        <Button
                                            id="filter"
                                            loading={isFilterLoading}
                                            onClick={getFilteredTourns}
                                        >
                                            Filter
                                        </Button>
                                        <Dropdown
                                            className="button icon"
                                            floating
                                            options={getFilterOptions()}
                                            onChange={(_, data) => {
                                                setFilterParams(Number(data.value));
                                            }}
                                            trigger={<></>}
                                        />
                                    </Button.Group>
                                </div>
                                {
                                    isViewerUser
                                    &&
                                    <ScheduleInformButtons
                                        gamesOptions={gamesOptions}
                                        selectedGame={selectedGame}
                                        selectedTournament={selectedTournament}
                                        gamesType={dartsGameType}
                                    />
                                }
                            </div>
                            {selectedTournament && gamesOptions !== undefined && gamesOptions.length > 0 ? (
                                <div className="schedule__match-inner">
                                    <div>
                                        <Label className="schedule-games-label">Games</Label>
                                        <Dropdown
                                            disabled={activeIsADC}
                                            placeholder="Select a match"
                                            fluid
                                            selection
                                            id={GameTypes.CLASSIC}
                                            value={selectedGame}
                                            onChange={(_, data) => {
                                                handleChangeActiveGame(data);
                                            }}
                                            options={gamesOptions}
                                        />
                                    </div>
                                </div>
                            ) : (
                                isGamesExistForSelectedTour(selectedTournament, selectedAdcGame, dispatch)
                            )}
                            {!!adcGamesOption?.length &&
                                <div>
                                    <Label className="schedule-games-label schedule-games-label-adc">Adc Qualifier
                                        Games</Label>
                                    <Dropdown
                                        disabled={Boolean(activeTournamentId)}
                                        id={GameTypes.ADC}
                                        placeholder="Select an adc qualifier match"
                                        fluid
                                        selection
                                        value={selectedAdcGame}
                                        onChange={(_, data) => {
                                            handleChangeActiveGame(data);
                                        }}
                                        options={adcGamesOption}
                                    />
                                </div>
                            }
                            {selectedGame || selectedAdcGame
                                ?
                                <ScheduleActiveButtons
                                    isScoringUser={isScoringUser}
                                    isViewerUser={isViewerUser}
                                    selectedAdcGame={selectedAdcGame}
                                    selectedGame={selectedGame}
                                    games={games}
                                    activeAdcGame={activeAdcGame}
                                    isAdcGameSelected={isAdcGameSelected}
                                    activeIs9shots={activeIs9shots}
                                    activeGameId={activeGameId}
                                    setIsAdcGameSelected={setIsAdcGameSelected}
                                    setDartsGameType={setDartsGameType}
                                    activeIsADC={activeIsADC}
                                    activeTournamentId={activeTournamentId}
                                    selectedTournament={selectedTournament}/>

                                : null
                            }
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default Schedule;
Schedule.displayName = "Schedule";
