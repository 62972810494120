import React from 'react';
import Scoreboard from './Scoreboard';
import './styles.scss';

interface IScoreboardPageProps {
}

export const ScoreboardPage: React.FC<IScoreboardPageProps> = () => {

  return (
    <div className="scoreboard-page">
      <Scoreboard />
    </div>
  );
}
ScoreboardPage.displayName = "ScoreboardPage";

