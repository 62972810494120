export const imgUrlToFile = (url: string, fileName: string) => {
    const splitImgData = url.split(',');
    //@ts-ignore
    const mime = splitImgData[0].match(/:(.*?);/)[1];
    const data = splitImgData[1];

    const dataStr = atob(data);
    let n = dataStr.length;
    const dataArr = new Uint8Array(n);

    while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
    }

    return new File([dataArr], fileName, {type: mime})
}
