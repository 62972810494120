import React, {useEffect, useState} from "react";
import {Button, Container, Icon, Progress} from "semantic-ui-react";
import Flatpickr from "react-flatpickr";
import {client} from "../../services/api.service";
import fileDownload from "js-file-download";
import {useHistory} from "react-router-dom";
import "./style.scss";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {setCsvStatistics} from "../../store/pages/schedule/scheduleSlice";

export const Statistics = () => {
    const [startDate, setStartDate] = useState<string | Date>(new Date());
    const [endDate, setEndDate] = useState<string | Date>(new Date());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalGames, setTotalGames] = useState<number | null>(null);
    const currentGames = useAppSelector((state) => state.schedule.statistics);
    const dispatch = useAppDispatch()
    const history = useHistory()
    const getTotalGamesAndOpenSocket = async (from: string | Date, to: string | Date) => {
        const body = {from, to,};
        dispatch(setCsvStatistics(0))
        setIsLoading(true);
        setTotalGames(null)
        const totalGames = await client.post("/api/darts-statistics/form-statistics", body);
        setTotalGames(totalGames.data);
        setIsLoading(false);
    };
    useEffect(() => {
        if (currentGames === totalGames) {
            getCSVStatistics()
        }
    }, [totalGames, currentGames])

    const getCSVStatistics = async () => {
        setIsLoading(true);
        const CSVFile = await client.get("/api/darts-statistics/get-csv-file");
        fileDownload(CSVFile.data, "statistics.csv");
        setIsLoading(false);
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    return (
        <Container>
            <div onClick={()=>history.go(-1)} className="back-arrow">
                <div>
                    <Icon className="back-arrow__icon" name="arrow left"/>
                </div>
                <p className="back-arrow__text">Back to Schedule</p>
            </div>
            <div className="statistics">
                <div className="statistics-inputs">
                    <div className="statistics__datepicker">
                        <label>Start date:</label>
                        <Flatpickr
                            className="statistics__flatpickr"
                            value={startDate}
                            options={dayPickerOptions}
                            onChange={(date: Date, dayStr: string) => setStartDate(dayStr)}
                        />
                    </div>
                    <div className="statistics__datepicker">
                        <label>End date:</label>
                        <Flatpickr
                            className="statistics__flatpickr"
                            value={endDate}
                            options={dayPickerOptions}
                            onChange={(date: Date, dayStr: string) => setEndDate(dayStr)}
                        />
                    </div>
                </div>
                <div className="statistics__download-btn">
                    <Button
                        loading={isLoading}
                        onClick={() => getTotalGamesAndOpenSocket(startDate, endDate)}
                        color="green"
                    >
                        Download Stats
                    </Button>
                </div>
            </div>
            {totalGames && (
                <Progress
                    color="green"
                    progress="value"
                    value={currentGames}
                    total={totalGames}/>
            )}
        </Container>
    );
};

export default Statistics;
