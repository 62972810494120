import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {getPlayerName} from "../../../../helpers/playersNames.helper";
import {useParams} from "react-router-dom";
import {renderFirstThrowerInLegPointer} from "./helpers/renderFirstThrowerInLegPointer";
import {setCurrentThrowerStyles, trowerAway} from "./helpers/setCurrentThrowerStyles";
import {getBestOfSevenLegs} from "./helpers/getBestOfSevenLegs";
import GameScoreFooter from "./components/GameScoreFooter";
import "./styles.scss";

const GameScores: React.FC = React.memo(() => {
    const {leagueId} = useParams<{ leagueId: string }>();
    const dispatch = useAppDispatch();
    const statistics = useAppSelector((state) => state.scoreboardAndStats.bestOfSevenLeg);
    const {dataTurns180s} = useAppSelector((state) => state.scoreboardAndStats);
    const [animationAway180, setAnimationAway180] = useState(false);
    const [animationHome180, setAnimationHome180] = useState(false);

    useEffect(() => {
        if (dataTurns180s.playerHomeAway === false) {
            setAnimationAway180(true);
            setTimeout(() => {
                setAnimationAway180(false);
            }, 2000);
            // dispatch(scoreStatsActionCreators.resetHomeAwayTurn())
        }
        if (dataTurns180s.playerHomeAway) {
            setAnimationHome180(true);
            setTimeout(() => {
                setAnimationHome180(false);
            }, 2000);
            // dispatch(scoreStatsActionCreators.resetHomeAwayTurn())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTurns180s]);


    useEffect(() => {
        getBestOfSevenLegs({id: +leagueId, dispatch});
    });

    return (
        <div className="root__container">
            <div className="game-scores">
                <div className="game-scores__container">
                    <div className="wrapper">
                        <div className="game-scores-table">
                            <div className="game-scores-header">
                                <div className="name-cell">
                                    <div>{statistics?.groupName}</div>
                                </div>
                                <div className="name-cell"></div>
                                <div className=" name-cell-widget-name">
                                    <div>
                                        <div>Best of {(statistics?.legsToWin * 2) - 1} legs</div>
                                    </div>
                                </div>
                                <div className="game-scores-header-right-wrapper">
                                    <div className="value-column">
                                        <div>Legs</div>
                                    </div>
                                    <div className="game-scores-right-score">
                                        <div>Score</div>
                                    </div>
                                </div>

                            </div>
                            <div className="game-scores-body">
                                <div className="game-scores-row">
                                    <div className="game-scores-row__player">
                                        <div className="player__container">
                                            <div className="player__info">
                                                <div
                                                    className="player__throws"
                                                    style={setCurrentThrowerStyles(!!statistics?.lastThrowHA)}
                                                >
                                                    {statistics?.numThrows?.home}
                                                </div>
                                                <div
                                                    className="player__name"
                                                >
                                                    {`${getPlayerName(statistics?.players?.home)[0]}
                                                    ${getPlayerName(statistics?.players?.home)[1]}`}

                                                </div>
                                            </div>
                                            <div>
                                                {renderFirstThrowerInLegPointer(
                                                    statistics?.firstPlayerHA
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="game-scores-body-scores-wrapper">
                                        <div className="score score-legs">
                                            <div>{statistics?.legs?.home}</div>
                                        </div>
                                        <div className="score score-value">
                                            <div>{statistics?.score?.home}</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="game-scores-row">
                                    <div className="game-scores-row__player">
                                        <div className="player__container">
                                            <div className="player__info">
                                                <div
                                                    className="player__throws"
                                                    style={setCurrentThrowerStyles(
                                                        trowerAway(statistics?.lastThrowHA)
                                                    )}
                                                >
                                                    {statistics?.numThrows?.away}
                                                </div>
                                                <div className="player__name"
                                                >
                                                    {`${getPlayerName(statistics?.players?.away)[0]}
                                                    ${getPlayerName(statistics?.players?.away)[1]}`}
                                                </div>
                                            </div>
                                            {renderFirstThrowerInLegPointer(
                                                trowerAway(statistics?.firstPlayerHA)
                                            )}
                                        </div>
                                    </div>

                                    <div className="game-scores-body-scores-wrapper">
                                        <div className="score score-legs">
                                            <div>{statistics?.legs?.away}</div>
                                        </div>
                                        <div className="score score-value">
                                            <div>{statistics?.score?.away}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'game-scores-footer'}>
                              <GameScoreFooter/>
                            </div>
                        </div>
                        <div
                            className={
                                animationAway180
                                    ? "animation-180-away-active"
                                    : "animation-180-away"
                            }
                        >
                            <p>180</p>
                        </div>
                        <div
                            className={
                                animationHome180
                                    ? "animation-180-home-active"
                                    : "animation-180-home"
                            }
                        >
                            <p>180</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default GameScores;
