import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import {useAppDispatch} from "../../hooks/hooks";

interface IDartsDeleteModal {
  id: number;
  deleteAction: (id: number) => any;
  confirmText: string;
  titleModal: string;
  closeUploaderHandler?: (flag: false) => void;
}
const DartsDeleteModal: React.FC<IDartsDeleteModal> = ({ id, deleteAction, titleModal, confirmText, closeUploaderHandler }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const deleteHandler = (id: number) => {
    dispatch(deleteAction(id));
    setOpen(false);
    if (closeUploaderHandler) {
      closeUploaderHandler(false);
    }
  };

  return (
    <>
      <Button size="mini" color="red" onClick={() => setOpen(true)}>Delete</Button>
      <Modal size={"mini"} open={open} onClose={() => setOpen(false)}>
        <Modal.Header>{titleModal}</Modal.Header>
        <Modal.Content>
          <p>{confirmText}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpen(false)}>
            No
          </Button>
          <Button positive onClick={() => deleteHandler(id)}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default DartsDeleteModal;
