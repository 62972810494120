import {client} from "../../../../services/api.service";
import {API_DARTS_MATCH_STATUS} from "../../../../constants";
import {deleteActiveGameAndTournament} from "../../../pages/schedule/scheduleSlice";
import {IMatchStatusPayload} from "../types";
import {AppThunkDispatch} from "../../../../store";
import {setCurrentGameStatus} from "../dartsCurrentGameSlice";
import {disconnectFromGameSocket} from "../../../../services/connection.service";
import {GameTypes} from "../../../../enums/gameEvents";

export const setEndGame = async (payload: IMatchStatusPayload, dispatch: AppThunkDispatch): Promise<void> => {
    const {gameData, gameType, history} = payload;
    const API_CLASSIC = `${API_DARTS_MATCH_STATUS}-${gameData.status}/${gameData.gameId}`;
    const API_ADC = `${API_DARTS_MATCH_STATUS}-${gameData.status}-adc/${gameData.gameId}`;
    const API_SHOOTOUT = `${API_DARTS_MATCH_STATUS}-${gameData.status}-9shots/${gameData.gameId}`;
    let response;

    switch (gameType) {
        case GameTypes.ADC:
            response = await client.put(API_ADC, gameData);
            if (response.status === 204) {
            }
            dispatch(setCurrentGameStatus(response.data.status))
            dispatch(deleteActiveGameAndTournament())
            localStorage.setItem('isGameRunning', JSON.stringify(false))
            await disconnectFromGameSocket(gameData.gameId!)
            if (history) {
                history.go(-1)
            }
            return;

        case GameTypes.SHOOTOUT:
            response = await client.put(API_SHOOTOUT, gameData);
            if (response.status === 204) {
            }
            dispatch(setCurrentGameStatus(response.data.status));
            dispatch(deleteActiveGameAndTournament());
            localStorage.setItem('isGameRunning', JSON.stringify(false))
            await disconnectFromGameSocket(gameData?.gameId!);
            if (history) {
                history.go(-1)
            }
            return;

        default:
            response = await client.put(API_CLASSIC, gameData);
            if (response.status === 204) {
            }
            dispatch(setCurrentGameStatus(response.data.status));
            dispatch(deleteActiveGameAndTournament());
            localStorage.setItem('isGameRunning', JSON.stringify(false));
            await disconnectFromGameSocket(gameData?.gameId!);
            if (history) {
                history.go(-1)
            }
            return;
    }
}
