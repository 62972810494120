import React, {Fragment, useEffect} from "react";
import './styles.scss';
import {Button, Container, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import UsersForm from "./UsersForm";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    createModeHandler,
    deleteUser,
    getSingleUser,
    getUsers
} from "../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {IUser} from "../../../../store/cms/usersNRoles/types";
import UsersPagination from "./components/UserPagination";


const Users = () => {
    const dispatch = useAppDispatch();
    const {
        allUsers,
        usersLoader,
        createMode,
        editMode,
        filterValues,
        usersCount
    } = useAppSelector((state) => state.cmsUsersNRoles);

    const getRoles = (roles: string[] | null) => {
        if (Array.isArray(roles)) {
            return !roles.length ?  ["-"] :  roles.join(', ');
        }
        return ["-"];
    }

    const generateButtons = (user: IUser) => {
        return (
            <Fragment>
                <DartsDeleteModal
                    id={user.id}
                    deleteAction={deleteUser}
                    confirmText={"Are you sure you want to delete this user?"}
                    titleModal={"Delete the user"}
                />
                <Button
                    onClick={() => editUserHandler(user.id)}
                    size="mini"
                    style={{marginTop: 5}}
                    color="yellow"
                >
                    Update
                </Button>
            </Fragment>
        );
    };
    const editUserHandler = (id: number) => dispatch(getSingleUser(id));
    const createUserHandler = () => dispatch(createModeHandler(true));

    useEffect(() => {
        dispatch(getUsers(filterValues))
    }, [dispatch]);

    return (
        <>
            <Container>
                <div className="users-btn-wrapper">
                    <Button
                        disabled={usersLoader}
                        className={'users-table'}
                        color="blue"
                        id="add_game"
                        onClick={createUserHandler}>
                        Add user
                    </Button>
                </div>
            </Container>
            {allUsers?.length > 1 && !usersLoader &&
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center">
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Role
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                E-mail
                            </Table.HeaderCell>

                            <Table.HeaderCell textAlign="center"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {allUsers?.map((user: IUser) => {
                            return (
                                <Table.Row key={user.id}>
                                    <Table.Cell textAlign="center">
                                        {user.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {getRoles(user.roles!)}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{user.email}</Table.Cell>

                                    <Table.Cell textAlign="center">
                                        {generateButtons(user)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            }
            {usersLoader && <DimmerLoader/>}
            {createMode || editMode ? <UsersForm/> : null}
            <UsersPagination
                itemsCount={usersCount}
                limitItemsInPage={10}
            />
        </>
    );
}

export default Users;
