import React from "react";
import { Button, Segment } from "semantic-ui-react";
import "./styles.scss";
import {GameStatus} from "../../../../enums/gameStatus";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {setWalkoverModal} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {SportType} from "../../../../enums/gameEvents";

export interface IActionButtonProps {
  sportType: SportType;
  onClickActionButton: (
    eventStatus: GameStatus,
    newStatus: GameStatus,
    message: string
  ) => void;
  setIsOpenEndGameModal: (flag: boolean) => void;
}

const ActionButtons: React.FC<IActionButtonProps> = ({
  onClickActionButton,
  setIsOpenEndGameModal,
}) => {
  const dispatch = useAppDispatch();
  const {activeGameData} = useAppSelector((state) => state.activeGameScore);
  const {currentGameStatus} = useAppSelector((state) => state.currentDartsGame);

  const handleEndGame = (gameStatus: number | string) => {
    if (gameStatus !== GameStatus.PreEnd && gameStatus !== "end") {
      setIsOpenEndGameModal(true);
      return;
    } else {
      onClickActionButton(GameStatus.Closed, GameStatus.End, "end");
    }
  };

  const walkoverHandler = () => {
    onClickActionButton(GameStatus.Void, GameStatus.Walkover, "walkover");
    return dispatch(setWalkoverModal(true));
  }

  return (
      <Segment>
        <div className="action-buttons__container">
          <div className="action-buttons__row">
            <Button
                disabled={currentGameStatus === GameStatus.PreEnd}
                onClick={() =>
                    onClickActionButton(GameStatus.Open, GameStatus.Delayed, "delay")
                }
                color="blue"
            >
              Delay
            </Button>
            <Button
                disabled={currentGameStatus === GameStatus.PreEnd}
                onClick={() =>
                    onClickActionButton(
                        GameStatus.Pending,
                        GameStatus.Suspended,
                        "suspend"
                    )
                }
                color="violet"
            >
              Suspend
            </Button>
            <Button
                disabled={currentGameStatus === GameStatus.PreEnd}
                onClick={() =>
                    onClickActionButton(GameStatus.Void, GameStatus.Postponed, "postpone")
                }
                color="violet"
            >
              Postpone
            </Button>
          </div>
          <div className="action-buttons__row">
            <Button
                onClick={walkoverHandler}
                color="yellow"

                disabled={currentGameStatus === GameStatus.Walkover || !activeGameData || currentGameStatus === GameStatus.PreEnd}
            >
              Walkover
            </Button>
            <Button
                disabled={currentGameStatus === GameStatus.PreEnd}
                onClick={() =>
                    onClickActionButton(GameStatus.Void, GameStatus.Abandoned, "abandon")
                }
                color="orange"
            >
              Abandon
            </Button>
            <Button
                disabled={currentGameStatus === GameStatus.PreEnd}
                onClick={() =>
                    onClickActionButton(GameStatus.Void, GameStatus.Cancelled, "cancel")
                }
                color="red"
            >
              Cancel
            </Button>
          </div>
          <div className="action-buttons__row">
            <Button
                className="end-game-btn"
                onClick={() => handleEndGame(currentGameStatus)}
            >
              End Game
            </Button>
          </div>
        </div>
      </Segment>
  );
};


export default ActionButtons;
