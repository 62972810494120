import {DropdownItemProps} from "semantic-ui-react";
import {IDartsGameCountry, IDartsGamePlayer} from "../../../../../../../store/cms/darts/games/types";
import {IDartsTournament} from "../../../../../../../store/cms/darts/tournaments/types";

interface IUseShootOutFormOptions {
    countries: IDartsGameCountry[],
    players: IDartsGamePlayer[],
    dartsTournaments: IDartsTournament[],
}

export const useShootOutFormOptions = ({countries, players, dartsTournaments}: IUseShootOutFormOptions) => {

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    const countryOptions: DropdownItemProps[] = countries?.map((country: { intCode: any; alpha2Code: any; englishName: any; }) => ({
        key: country.intCode,
        value: country.alpha2Code,
        text: country.englishName,
    }));

    const playersOptions: DropdownItemProps[] = players?.map((player: { id: number; displayname: string }) => ({
        key: player.id,
        value: player.id,
        text: player.displayname,
    }));

    const tournamentsOptions: DropdownItemProps[] = dartsTournaments?.map((tour: { id: any; name: any; }) => ({
        key: tour.id,
        value: tour.id,
        text: tour.name,
    }));

    const formStyle = {
        width: 370,
        padding: 20,
        position: "relative",
        background: "white",
        borderRadius: 10,
    };

    return {
        dayPickerOptions,
        countryOptions,
        playersOptions,
        tournamentsOptions,
        formStyle
    }
}
