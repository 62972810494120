import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {API_DARTS_WEEKS} from "../../../../constants";
import {DartsWeeks, DartsWeeksData} from "./types";

const initialState: DartsWeeks = {
    dartsWeeks: [] as DartsWeeksData[],
    isLoading: true,
    isVisibleForm: false,
    editMode: false,
    editWeekId: null,
    weeksCount: 0,
    weeksOnCurrentPage: [] as DartsWeeksData[],
    currentPage: 1,
    noContent: null
}

export const getDartsWeeks = createAsyncThunk<DartsWeeksData[]>(
    'dartsWeeks/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_DARTS_WEEKS);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts weeks.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Weeks: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const cmsWeeksSlice = createSlice({
    name: 'cmsWeeksPhases',
    reducers: {
        setCurrentCmsWeekPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setWeekOnCurrentPage: (state, action: PayloadAction<DartsWeeksData[]>) => {
            state.weeksOnCurrentPage = action.payload;
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsWeeks.fulfilled, (state, action) => {
                state.dartsWeeks = action.payload;
                state.weeksCount = action.payload.length;
                state.noContent = false;
                state.isLoading = false;
            })
            .addCase(getDartsWeeks.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export const {
    setWeekOnCurrentPage,
    setCurrentCmsWeekPage
} = cmsWeeksSlice.actions
export default cmsWeeksSlice.reducer;
