export enum GameEvent {
    Shot = 1,
    TurnBust = 2,
    TurnFinished = 3,
    LegStart = 11,
    LegWin = 12,
    LegMissed = 13,
    GameStart = 21,
    GameStatus = 22,
    GameEnd = 23,
    WinSet = 24,
    PlayerChoosed = 31,
    PlayerChanged = 32,
    Point = 33,
}

export enum SportType {
    Darts,
    TableTennis,
    Unknown,
}


export enum GameTypes  {
    SHOOTOUT = "SHOOTOUT",
    CLASSIC = "CLASSIC",
    ADC = "ADC"
}

export interface FinishedRound {
    leg: number;
    roundNumber: number;
    currentScore: number;
    playerId: number;
    scores: Score[];
    timestamp: Date;
    gameId: number;
}

export interface HomeAwayScore {
    home: number;
    away: number;
}

export interface Score {
    score: number;
    bed: string;
    ring: string;
    date: Date;
    isDoubleAttempt: boolean;
    scoreBeforeShot: number;
}
export type OnShotForLiveScore = {
    bed: number;
    ring: string;
    message: string;
}

export type PlayerScoreData = {
    turn: null | number;
    score: number;
}
