import {IRole, IUserFormData} from "../../../../../store/cms/usersNRoles/types";
import {getRoleIDs} from "./getRolesId";

interface IUserPayload {
    inputData: IUserFormData;
    roles: IRole[];
    userId?: number;
}

export const createUserPayload = ({inputData, roles, userId}: IUserPayload) => {
    if (userId) {
        return {
            id: userId,
            roleIDs: getRoleIDs(inputData.userRole, roles)!,
            email: inputData.userEmail,
            name: inputData.userName
        }
    }
    return {
        roleIDs: getRoleIDs(inputData.userRole, roles)!,
        email: inputData.userEmail,
        name: inputData.userName,
        password: inputData.userPassword!
    }
}
