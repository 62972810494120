import React, {useEffect, useRef, useState} from "react";
import PlayerVsPLayer from "./PlayerVsPlayer/PlayerVsPlayer";
import {useSpring, animated} from "react-spring";
import './styles.scss'
import StartPage from "./StartPage/StartPage";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {removeNameSeparator} from "../../helpers/playersNames.helper";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {getPreMatchStats} from "../../store/pages/preMatch/preMatchSlice";

export interface IStatisticData {
    gameId: number,
    playerHome: IPlayerAnimateData,
    playerAway: IPlayerAnimateData,
}

export interface IPlayerAnimateData {
    id: number,
    name: string,
    surName: string,
    picture: string,
    average: number,
    checkoutPercentage: number,
    winLossRecord: string,
    lastGames: boolean | null [],
    winningPercent: number,
    perMatch180: number,
    displayName: string

}

const PreMatchPage = () => {
    const refLogoHighlight = useRef(null);
    const refAnimatedBg = useRef(null);
    const [counter, setCounter] = React.useState(0);
    const [isActiveCounter, setIsActiveCounter] = useState(false)
    let timeoutId: any;

    const dispatch = useAppDispatch();
    const {
        gameStatistic,
        isDataDownloaded,
        isReady
    } = useAppSelector((state) => state.preMatch);

    useEffect(() => {
        dispatch(getPreMatchStats())
    }, [dispatch])

    useEffect(() => {
            if (isDataDownloaded) {
                setIsActiveCounter(true)
            }
        }
        , [isDataDownloaded])

    const counterHandler = () => {
        timeoutId = setTimeout(() => {
            setCounter(counter + 100)
        }, 100);
        if (counter >= 40000) {
            clearTimeout(timeoutId)
            setCounter(0)
        }
    }
    const stopCountdown = () => {
        setIsActiveCounter(false)
    }

    useEffect(() => {
        if (!isActiveCounter) {
            setCounter(0)
        }
        isActiveCounter && counterHandler();
        return () => {
            clearTimeout(timeoutId)
        }
    }, [counter, isActiveCounter]);


    const PvPStyles = useSpring({
        to: async (next) => {
            await next({
                opacity: counter > 3000 ? 1 : 0,
                display: counter > 3000 ? 'block' : 'none'
            })
        },
        from: {opacity: 0},
    })
    const PvPStyleOut = useSpring({
        to: async (next) => {
            await next({
                opacity: 0,
                display: "none",
            })
        },
        from: {opacity: 1, display: 'none'},
    })

    const startPageStyles = useSpring({
        to: async (next, cancel) => {
            await next({
                opacity: counter <= 3000 ? 1 : 0,
                display: counter <= 3000 ? "block" : "none"
            })
        },
        from: {opacity: 1, display: "block"},
        config: {duration: 600}

    })
    const startPageStylesEnd = useSpring({
        to: async (next, cancel) => {
            await next({
                opacity: counter >= 40100 ? 1 : 0,
                display: counter >= 40100 ? "block" : "none"
            })
        },
        from: {opacity: 0, display: "none"},
        config: {duration: 600}
    })

    return (
        <>
            {
                isDataDownloaded
                    ?
                    isReady ?

                        <div className="pre-match-page">
                            <div className="pre-match-participants">
                                <div className="pre-match-players">
                                    {removeNameSeparator(gameStatistic.playerHome.displayName)}
                                </div>
                                <div>
                                    vs
                                </div>
                                <div className="pre-match-players">
                                    {removeNameSeparator(gameStatistic.playerAway.displayName)}
                                </div>
                            </div>
                            <div className="blackBgLayout">
                            </div>
                            <animated.div
                                style={
                                    counter > 10000
                                        ?
                                        {width: "85%", height: "85vh", ...startPageStylesEnd}
                                        :
                                        {width: "85%", height: "85vh", ...startPageStyles}
                                }>

                                <div style={{backgroundColor: 'yellow'}}>
                                    {<StartPage counter={counter} ref={refLogoHighlight}/>}
                                </div>
                            </animated.div>
                            <animated.div
                                style={
                                    counter < 40900
                                        ?
                                        {width: "85%", height: "85vh", ...PvPStyles}
                                        :
                                        {width: "85%", height: "85vh", ...PvPStyleOut}
                                }>
                                <PlayerVsPLayer
                                    ref={refAnimatedBg}
                                    animateData={gameStatistic}
                                    counter={counter}/>
                            </animated.div>

                        </div> :
                        <div className="pre-match-page">
                            <div className="blackBgLayout">
                                <div className="pre-match-failed-request">Data does not exist</div>
                            </div>
                        </div>
                    :
                    <div className="pre-match-page">
                        <div className="blackBgLayout">
                            <OverlayLoader/>
                        </div>
                    </div>
            }
        </>

    )
}

export default PreMatchPage;
PreMatchPage.displayName = "PreMatchPage";
