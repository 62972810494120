import "./styles.scss";
import {Button, Form, Icon, Label} from "semantic-ui-react";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    clearSingleClientState, createClient,
    createModeHandler, editClient, editModeHandler
} from "../../../store/cms/clients/cmsClientsSlice";
import createClientPayload, {updateClientPayload} from "./helpers/createPayload";
import {IClient} from "../../../store/cms/clients/types";


const ClientForm = () => {

    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState<IClient>({
        id: 0,
        name: '',
        email: '',
        sendNotification: false,
        notificationUrl: ''
    });
    const {client, editMode} = useAppSelector((state) => state.cmsClients);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IClient>({
    });

    const closeForm = () => {
        if (editMode) {
            dispatch(editModeHandler(false));
            return dispatch(clearSingleClientState());
        }
        dispatch(createModeHandler(false));
    };

    const onSubmit = (data: IClient) => {
        setFormData({
            ...formData,
            name: data.name,
            email: data.email,
            notificationUrl: data.notificationUrl
        })

        if (editMode) {
            setFormData({
                ...formData,
                id: client.id
            })
            const updClient = updateClientPayload(formData);
            return dispatch(editClient(updClient));
        }
        const newClient = createClientPayload(formData);
        dispatch(createClient(newClient))
    };

    useEffect(() => {

        if (editMode) {
            setFormData({
                ...formData,
                id: client.id,
                name: client.name,
                email: client.email,
                notificationUrl: client.notificationUrl,
                sendNotification: client.sendNotification
            })
        }
    }, [editMode])

    const checkboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, sendNotification: e.target.checked})
    }
    return (
        <div className="form-overlay">
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className={"darts-users-form"}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={closeForm}
                    style={{float: "right", cursor: "pointer"}}
                />

                <Form.Field>
                    <Label className="darts-users-form-label">Client name</Label>
                    <input
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                        value={formData.name}
                        defaultValue={client.name}
                        name="name"
                        ref={register({required: true, minLength: 3})}/>

                    {errors.name && errors.name.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The client name field is required</p>
                        </div>}
                    {errors.name && errors.name.type === 'minLength' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>This field required min 3 symbols</p>
                        </div>}
                </Form.Field>

                <Form.Field>
                    <Label className="darts-users-form-label">E-mail</Label>
                    <input
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                        type={'email'}
                        defaultValue={client.email}
                        name="email"
                        value={formData.email}
                        ref={register({required: true, minLength: 5})}/>

                    {errors.email && errors.email.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The client e-mail field is required</p>
                        </div>}
                    {errors.email && errors.email.type === 'minLength' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>This field required min 5 symbols</p>
                        </div>}
                </Form.Field>

                <Form.Field>
                    <Label className="darts-users-form-label">Notification URL</Label>
                    <input
                        onChange={(e) => setFormData({...formData, notificationUrl: e.target.value})}
                        value={formData.notificationUrl!}
                        defaultValue={client.notificationUrl!}
                        name="clientNotificationUrl"
                        ref={register()}
                    />

                </Form.Field>

                <Form.Field className={'clients-notification-wrapper'}>
                    <Label className="darts-users-form-label">Notification</Label>
                    <input
                        defaultChecked={client.sendNotification}
                        className="clients-notification-checkbox"
                        type={"checkbox"}
                        checked={formData.sendNotification}
                        onChange={(e) => checkboxHandler(e)}
                    />

                </Form.Field>

                <Button id="save_client" color="green" type="submit">
                    {editMode ? 'Update' : 'Create'}
                </Button>
            </Form>
        </div>
    );
}

export default ClientForm;
ClientForm.displayName = "ClientForm";
