import React, {useEffect} from 'react';
import './styles.scss';
import './styles.media.scss';
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import mmsLogo from "../../img/MSSLogoMain.png"
import {getPlayerName} from "../../helpers/playersNames.helper";
import {getPostMatchStats} from "../../store/pages/postMatch/postMatchSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";


const PostMatchPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const {playersStatistics, isLoading} = useAppSelector((state) => state.postMatch);


    useEffect(() => {
        dispatch(getPostMatchStats())
    }, [dispatch]);

    const {players, home, away} = playersStatistics

    return (

        <div className="post-match-wrapper">
            <div className="static-statistic">
                {
                    isLoading ? <OverlayLoader/> :
                        <div className="static-statistic-wrapper">
                            <div className="static-statistic-names">

                                {
                                    players &&
                                    <div>
                                    <span
                                        className="static-statistic-names-firstname">{getPlayerName(players?.home)[0]}
                                    </span>
                                        {players && ` ${getPlayerName(players?.home)[1]}`}
                                    </div>
                                }

                                <div className="static-statistic-total-points">
                                    <div className="static-statistic-total-points-wrapper">{home?.totalScore}</div>
                                    <div className="static-statistic-total-points-wrapper">{away?.totalScore}</div>
                                </div>

                                {
                                    players &&
                                    <div>
                                    <span
                                        className="static-statistic-names-firstname">{getPlayerName(players?.away)[0]}</span>
                                        {players && ` ${getPlayerName(players?.away)[1]}`}
                                    </div>
                                }
                            </div>
                            <div className="static-statistic-container">
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {home?.average?.toFixed(2)}
                                    </div>
                                    <div className="static-statistic-item-title"> 3 dart average</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {away?.average.toFixed(2)}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {home?.turns180}
                                    </div>
                                    <div className="static-statistic-item-title">180s</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {away?.turns180}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {home?.turns140to179}
                                    </div>
                                    <div className="static-statistic-item-title">140+</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {away?.turns140to179}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {home?.checkouts}
                                    </div>
                                    <div className="static-statistic-item-title">Checkouts</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {away?.checkouts}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value ">
                                        {`${home?.checkoutPercentage}%`}
                                    </div>
                                    <div className="static-statistic-item-title">Checkout %</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {`${away?.checkoutPercentage}%`}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {home?.highestCheckout}
                                    </div>
                                    <div className="static-statistic-item-title">Highest checkout</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {away?.highestCheckout}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {home?.checkouts100plus}
                                    </div>
                                    <div className="static-statistic-item-title">100+ checkouts</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {away?.checkouts100plus}
                                    </div>
                                </div>
                                <div className="static-statistic-item">
                                    <div className="static-statistic-logo">
                                        <img src={mmsLogo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

export default PostMatchPage;
