import React, {useCallback, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import {bounceInDown} from "react-animations";
import styled, {keyframes} from "styled-components";
import {useState} from "react";
import {useParams} from "react-router-dom";
import "./styles.scss";
import './styles-media.scss';
import moment from "moment";
import modusLogo from '../../img/MSSLogoMain.png';
import {LS_ACTIVE_PLAYER} from "../../constants/localStorageItems";
import {removeNameSeparator} from "../../helpers/playersNames.helper";
import {getScoreboardStatsInit} from "../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {IUrlParams} from "../../constants/globalTypes";
import {DartsPlayer} from "../../store/cms/darts/players/types";
import {formatAverageScore} from "./helpers/formatAverageScore";
import {showPlayerCard} from "./helpers/showPlayerCard";
import LeagueTable from "../LeagueTablePage/LeagueTable/LeagueTable";
import GameScores from "../DartBoard/components/GameScores/GameScores";
import {useWindowDimensions} from "../../hooks/useWindowDiensions";


const DartMatchStatsTable: React.FC = () => {
    const getBtnNameToLocalStorage = (): string | null => {
        if (!localStorage.getItem(LS_ACTIVE_PLAYER)) return null;
        return JSON.parse(localStorage.getItem(LS_ACTIVE_PLAYER)!);
    }

    const {gameType, gameId} = useParams<IUrlParams>();
    const dispatch = useAppDispatch();

    const {dataTurns180s, playersStatistics, statsLoader, leagueTable: {tournamentId}} = useAppSelector(
        (state) => state.scoreboardAndStats
    );
    const isConnected = useAppSelector((state) =>  state.connection);

    const BounceInDown = styled.div`
      animation: 0.6s ${keyframes`${bounceInDown}`};
    `;
    const [phaseCounterVal, setPhaseCounterVal] = useState(0);
    const [weekCounter, setWeekCounter] = useState(0);
    const [weekCounterAnimation, setWeekCounterAnimation] = useState(false);
    const [phaseCounterAnimation, setPhaseCounterAnimation] = useState(false);
    const [isShowPlayerCard, setIsShowPlayerCard] = useState<boolean>(true);
    const [activeBtnName, setActiveBtnName] = useState(getBtnNameToLocalStorage());
    const [playerInfo, setPlayerInfo] = useState({} as DartsPlayer);
    const {home, away, playerIDs} = playersStatistics;

    const windowDimensions = useWindowDimensions();

    const renderCounter = useCallback(
        (isAnimation: boolean, counterValue: number) => {
            if (isAnimation) {
                return (
                    <BounceInDown>
                        <div className="counter__scoreboard">
                            <span className="counter__scoreboard__value">{counterValue}</span>
                        </div>
                    </BounceInDown>
                );
            } else {
                return (
                    <div className="counter__scoreboard">
                        <span className="counter__scoreboard__value">{counterValue}</span>
                    </div>
                );
            }
        },
        [BounceInDown]
    );



    const getPlayerAge = (dateOfBirth: string) => {
        const formatDateOfBirth = moment(dateOfBirth).format("YYYY-MM-DD")
        return moment().diff(formatDateOfBirth, 'years', false)
    }

    const handlerSetWeekCounter = (counterData: number) => {
        setWeekCounter(counterData);
        setWeekCounterAnimation(true);
        setTimeout(() => {
            setWeekCounterAnimation(false);
        }, 600);
    };

    const handlerSetPhaseCounter = (counterData: number) => {
        setPhaseCounterVal(counterData);
        setPhaseCounterAnimation(true);
        setTimeout(() => {
            setPhaseCounterAnimation(false);
        }, 600);
    };

    const setBtnNameToLocalStorage = (activeBtnClass: string | null): void => {
        localStorage.setItem(LS_ACTIVE_PLAYER, JSON.stringify(activeBtnClass))
    }


    useEffect(() => {
        if (dataTurns180s) {
            handlerSetPhaseCounter(dataTurns180s.turns180inPhase);
            handlerSetWeekCounter(dataTurns180s.turns180inWeek);
        }
    }, [dataTurns180s]);

    useEffect(() => {
        if (isConnected) {
            dispatch(getScoreboardStatsInit(
                {
                    gameType: gameType,
                    gameId:  +gameId!
                }));
        }
    }, [isConnected]);

    useEffect(() => {
        setBtnNameToLocalStorage(activeBtnName)
    }, [activeBtnName])

    useEffect(() => {
        if (playerIDs) {
            switch (getBtnNameToLocalStorage()) {
                case '1player':  // if (x === 'value1')
                    showPlayerCard({
                        btnName: '1player',
                        playerId: playerIDs.home,
                        setPlayerInfo,
                        setActiveBtnName,
                        setIsShowPlayerCard
                    })
                    break;
                case '2player':  // if (x === 'value2')
                    showPlayerCard({
                        btnName: '2player',
                        playerId: playerIDs.away,
                        setPlayerInfo,
                        setActiveBtnName,
                        setIsShowPlayerCard
                    })
                    break;
                default:
                    showPlayerCard({
                        btnName: '1player',
                        playerId: playerIDs.home,
                        setPlayerInfo,
                        setActiveBtnName,
                        setIsShowPlayerCard
                    })
            }
        }
    }, [playerIDs])

    return statsLoader ? (
        <div className="match-stats-loader">Loading...</div>
    ) : (
        <div className="match-stats">
            {playersStatistics && (
                <Grid>
                    <Grid.Row>
                        <Grid.Column className="panel-column" width="5">
                            <div className="prematch-control">
                                <button
                                    className={activeBtnName === "1player" ? "prematch-control__btn prematch-control__btn-active" : "prematch-control__btn"}
                                    onClick={() => showPlayerCard({
                                        btnName: '1player',
                                        playerId: playerIDs.home,
                                        setPlayerInfo,
                                        setActiveBtnName,
                                        setIsShowPlayerCard
                                    })}>
                                    P1
                                </button>
                                <button
                                    onClick={() => showPlayerCard({
                                        btnName: '2player',
                                        playerId: playerIDs.away,
                                        setPlayerInfo,
                                        setActiveBtnName,
                                        setIsShowPlayerCard
                                    })}
                                    className={activeBtnName === "2player" ?
                                        "prematch-control__btn prematch-control__btn-active"
                                        :
                                        "prematch-control__btn"}>
                                    P2
                                </button>
                            </div>
                            {isShowPlayerCard && playerInfo &&
                                <div className="player-card">
                                    <div className="player-card__logo">
                                        <img
                                            alt="logo"
                                            className="player-card__logo-img"
                                            src={modusLogo}
                                        />
                                    </div>
                                    <div className="player-card-name">
                                        <h1 className="player-card-name__first-name">{removeNameSeparator(playerInfo.name)}</h1>
                                        <h1 className="player-card-name__last-name">{removeNameSeparator(playerInfo.surname)}</h1>
                                    </div>
                                    <div className="player-card-info">
                                        <div className="panel-stats-item panel-stats-item-card">
                                            <div>
                                                <p className="panel-stats-title">AGE</p>
                                            </div>
                                            <div>
                                                <p className="panel-stats-value panel-stats-value-card">
                                                    {getPlayerAge(playerInfo.dateOfBirth)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="panel-stats-item panel-stats-item-card">
                                            <div>
                                                <p className="panel-stats-title">HOME TOWN</p>
                                            </div>
                                            <div>
                                                <p className="panel-stats-value panel-stats-value-card">
                                                    {playerInfo.hometown}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="panel-stats-item panel-stats-item-card">
                                            <div>
                                                <p className="panel-stats-title">DARTS USED</p>
                                            </div>
                                            <div>
                                                <p className="panel-stats-value panel-stats-value-card">
                                                    {playerInfo.dartsUsed}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="panel-stats-item panel-stats-item-card">
                                            <div className="panel-stats-item-card-career">
                                                <div className="panel-stats-title">
                                                    CAREER BEST
                                                </div>
                                                <div className="panel-stats-value">
                                                    {playerInfo.careerBest}
                                                </div>
                                            </div>
                                        </div>
                                        {playerInfo.nickname
                                            &&
                                            <div className="panel-stats-item panel-stats-item-card">
                                                <div>
                                                    <p className="panel-stats-title">NICKNAME</p>
                                                </div>
                                                <div>
                                                    <p className="panel-stats-value panel-stats-value-card">
                                                        {playerInfo.nickname}
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>}
                        </Grid.Column>
                        <Grid.Column className="content-column" width="5">
                            <div className="content">
                                <div className="border-container">
                                    <div className="game-scores-wrapper">
                                        <div className="wrapper-score">
                                            <div className="score-table score-table-average">
                                                <div className="scores-header">
                                                    Average
                                                </div>
                                                <div className="table-body">
                                                    <div
                                                        className="table-body-inner table-body-inner-average-checkouts">
                                                        {formatAverageScore(home.average)}
                                                    </div>
                                                    <div
                                                        className="table-body-inner table-body-inner-average-checkouts">
                                                        {formatAverageScore(away.average)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="score-table score-table-checkouts">
                                                <div className="scores-header">
                                                    Checkouts
                                                </div>
                                                <div className="table-body">
                                                    <div
                                                        className="table-body-inner table-body-inner-average-checkouts">
                                                        {home.checkouts}
                                                    </div>
                                                    <div
                                                        className="table-body-inner table-body-inner-average-checkouts">
                                                        {away.checkouts}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="score-table score-table-average-180">
                                                <div className="scores-header">
                                                    <div className="scores-header-item">
                                                        Average
                                                    </div>
                                                    <div className="scores-header-item item-180s">
                                                        180<span>s</span>
                                                    </div>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-inner">
                                                        <div
                                                            className="table-body-inner-item table-body-inner-item-average-180">
                                                            {formatAverageScore(home.average)}
                                                        </div>
                                                        <div className="table-body-inner-item table-body-inner-item-line
                                                        table-body-inner-item-line-average-180">
                                                            {home.turns180}
                                                        </div>
                                                    </div>
                                                    <div className="table-body-inner">
                                                        <div
                                                            className="table-body-inner-item table-body-inner-item-average-180">
                                                            {formatAverageScore(away.average)}
                                                        </div>
                                                        <div className="table-body-inner-item table-body-inner-item-line
                                                        table-body-inner-item-line-average-180">
                                                            {away.turns180}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="score-table score-table-100-140-180">
                                                <div className="scores-header">
                                                    <div className="scores-header-item">
                                                        100+
                                                    </div>
                                                    <div className="scores-header-item">
                                                        140+
                                                    </div>
                                                    <div className="scores-header-item">
                                                        180<span>s</span>
                                                    </div>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-inner">
                                                        <div className="table-body-inner-item">
                                                            {home.turns100to139}
                                                        </div>
                                                        <div
                                                            className="table-body-inner-item table-body-inner-item-line">
                                                            {home.turns140to179}
                                                        </div>
                                                        <div
                                                            className="table-body-inner-item table-body-inner-item-line">
                                                            {home.turns180}
                                                        </div>
                                                    </div>
                                                    <div className="table-body-inner">
                                                        <div className="table-body-inner-item">
                                                            {away.turns100to139}
                                                        </div>
                                                        <div
                                                            className="table-body-inner-item table-body-inner-item-line">
                                                            {away.turns140to179}
                                                        </div>
                                                        <div
                                                            className="table-body-inner-item table-body-inner-item-line">
                                                            {away.turns180}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="score-table score-table-hi-checkouts">
                                                <div className="scores-header">
                                                    high checkout
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-inner">
                                                        {home.highestCheckout}
                                                    </div>
                                                    <div className="table-body-inner">
                                                        {away.highestCheckout}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column className={'right-content-column'} width="5">
                            <GameScores/>
                            {!!tournamentId && windowDimensions.width > 1600 &&
                                <div className="league-table-wrapper">
                                    <LeagueTable isShowDownLoadBtn={false} leagueId={tournamentId}/>
                                </div>}
                            <div className={'counters-container'}>
                                <button className={'counter-refresh-btn'}>
                                    refresh stats
                                </button>
                                <div className="score-table score-table-counter">
                                    <div className="scores-header scores-header-counter">
                                        <div>series 180<span>s</span></div>
                                    </div>
                                    <div className="table-body-counter">
                                        <div className="table-body-inner table-body-inner-counter">
                                            {renderCounter(phaseCounterAnimation, phaseCounterVal)}

                                        </div>
                                    </div>
                                </div>
                                <div className="score-table score-table-counter">
                                    <div className="scores-header scores-header-counter">
                                        <div>week 180<span>s</span></div>
                                    </div>
                                    <div className="table-body-counter">
                                        <div className="table-body-inner table-body-inner-counter">
                                            {renderCounter(weekCounterAnimation, weekCounter)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {!!tournamentId && windowDimensions.width < 1600 &&
                                <div className={'league-table-wrapper-laptop'}>
                                    <LeagueTable isShowDownLoadBtn={false} leagueId={tournamentId}/>
                                </div>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </div>
    );
};


export default DartMatchStatsTable;
DartMatchStatsTable.displayName = "DartMatchStatsTable";
