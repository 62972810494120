import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {ISetFirstPlayer} from "../../../../store/dartsGame/currentGame/types";
import moment from "moment";
import {GameTypes} from "../../../../enums/gameEvents";
import {useAppSelector} from "../../../../hooks/hooks";
import {GameStatus} from "../../../../enums/gameStatus";
import {getPlayerName} from "../../../../helpers/playersNames.helper";
import AlertModal from "../../../../components/DartsGameAlertModal/AlertModal";

interface IPlayerProps {
    isPlayingFirst: boolean;
    playerId: number | null;
    isHome: boolean;
    leg: number | null;
    onSelect: (data: ISetFirstPlayer) => void;
    gameId: number;
    isCurrentPlayerId: number | undefined;
}
export const Player: React.FC<IPlayerProps> = ({
                                            isHome,
                                            onSelect,
                                            leg,
                                            playerId,
                                            gameId,
                                            isCurrentPlayerId,
                                        }) => {
    const history = useHistory();
    const pathName = history.location.pathname;
    const isDartsAdc = pathName.includes("darts-adc");
    const payload: ISetFirstPlayer = {
        gameData: {
            gameId,
            playerId,
            date: moment().utc().toDate(),
        },
        gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC,
        currentPlayerId: isCurrentPlayerId || playerId

    };

    const [isOpenModal, setIsOpenModal] = useState(false);
    const {currentGameStatus} = useAppSelector((state)=> state.currentDartsGame)
    const {playerNames} = useAppSelector((state)=> state.currentDartsGame.currentGameInitData)
    const selectThrowingPlayer = () => {
        if ((currentGameStatus === GameStatus.PreEnd) || (currentGameStatus === GameStatus.End)) {
            setIsOpenModal(true);
            return setTimeout(() => setIsOpenModal(false), 1500);
        }
        else {
            onSelect(payload)
        }
    }

    return (
        <div
            className="player-name__container"
            onClick={selectThrowingPlayer}
        >
            <div className="player-name__score">{leg}</div>
            {
                playerNames &&
                <div className="player-name__details">
                    <div className="player-name__details-name">
                        {isHome ?
                            getPlayerName(playerNames.home)[0]
                            :
                            getPlayerName(playerNames.away)[0]
                        }
                    </div>
                    <div className="player-name__details-surName">
                        {isHome ?
                            getPlayerName(playerNames.home)[1]
                            :
                            getPlayerName(playerNames.away)[1]
                        }
                    </div>
                </div>
            }
            {<AlertModal open={isOpenModal} text={'This game is not active'}/>}
        </div>
    );
};
