import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {IUserPayload, IRole, IUser, IUserRolesState, FilterValues, IGetUsersResponse} from "./types";
import {API_ROLES, API_USERS, API_USERS_FILTERS} from "../../../constants";
import {client} from "../../../services/api.service";
import {errorLogger} from "../../../services/error-logger";

const initialState: IUserRolesState = {
    roles: [] as IRole[],
    allUsers: [] as IUser[],
    user: {} as IUser,
    usersLoader: true,
    rolesLoader: true,
    createMode: false,
    editMode: false,
    usersCount: 0,
    filterValues: {
        from: '',
        search: '',
        status: 0,
        pNum: 1,
        pSize: 10,
        sort: false,
    }
}



export const getRoles = createAsyncThunk<IRole[]>(
    'roles/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_ROLES);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch roles.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get roles: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data)
        }

    }
)

export const getUsers = createAsyncThunk<IGetUsersResponse, FilterValues>(
    'users/get',
    async (params, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_USERS_FILTERS}/?From=${params.from}&Search=${params.search}&Status=${params.status}&pNum=${params.pNum}&pSize=${params.pSize}&Sort=${params.sort}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch users.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get users: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data)

        }

    }
)
export const getSingleUser = createAsyncThunk<IUser, number>(
    'users/getSingle',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_USERS}/${id}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch a single user.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get a single user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createUser = createAsyncThunk<IUser, IUserPayload>(
    'users/post',
    async (user, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_USERS, user);
            toastr.success("CMS", "User has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a user.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const editUser = createAsyncThunk<IUser, IUserPayload>(
    'users/edit',
    async (user, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_USERS}/${user.id}`, user);
            toastr.success("CMS", "User has been edited");
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a user.')
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)

export const deleteUser = createAsyncThunk<IUser, number>(
    'users/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_USERS}/${id}`);
            toastr.success("CMS", "User has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a user.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.message)
        }

    }
)

export const cmsUsersNRolesSlice = createSlice({
    name: 'cmsRoles',
    reducers: {
        createModeHandler: (state, action: PayloadAction<boolean>) => {
            state.createMode = action.payload;
        },
        editModeHandler: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        clearSingleUserState: (state) => {
            state.user = initialState.user;
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.fulfilled, (state: IUserRolesState, action) => {
                state.rolesLoader = false;
                state.roles = action.payload;
            })
            .addCase(getRoles.rejected, (state: IUserRolesState) => {
                state.rolesLoader = false;
            })
            .addCase(getUsers.fulfilled, (state: IUserRolesState, action) => {
                state.usersLoader = false;
                state.allUsers = action.payload.users;
                state.usersCount = action.payload.totalQuantity;
                state.filterValues = action.meta.arg;
            })
            .addCase(getUsers.rejected, (state: IUserRolesState) => {
                state.usersLoader = false;
            })
            .addCase(getSingleUser.fulfilled, (state: IUserRolesState, action) => {
                state.editMode = true;
                state.user = action.payload;
            })
            .addCase(createUser.pending, (state: IUserRolesState) => {
                state.createMode = false;
                state.usersLoader = true;
            })
            .addCase(createUser.fulfilled, (state: IUserRolesState, action) => {
                state.allUsers = state.allUsers.concat(action.payload);
                state.usersLoader = false;
            })
            .addCase(createUser.rejected, (state: IUserRolesState) => {
                state.usersLoader = false
            })
            .addCase(editUser.pending, (state: IUserRolesState) => {
                state.editMode = false;
                state.usersLoader = true;
            })
            .addCase(editUser.fulfilled, (state: IUserRolesState, action) => {
                state.usersLoader = false;
                state.user = {} as IUser;
                state.allUsers = state.allUsers.map((user) => {
                    if (user.id === action.payload.id) {
                        user = action.payload
                    }
                    return user
                })

            })
            .addCase(editUser.rejected, (state: IUserRolesState) => {
                state.usersLoader = false;
            })
            .addCase(deleteUser.pending, (state: IUserRolesState) => {
                state.usersLoader = true;
            })
            .addCase(deleteUser.fulfilled, (state: IUserRolesState, action) => {
                state.allUsers = state.allUsers.filter((user) => {
                    return user.id !== action.meta.arg
                })
                state.usersLoader = false;
            })
            .addCase(deleteUser.rejected, (state: IUserRolesState) => {
                state.usersLoader = false;
            })
    },
});
export const {
    createModeHandler,
    editModeHandler,
    clearSingleUserState
} = cmsUsersNRolesSlice.actions

export default cmsUsersNRolesSlice.reducer;
