import React, {useEffect, useState} from "react";
import ImageUploading from "react-images-uploading";
import {Button, Icon, Loader} from "semantic-ui-react";
import {client} from "../../../../../services/api.service";
import './style.scss'
import {errorLogger} from "../../../../../services/error-logger";
import DartsDeleteModal from "../../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    deletePlayersPhoto,
    playerSetModal, setPlayerPhotoLoader,
    updatePlayersPhoto,
    uploadPlayersPhoto
} from "../../../../../store/cms/darts/players/cmsPlayersSlice";
import {IUploadPhotoPayload} from "../../../../../store/cms/darts/players/types";


interface IPhotoUploaderProps {
    closeUploaderHandler: (flag: false) => void
    playerId: number
}

const PhotoUploader: React.FC<IPhotoUploaderProps> = ({closeUploaderHandler, playerId}) => {

    const dispatch = useAppDispatch()
    const [imageInfo, setImageInfo] = useState(null as any);
    const [isExistPhoto, setIsExistPhoto] = useState('')
    const [imgName, setImgName] = useState('')
    const [imgValue, setImgValue] = useState('')

    const {photoLoader,isShowModal} = useAppSelector((state) => state.cmsPlayers);

    const getPlayerPhotoById = async () => {
        try {
            dispatch(setPlayerPhotoLoader(true))
            const playersPhoto = await client.get(`api/DartsPlayerPictures/${playerId}`)
            setIsExistPhoto(playersPhoto.data)
            dispatch(setPlayerPhotoLoader(false))
            dispatch(playerSetModal(true))

        } catch (error) {
            dispatch(setPlayerPhotoLoader(false))
            if (error instanceof Error) {
                console.error(error.message);
                const errorLog = {
                    projectName: 'DC',
                    errorMessage: `getPlayerPhotoById: ${error.name}: ${error.message}`,
                    errorDate: new Date()
                }
                errorLogger(errorLog)
            }
        }
    }

    useEffect(() => {
        getPlayerPhotoById()
    }, [])

    const onChange = (
        imageList: any,
    ) => {
        setImageInfo(imageList)

        if(imageList.length !==0) {
            const WIDTH = 750;
            const HEIGHT = 1000;
            let reader = new FileReader();
            let file = imageList[0].file;
            setImgName(file.name);
            reader.readAsDataURL(file);
            reader.onload = e => {
                let imgUrl: any;
                if (e.target) {
                    imgUrl = e.target.result;
                }
                const img = new Image();
                img.src = imgUrl;

                setImgValue(imgUrl)

                img.onload = (e: any) => {
                    const canvas = document.createElement('canvas');
                    let ratio = WIDTH / e.target.width;

                    if (e.target.height * ratio < HEIGHT) {
                        ratio = HEIGHT / e.target.height
                    }

                    canvas.height = e.target.height * ratio;
                    canvas.width = e.target.width * ratio;
                    const context = canvas.getContext("2d");

                    if (context) {
                        if (canvas.height > HEIGHT) {
                            canvas.height -= canvas.height - HEIGHT;
                        }
                        if (canvas.width > WIDTH) {
                            canvas.width -= canvas.width - WIDTH;
                        }
                        context.drawImage(img, 0, 0, canvas.width, canvas.height);
                        const imgUrl = context.canvas.toDataURL("image/png", 80);

                        setImgValue(imgUrl);
                    }
                };
            }
        }
    };

    const convertImageUrlToFile = (url: string, fileName: string) => {
        const splitImgData = url.split(',');
        if (splitImgData.length < 2) {
            throw new Error('Invalid image data');
        }

        const mimeMatch = splitImgData[0].match(/:(.*?);/);

        if (!mimeMatch || mimeMatch.length < 2) {
            throw new Error('MIME type not found');
        }

        const mime = mimeMatch[1];
        const data = splitImgData[1];

        const dataStr = atob(data);
        let n = dataStr.length;
        const dataArr = new Uint8Array(n);

        while (n--) {
            dataArr[n] = dataStr.charCodeAt(n);
        }

        return new File([dataArr], fileName, { type: mime });
    }

    const onUploadImg = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, uploadFunction: () => void) => {
        event.preventDefault();
        uploadFunction()
    }

    const onClose = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        closeUploaderHandler(false)
    }

    const getTitlePhoto = (imageInfo: any[]) => {
        if (imageInfo.length !== 0) {
            return imageInfo[0].file.name.split('.')[0]
        }
    }


    const onSendPhoto = () => {
        const formData = new FormData();
        formData.append(
            "picture",
            convertImageUrlToFile(imgValue, imgName),
        );
        const payload: IUploadPhotoPayload = {
            id: playerId,
            picture: formData
        }

        closeUploaderHandler(false)
        if (isExistPhoto) {
            dispatch(updatePlayersPhoto(payload))
        } else {
            dispatch(uploadPlayersPhoto(payload))
        }
    };

    return (
        <div className="photo-uploader">
            <ImageUploading
                acceptType={["png"]}
                resolutionType={"absolute"}
                onError={((errors) => console.log("errors", errors))}
                value={imageInfo}
                onChange={onChange}>
                {({
                      imageList,
                      onImageUpload,
                      onImageRemove,
                      errors

                  }) => (
                    <div className={"photo-uploader__image-wrapper"}>
                        <Icon
                            color="red"
                            name="close"
                            size="large"
                            className="close-icon"
                            onClick={onClose}
                        />
                        {<div className="photo-uploader__btn-wrapper">
                            {
                                !photoLoader && isShowModal &&
                                <div>
                                    <Button
                                        size="mini"
                                        className="photo-widget-btn"
                                        color="green"
                                        onClick={(e) => onUploadImg(e, onImageUpload)}
                                    >
                                        {imageList.length > 0  ? " Change photo" : "Upload"}
                                    </Button>
                                    {
                                        isExistPhoto &&
                                        <>
                                            <DartsDeleteModal
                                                id={playerId}
                                                confirmText={
                                                    "Are you sure you want to delete photo?"
                                                }
                                                deleteAction={() => deletePlayersPhoto({playerId, setIsOpenForm:closeUploaderHandler})}
                                                titleModal={"Delete your Player"}
                                            />
                                        </>
                                    }

                                </div>
                            }
                            <div>
                                {photoLoader && <Loader active></Loader>}
                            </div>
                        </div>}

                        {
                            errors &&
                            <div>
                                {errors.acceptType
                                    &&
                                    <span
                                        className={"upload-error-block"}>Your selected file type is not allow(only png)
                                    </span>}
                                {errors.resolution
                                    &&
                                    <span
                                        className={"upload-error-block"}>Selected file is not match your desired resolution(750x1000)
                                    </span>
                                }
                            </div>
                        }
                        {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <h6 className="image-item__title">{getTitlePhoto(imageInfo)}</h6>
                                <div className="downloadable-image-inner">
                                    <img className="downloadable-image" src={image.dataURL} alt={"photo"}/>
                                </div>
                                <div className="image-item__btn-wrapper">
                                    <Button
                                        size="mini"
                                        color="green"
                                        onClick={onSendPhoto}
                                    >
                                        Upload
                                    </Button>
                                    <Button
                                        size="mini"
                                        color="red"
                                        onClick={() => onImageRemove(index)}
                                    >Remove
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>

                )}
            </ImageUploading>
        </div>
    );
}

export default PhotoUploader





