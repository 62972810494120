import React from "react";
import {Container} from "semantic-ui-react";
import "./styles.scss";

type LiveScoreStatistics = {
    totalScore: number;
    turns100to139: number;
    turns140to179: number;
    turns180: number;
    highestCheckout: number;
    checkouts100plus: number;
    average: number;
    checkoutPercentage: number;
    checkouts: string;
}
interface IPlayerLiveStatsPanelProps {
    statistics: LiveScoreStatistics;
}

export const PlayerLiveStatsPanel: React.FC<IPlayerLiveStatsPanelProps> = React.memo((
    props
) => {
    const {statistics} = props;
    const formatAverageScore = (average: number): string => {
        const stringAverage = average?.toString().split('.')[1]
        if (!stringAverage) return average + ".00"
        if (stringAverage.length === 1) return average + "0"
        return average.toString()
    }
    return (
        <Container>
            <Container className="section">
                <div className="section__title">Averages</div>
                <div className="section__content">
                    <span className="section__content__title">Averages (3 darts)</span>
                    <span className="section__content__value inversed">
            {formatAverageScore(statistics?.average)}
          </span>
                </div>
            </Container>
            <Container className="section">
                <div className="section__title">Totals</div>
                <div className="section__content">
                    <div>
                        <span className="section__content__title">100+ throws</span>
                        <span className="section__content__value">
              {statistics?.turns100to139}
            </span>
                    </div>
                    <div>
                        <span className="section__content__title">140+ throws </span>
                        <span className="section__content__value">
              {statistics?.turns140to179}
            </span>
                    </div>
                    <div>
                        <span className="section__content__title">180 throws</span>
                        <span className="section__content__value">
              {statistics?.turns180}
            </span>
                    </div>
                </div>
            </Container>
            <Container className="section">
                <div className="section__title">Other stats</div>
                <div className="section__content">
                    <div>
                        <span className="section__content__title">Highest checkout</span>
                        <span className="section__content__value">
              {statistics?.highestCheckout}
            </span>
                    </div>
                    <div>
                        <span className="section__content__title">Checkouts 100+</span>
                        <span className="section__content__value">
              {statistics?.checkouts100plus}
            </span>
                    </div>
                    <div>
                        <span className="section__content__title">Checkout  %</span>
                        <span className="section__content__value">
              {statistics?.checkoutPercentage} %
            </span>
                    </div>
                    <div>
                        <span className="section__content__title">Checkouts</span>
                        <span className="section__content__value">
              {statistics?.checkouts}
            </span>
                    </div>
                </div>
            </Container>
        </Container>
    );
},(prevProps, nextProps) => {
    return nextProps.statistics === prevProps.statistics;
})
