export const trowerAway = (isCurrentTrower: boolean | null) => {
    if (isCurrentTrower === false) {
        return true;
    }
    if (isCurrentTrower === null) {
        return false;
    } else {
        return false;
    }
};
export const setCurrentThrowerStyles = (currThrower: boolean) => {
    return {
        background: currThrower
            ? "linear-gradient(rgb(255, 207, 64), rgb(255, 207, 64)"
            : "inherit",
    };
};
