import React, {useEffect, useState} from "react";
import {Container, Table, Button} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import TablePagination from "../../../../components/Pagination/Pagination";
import DartsTournamentsTableForm from "./DartsTournamentsForm/dartsTournamentsTableForm";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {IWeek} from "../../../../store/cms/darts/phases/types";
import {
    getDartsTournamentsInit, setCurrentCmsTournamentPage,
    setEditTournamentMode,
    showTournamentForm,
    setTournamentsOnCurrentPage, getDartsTournaments, deleteDartsTournament
} from "../../../../store/cms/darts/tournaments/cmsTournamentsSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";


export interface ICellValue {
    id: number,
    name: string,
    group: string,
    week: string,
    phaseId: number,
    phaseName: string
}

const DartsTournaments = () => {
    const [tableCellValues, setTableCellValues] = useState<ICellValue[]>([]);
    const dispatch = useAppDispatch();
    const {competitions, groups, phases} = useAppSelector((state) => state.cmsTournaments.dartsTournaments);
    const {
        isVisibleForm,
        tournamentsCount,
        currentPage,
        tournamentsOnCurrentPage,
        isLoading,
        noContent,
    } = useAppSelector((state) => state.cmsTournaments);
    const {user} = useAppSelector((state) => state.auth);
    const tournaments = useAppSelector((state) => state.cmsTournaments.currentDartsTournaments);

    useEffect(() => {
        dispatch(getDartsTournaments());
        dispatch(getDartsTournamentsInit());
    }, []);

    useEffect(() => {
        if (tournaments && groups) {
            const allWeek = getAllWeeksInTournaments();
            const cellValues = tournaments?.map((tour) => {
                const competition = competitions?.find(
                    (comp) => comp.id === tour.competitionId
                );
                const group = groups?.find((group) => group.id === tour.groupId);
                const week = allWeek?.find((week) => week.id === tour.weekId);
                const phase = phases?.find((phase) => phase.id === tour.phaseId);
                return {
                    id: tour.id,
                    name: competition!?.name?.split("-")[0],
                    group: group!?.name,
                    week: `${week!?.sequential}`,
                    phaseId: tour.phaseId,
                    phaseName: phase!?.name
                };
            });
            setTableCellValues(cellValues);
        }
    }, [tournaments, groups]);

    const getAllWeeksInTournaments = () => {
        const allWeek: IWeek[] = [];
        phases?.map((phase) => {
            return phase?.weeks?.forEach((week) => {
                allWeek.push(week);
            });
        });
        return allWeek;
    };

    return (
        <Container>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button onClick={() => dispatch(showTournamentForm())} id="add_tournament" color="green">
                    Add tournament
                </Button>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No tournaments</div>
            ) : (
                <Table celled>
                    {isLoading && <DimmerLoader/>}
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">
                                    Id
                                </Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">
                                Competition
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Phase</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Week</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Group</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!noContent && tournamentsOnCurrentPage.map((cellValue) => {
                                return (
                                    <Table.Row key={cellValue.id}>
                                        {checkIsUserAdmin(user) &&
                                            <Table.Cell textAlign="center">{cellValue.id}</Table.Cell>
                                        }
                                        <Table.Cell textAlign="center">{cellValue.name}</Table.Cell>
                                        <Table.Cell textAlign="center">{cellValue.phaseName}</Table.Cell>
                                        <Table.Cell textAlign="center">{cellValue.week}</Table.Cell>
                                        <Table.Cell textAlign="center">{cellValue.group}</Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <DartsDeleteModal
                                                id={cellValue.id}
                                                deleteAction={(id: number) => deleteDartsTournament(id)}
                                                confirmText={
                                                    "Are you sure you want to delete your tournament?"
                                                }
                                                titleModal={"Delete your Tournament"}
                                            />
                                            <Button
                                                onClick={() =>
                                                    dispatch(setEditTournamentMode(cellValue.id))
                                                }
                                                color="yellow"
                                                size="mini"
                                            >
                                                update
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                    </Table.Body>
                </Table>
            )}
            {!noContent && (
                <TablePagination
                    setCurrentPage={setCurrentCmsTournamentPage}
                    setItemsInCurrentPage={setTournamentsOnCurrentPage}
                    itemsCount={tournamentsCount}
                    items={tableCellValues}
                    currentPage={currentPage}
                    limitItemsInPage={10}
                />
            )}
            {isVisibleForm && (
                <DartsTournamentsTableForm
                    allWeek={getAllWeeksInTournaments()}
                    tournamentsIds={tournaments}
                />
            )}
        </Container>
    );
};

export default DartsTournaments;
